import React, { useEffect } from "react";
import "./DashboardScreen.css";
import LeftReusableScreen from "../reusables/LeftReusableScreen";
import { ImageConstants } from "../../../utils/constants";
import MyFilterAndSlide from "../reusables/MyFilterAndSlide";
import QuizIcon from "@mui/icons-material/Quiz";
import { Card, Button } from "@mui/material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useDispatch, useSelector } from "react-redux";
import { getFullCertificationName } from "../../../utils/get_full_certification_name";
import {
  DashboardAllSubmissionsAction,
  DashboardFilterCertificationAction,
  DashboardUploadSolutionAction,
} from "../../../actions/Dashboard/dashboardActions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditIcon from "@mui/icons-material/Edit";
import AssignmentIcon from "@mui/icons-material/Assignment";
import EventNoteIcon from "@mui/icons-material/EventNote";
import moment from "moment";
import ProfileDropdown from "../reusables/profile_dropdown";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

function DashboardScreen() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { allSubmissions, loading } = useSelector(
    (state) => state.usersAllSubmissionsStore
  );
  const {
    inprogressCertificationsData,
    completedCertificationsData,
    inReviewCertificationsData,
  } = useSelector((state) => state.dashboardFilterCertificationsStore);
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo != null || userInfo !== undefined) {
      dispatch(DashboardAllSubmissionsAction());
    }
  }, [userInfo]);

  useEffect(() => {
    if (allSubmissions != null || allSubmissions !== undefined) {
      dispatch(DashboardFilterCertificationAction("inprogress"));
      dispatch(DashboardFilterCertificationAction("completed"));
      dispatch(DashboardFilterCertificationAction("inreview"));
    }
  }, [allSubmissions]);

  const history = useHistory();
  const handleUploadSolution = (val) => {
    dispatch(DashboardUploadSolutionAction(val));
    history.push("/dashboard/uploadsolution");
  };

  const upperContainer = () => {
    return (
      <div className="dashboardScreen_top_container">
        <div className="dashboardScreen_top_container_title">
          <h1>Welcome back {userInfo?.firstName}!</h1>
          <h3>
            You have completed{" "}
            <span
              style={{
                fontWeight: "800",
                fontFamily: "sans-serif",
                fontSize: "18px",
              }}
            >
              {completedCertificationsData?.length ?? "0"}
            </span>{" "}
            Certifications and{" "}
            <span
              style={{
                fontWeight: "800",
                fontFamily: "sans-serif",
                fontSize: "18px",
              }}
            >
              {userInfo?.courses_bought?.length}
            </span>{" "}
            Courses. Keep going to increase your resume strength.
          </h3>
        </div>
        <img
          className="dashboard_upper_image"
          src={ImageConstants.getDashboardTopLogo}
          alt=""
        />
      </div>
    );
  };

  const inProgressCertificateSection = (e, isInreview) => {
    var image = ImageConstants.getBasicCertificateImage;
    if (e?.certificate?.certificate_category === "basic") {
      image = ImageConstants.getBasicCertificateImage;
    } else if (e?.certificate?.certificate_category === "intermediate") {
      image = ImageConstants.getIntermediateCertificateImage;
    } else {
      image = ImageConstants.getAdvancedCertificateImage;
    }
    return (
      <div style={{ margin: "50px 0px" }}>
        <div>
          <Card className="inprogress_certification_card">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="inprogress_certification_card_image"
                src={image}
                alt=""
              />
            </div>
            <div className="inprogress_certification_card_title">
              <h4 style={{ textAlign: "center" }}>
                {getFullCertificationName(
                  e?.certificate?.certificate_type,
                  e?.certificate?.certificate_category
                )}{" "}
                Internship
              </h4>
            </div>
            <div className="inprogress_certification_card_selected">
              <h5>Selected:</h5>
              <div>
                <QuizIcon style={{ fontSize: "20px" }} />
                <h4>
                  Problem Statement{" "}
                  {e?.selected_problem_statement?.problem_number ?? "1"}
                </h4>
              </div>
            </div>
            {isInreview ? (
              <div className="inprogress_certification_card_reviewing_card_button">
                <EditIcon style={{ marginRight: "5px", fontSize: "18px" }} />
                <h3>Reviewing</h3>
              </div>
            ) : (
              <div className="inprogress_certification_card_uploadButtonContainer">
                <Button onClick={() => handleUploadSolution(e)}>
                  Upload Solution
                </Button>
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  };
  const inReviewCertificateSection = (e, isInreview) => {
    var image = ImageConstants.getBasicCertificateImage;
    if (e?.certificate?.certificate_category === "basic") {
      image = ImageConstants.getBasicCertificateImage;
    } else if (e?.certificate?.certificate_category === "intermediate") {
      image = ImageConstants.getIntermediateCertificateImage;
    } else {
      image = ImageConstants.getAdvancedCertificateImage;
    }
    return (
      <div style={{ margin: "50px 0px", position: "relative" }}>
        <div>
          <Card className="inprogress_certification_card">
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                marginBottom: "10px",
              }}
            >
              <div className="inprogress_certification_card_reviewing_card_button">
                <AssignmentIcon
                  style={{ marginRight: "5px", fontSize: "18px" }}
                />
                <h3>Reviewing</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="inprogress_certification_card_image"
                src={image}
                alt=""
              />
            </div>
            <div className="inprogress_certification_card_title">
              <h4 style={{ textAlign: "center" }}>
                {getFullCertificationName(
                  e?.certificate?.certificate_type,
                  e?.certificate?.certificate_category
                )}{" "}
                Internship
              </h4>
            </div>
            <div className="inprogress_certification_card_selected">
              <h5>Selected:</h5>
              <div>
                <QuizIcon style={{ fontSize: "20px" }} />
                <h4>
                  Problem Statement{" "}
                  {e?.selected_problem_statement?.problem_number ?? "1"}
                </h4>
              </div>
            </div>
            <div className="inprogress_certification_card_selected">
              <h5>Date:</h5>
              <div>
                <EventNoteIcon style={{ fontSize: "20px" }} />
                <h4>{moment(e.createdAt).format("D MMM YYYY")}</h4>
              </div>
            </div>
            <div className="inreview_certification_card_uploadButtonContainer">
              <Button onClick={() => handleUploadSolution(e)}>
                <EditIcon style={{ marginRight: "5px", fontSize: "12px" }} />{" "}
                Edit
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  const inProgressCoursesSection = () => {
    return (
      <Card className="dashboardCourses_card_main">
        <div className="dashboardCourses_image_container">
          <img src={ImageConstants.shopping_project_image} alt="" />
        </div>
        <div className="dashboard_details_container">
          <p>Title of the Card</p>
          <span>Subtitle of the Card</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="dashboard_courses_below">
            <div>
              <OndemandVideoIcon style={{ fontSize: "16px", color: "grey" }} />
              <span>10 videos</span>
            </div>
            <div>
              <AccessTimeIcon style={{ fontSize: "16px", color: "grey" }} />
              <span>8 hrs</span>
            </div>
          </div>
          <div className="dashboard_price">
            <h4>₹499</h4>
          </div>
        </div>
        <div className="dashboard_courses_button_container">
          <Button>Start</Button>
        </div>
      </Card>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <LeftReusableScreen />
      <div className="dashboardScreen_top_main_container">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "end",
            margin: "10px 10px",
          }}
        >
          <ProfileDropdown
            userImage={ImageConstants.getTempImage}
            userName={userInfo?.firstName}
          />
        </div>
        <div style={{ padding: "20px" }}>
          {upperContainer()}
          {inReviewCertificationsData?.length > 0 && (
            <div style={{ margin: "40px 0px" }}>
              <div>
                <h2 style={{ margin: "0px 0px -50px 0px" }}>
                  InReview Certifications:
                </h2>
              </div>
              <div style={{ marginTop: "20px" }} />
              <MyFilterAndSlide
                data={inReviewCertificationsData?.map((e) =>
                  inReviewCertificateSection(e, true)
                )}
              />
            </div>
          )}
          {loading && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                justifyContent: "space-between",
              }}
            >
              <Skeleton
                count={1}
                style={{
                  width: "300px",
                  height: "350px",
                  padding: "10px",
                  borderRadius: "22px",
                  margin: "20px",
                }}
              />
              <Skeleton
                count={1}
                style={{
                  width: "300px",
                  height: "350px",
                  padding: "10px",
                  borderRadius: "22px",
                  margin: "20px",
                }}
              />
              <Skeleton
                count={1}
                style={{
                  width: "300px",
                  height: "350px",
                  padding: "10px",
                  borderRadius: "22px",
                  margin: "20px",
                }}
              />
            </div>
          )}
          {inprogressCertificationsData?.length > 0 && (
            <div style={{ margin: "40px 0px" }}>
              <div>
                <h2 style={{ margin: "0px 0px -50px 0px" }}>
                  Inprogress Certifications:
                </h2>
              </div>

              <div style={{ marginTop: "20px" }} />
              <MyFilterAndSlide
                data={inprogressCertificationsData?.map((e) =>
                  inProgressCertificateSection(e)
                )}
              />
            </div>
          )}

          {/* <div style={{ margin: "40px 0px" }}>
            <div>
              <h2 style={{ margin: "0px 0px -50px 0px" }}>
                Inprogress Courses:
              </h2>
            </div>
            <div style={{ marginTop: "20px" }} />
            <MyFilterAndSlide
              data={userInfo?.courses_bought?.map((e) =>
                inProgressCoursesSection()
              )}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardScreen;
