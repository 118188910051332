import {
  GET_DETAILS_FOR_EVENT,
  GET_MY_REGISTERED_EVENTS_FAIL,
  GET_MY_REGISTERED_EVENTS_LOADING,
  GET_MY_REGISTERED_EVENTS_SUCCESS,
  REGISTER_USER_FOR_WEBINAR_FAIL,
  REGISTER_USER_FOR_WEBINAR_LOADING,
  REGISTER_USER_FOR_WEBINAR_RESET,
  REGISTER_USER_FOR_WEBINAR_SUCCESS,
  WEBINAR_EVENT_ALL_FAIL,
  WEBINAR_EVENT_ALL_LOADING,
  WEBINAR_EVENT_ALL_SUCCESS,
  WEBINAR_EVENT_POPULAR,
  WEBINAR_EVENT_TOP_RESULT,
  WEBINAR_EVENT_UPCOMING,
} from "../../constants/WebinarEvents/webinarEventConstants";

export const allWebinarEventReducer = (state = {}, action) => {
  switch (action.type) {
    case WEBINAR_EVENT_ALL_LOADING:
      return {
        loading: true,
      };
    case WEBINAR_EVENT_ALL_SUCCESS:
      return {
        loading: false,
        success: true,
        webinar_events: action.payload,
      };
    case WEBINAR_EVENT_ALL_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const upcomingWebinarEventReducer = (state = {}, action) => {
  switch (action.type) {
    case WEBINAR_EVENT_UPCOMING:
      return {
        ...state,
        upcomingEvent: action.payload,
      };
    case WEBINAR_EVENT_TOP_RESULT:
      return {
        ...state,
        topResults: action.payload,
      };
    case WEBINAR_EVENT_POPULAR:
      return {
        ...state,
        popularResult: action.payload,
      };

    default:
      return state;
  }
};

export const registerUserForWebinarReducer = (state = {}, action) => {
  switch (action.type) {
    case REGISTER_USER_FOR_WEBINAR_LOADING:
      return {
        loading: true,
      };
    case REGISTER_USER_FOR_WEBINAR_SUCCESS:
      return {
        loading: false,
        success: true,
        userWebinarRegistration: action.payload,
      };
    case REGISTER_USER_FOR_WEBINAR_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case REGISTER_USER_FOR_WEBINAR_RESET:
      return {
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};

export const getMyRegisteredEventsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_MY_REGISTERED_EVENTS_LOADING:
      return {
        loading: true,
      };
    case GET_MY_REGISTERED_EVENTS_SUCCESS:
      return {
        loading: false,
        success: true,
        myEventRegistrations: action.payload,
      };
    case GET_MY_REGISTERED_EVENTS_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const webinarEventComingFromReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_DETAILS_FOR_EVENT:
      return {
        webinarDetails: action.payload,
      };

    default:
      return state;
  }
};
