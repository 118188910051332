export const CERTIFICATE_PROBLEM_STATEMENT_SELECTED =
  "CERTIFICATE_PROBLEM_STATEMENT_SELECTED";

export const GET_CERTIFICATE_REQUEST_LOADING =
  "GET_CERTIFICATE_REQUEST_LOADING";
export const GET_CERTIFICATE_REQUEST_SUCCESS =
  "GET_CERTIFICATE_REQUEST_SUCCESS";
export const GET_CERTIFICATE_REQUEST_FAILED = "GET_CERTIFICATE_REQUEST_FAILED";
export const GET_CERTIFICATE_FILTERED_PROBLEM_STATEMENT =
  "GET_CERTIFICATE_FILTERED_PROBLEM_STATEMENT";
export const REDIRECT_CERTIFICATE_PAGE = "REDIRECT_CERTIFICATE_PAGE";

export const CERTIFICATE_ENROLLMENT_LOADING = "CERTIFICATE_ENROLLMENT_LOADING";
export const CERTIFICATE_ENROLLMENT_SUCCESS = "CERTIFICATE_ENROLLMENT_SUCCESS";
export const CERTIFICATE_ENROLLMENT_FAIL = "CERTIFICATE_ENROLLMENT_FAIL";
export const CERTIFICATE_ENROLLMENT_RESET = "CERTIFICATE_ENROLLMENT_RESET";
