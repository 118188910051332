import React, { useEffect } from "react";
import "./DashboardCertificates.css";
import { ImageConstants } from "../../../utils/constants";
import LeftReusableScreen from "../reusables/LeftReusableScreen";
import MyFilterAndSlide from "../reusables/MyFilterAndSlide";
import { Card, Button } from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import { useDispatch, useSelector } from "react-redux";
import {
  DashboardAllSubmissionsAction,
  DashboardFilterCertificationAction,
  DashboardUploadSolutionAction,
} from "../../../actions/Dashboard/dashboardActions";
import { getFullCertificationName } from "../../../utils/get_full_certification_name";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import EditIcon from "@mui/icons-material/Edit";
import EventNoteIcon from "@mui/icons-material/EventNote";
import moment from "moment";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ProfileDropdown from "../reusables/profile_dropdown";

function DashboardCertificates() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const {
    inprogressCertificationsData,
    completedCertificationsData,
    inReviewCertificationsData,
  } = useSelector((state) => state.dashboardFilterCertificationsStore);
  const { allSubmissions } = useSelector(
    (state) => state.usersAllSubmissionsStore
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo != null || userInfo !== undefined) {
      dispatch(DashboardAllSubmissionsAction());
    }
  }, [userInfo]);

  useEffect(() => {
    if (allSubmissions != null || allSubmissions !== undefined) {
      dispatch(DashboardFilterCertificationAction("inprogress"));
      dispatch(DashboardFilterCertificationAction("completed"));
      dispatch(DashboardFilterCertificationAction("inreview"));
    }
  }, [allSubmissions]);

  const history = useHistory();
  const handleUploadSolution = (val) => {
    dispatch(DashboardUploadSolutionAction(val));
    history.push("/dashboard/uploadsolution");
  };
  const upperContainer = () => {
    return (
      <div className="dashboardScreen_top_container">
        <div className="dashboardScreen_top_container_title">
          <h1>Welcome back {userInfo?.firstName}!</h1>
          <h3>
            You have completed{" "}
            <span
              style={{
                fontWeight: "800",
                fontFamily: "sans-serif",
                fontSize: "18px",
              }}
            >
              {completedCertificationsData?.length}
            </span>{" "}
            Certifications. Boost your resume by adding more certifications.
          </h3>
        </div>
        <img
          className="dashboardCertificate_upper_image"
          src={ImageConstants.getDashboardCertifcateTopImage}
          alt=""
        />
      </div>
    );
  };
  const inProgressCertificateSection = (e, isInReview) => {
    var image = ImageConstants.getBasicCertificateImage;
    if (e?.certificate?.certificate_category === "basic") {
      image = ImageConstants.getBasicCertificateImage;
    } else if (e?.certificate?.certificate_category === "intermediate") {
      image = ImageConstants.getIntermediateCertificateImage;
    } else {
      image = ImageConstants.getAdvancedCertificateImage;
    }
    return (
      <div style={{ margin: "50px 0px" }}>
        <div>
          <Card className="dashboardCertificates_certification_card">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="dashboardCertificates_certification_card_image"
                src={image}
                alt=""
              />
            </div>
            <div className="dashboardCertificates_certification_card_title">
              <h4 style={{ textAlign: "center" }}>
                {getFullCertificationName(
                  e?.certificate?.certificate_type,
                  e?.certificate?.certificate_category
                )}{" "}
                Internship
              </h4>
            </div>
            <div className="dashboardCertificates_certification_card_selected">
              <h5>Selected:</h5>
              <div>
                <QuizIcon style={{ fontSize: "20px" }} />
                <h4>
                  Problem Statement{" "}
                  {e?.selected_problem_statement?.problem_number ?? "1"}
                </h4>
              </div>
            </div>
            {isInReview ? (
              <div className="dashboardCertificates_certification_reviewing_card_button">
                <EditIcon style={{ marginRight: "5px", fontSize: "18px" }} />
                <h3>Reviewing</h3>
              </div>
            ) : (
              <div className="inprogress_certification_card_uploadButtonContainer">
                <Button onClick={() => handleUploadSolution(e)}>
                  Upload Solution
                </Button>
              </div>
            )}
          </Card>
        </div>
      </div>
    );
  };

  const inReviewCertificateSection = (e) => {
    var image = ImageConstants.getBasicCertificateImage;
    if (e?.certificate?.certificate_category === "basic") {
      image = ImageConstants.getBasicCertificateImage;
    } else if (e?.certificate?.certificate_category === "intermediate") {
      image = ImageConstants.getIntermediateCertificateImage;
    } else {
      image = ImageConstants.getAdvancedCertificateImage;
    }
    return (
      <div style={{ margin: "50px 0px", position: "relative" }}>
        <div>
          <Card className="inprogress_certification_card">
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                marginBottom: "10px",
              }}
            >
              <div className="inprogress_certification_card_reviewing_card_button">
                <AssignmentIcon
                  style={{ marginRight: "5px", fontSize: "18px" }}
                />
                <h3>Reviewing</h3>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                className="inprogress_certification_card_image"
                src={image}
                alt=""
              />
            </div>
            <div className="inprogress_certification_card_title">
              <h4 style={{ textAlign: "center" }}>
                {getFullCertificationName(
                  e?.certificate?.certificate_type,
                  e?.certificate?.certificate_category
                )}{" "}
                Internship
              </h4>
            </div>
            <div className="inprogress_certification_card_selected">
              <h5>Selected:</h5>
              <div>
                <QuizIcon style={{ fontSize: "20px" }} />
                <h4>
                  Problem Statement{" "}
                  {e?.selected_problem_statement?.problem_number ?? "1"}
                </h4>
              </div>
            </div>
            <div className="inprogress_certification_card_selected">
              <h5>Date:</h5>
              <div>
                <EventNoteIcon style={{ fontSize: "20px" }} />
                <h4>{moment(e.updatedAt).format("D MMM YYYY")}</h4>
              </div>
            </div>
            <div className="inreview_dashboard_certificates_uploadButtonContainer">
              <Button onClick={() => handleUploadSolution(e)}>
                <EditIcon style={{ marginRight: "5px", fontSize: "12px" }} />{" "}
                Edit
              </Button>
            </div>
          </Card>
        </div>
      </div>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <LeftReusableScreen />
      <div className="dashboard_certificates_upperContainer_main">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              margin: "10px 10px",
            }}
          >
            <ProfileDropdown
              userImage={ImageConstants.getTempImage}
              userName={userInfo?.firstName}
            />
          </div>
          <div className="dashboardCertificate_right_main_container">
            {upperContainer()}
            {inReviewCertificationsData?.length > 0 && (
              <div style={{ margin: "40px 0px" }}>
                <div>
                  <h2 style={{ margin: "0px 0px -50px 0px" }}>
                    InReview Certifications:
                  </h2>
                </div>
                <div style={{ marginTop: "20px" }} />
                <MyFilterAndSlide
                  data={inReviewCertificationsData?.map((e) =>
                    inReviewCertificateSection(e)
                  )}
                />
              </div>
            )}
            {inprogressCertificationsData?.length > 0 && (
              <div style={{ margin: "40px 0px" }}>
                <div>
                  <h2 style={{ margin: "0px 0px -50px 0px" }}>
                    Inprogress Certifications:
                  </h2>
                </div>
                <div style={{ marginTop: "20px" }} />
                <MyFilterAndSlide
                  data={inprogressCertificationsData?.map((e) =>
                    inProgressCertificateSection(e)
                  )}
                />
              </div>
            )}
            {completedCertificationsData?.length > 0 && (
              <div style={{ margin: "40px 0px" }}>
                <div>
                  <h2 style={{ margin: "0px 0px -50px 0px" }}>
                    Completed Certifications:
                  </h2>
                </div>
                <div style={{ marginTop: "20px" }} />
                <MyFilterAndSlide
                  data={completedCertificationsData?.map((e) =>
                    inProgressCertificateSection(e)
                  )}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashboardCertificates;
