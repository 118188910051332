import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  completeUserSubmissionAction,
  getAllSubmittedUsersAction,
} from "../../../actions/Admin-Panels/adminPanelActions";
import "./SuperAdminPanel.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "@mui/material";

export default function SuperAdminPanel() {
  const { getAllSubmittedUsers } = useSelector(
    (state) => state.getAllSubmittedUsersStore
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAllSubmittedUsersAction("inreview"));
  }, [dispatch]);

  const [mappedData, setMappedData] = useState(null);
  useEffect(() => {
    if (getAllSubmittedUsers !== undefined) {
      const data = mapData(getAllSubmittedUsers);
      setMappedData(data);
    }
  }, [getAllSubmittedUsers]);

  const mapData = (data) => {
    return data?.map((item) => {
      return {
        _id: item._id,
        name: item?.user?.firstName + " " + item?.user?.lastName,
        email: item?.user?.email,
        certification_type: item?.certificate?.certificate_type,
        certification_category: item?.certificate?.certificate_category,
      };
    });
  };

  const nameBodyTemplate = (data) => {
    return <h3>{data?.name}</h3>;
  };

  const emailBodyTemplate = (data) => {
    return <h3>{data?.email}</h3>;
  };

  const certificateCategoryBodyTemplate = (data) => {
    return <h3>{data?.certification_type}</h3>;
  };

  const certificateTypeBodyTemplate = (data) => {
    return (
      <div
        style={{
          padding: "2px",
          backgroundColor: "red",
          width: "100px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "22px",
        }}
      >
        <h4 style={{ color: "#fff", margin: "0px" }}>
          {data?.certification_category}
        </h4>
      </div>
    );
  };

  const handleSendMail = (id) => {
    dispatch(completeUserSubmissionAction(id));
  };

  const sendMailBodyTemplate = (data) => {
    return (
      <Button
        onClick={() => handleSendMail(data._id)}
        style={{
          backgroundColor: "green",
          color: "#fff",
          textTransform: "inherit",
        }}
      >
        Send Mail
      </Button>
    );
  };

  return (
    <div className="main_admin_panel_container">
      <DataTable
        value={mappedData}
        tableStyle={{ minWidth: "60rem" }}
        showGridlines
      >
        <Column field="name" header="Name" body={nameBodyTemplate}></Column>
        <Column field="email" header="Email" body={emailBodyTemplate}></Column>
        <Column
          field="price"
          header="Certificate Category"
          body={certificateCategoryBodyTemplate}
        ></Column>
        <Column
          field="category"
          header="Certificate Type"
          body={certificateTypeBodyTemplate}
        ></Column>
        <Column
          field="category"
          header="Send Mail"
          body={sendMailBodyTemplate}
        ></Column>
      </DataTable>
    </div>
  );
}
