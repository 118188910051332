import React from "react";
import { ImageConstants } from "../../../utils/constants";
import LeftReusableScreen from "../reusables/LeftReusableScreen";
import Accordion from "@mui/material/Accordion";
import AccordionActions from "@mui/material/AccordionActions";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Button from "@mui/material/Button";
import "./Support.css";

const data = [
  {
    question: "When will i receive my certificate?",
    answer:
      "Your Certificate will be sent to you within <strong>24 - 48 hours</strong> on your registered email id after submitting your solution.",
  },
  {
    question: "How will taking this courses make my resume standout?",
    answer:
      "Taking our courses will not only equip you with essential skills but also provide you with real-world insights and unique solutions that can make your resume stand out. Our curriculum delves into practical examples and case studies, like <strong>Creating Instagram Discover feed with post suggestions</strong> or <strong>Video shopping services</strong> or <strong>Creating a Showing Ads in between Videos system</strong> and much more using technologies like <strong>React.js, Redux, Node.js, MongoDB, Next.js</strong> etc. By understanding these concepts and implementing them in your projects, you'll not only demonstrate technical proficiency but also showcase your ability to tackle complex challenges creatively. Employers are increasingly looking for candidates who can bring innovative solutions to the table, and our courses are designed to help you do just that. So, by enrolling in our courses, you're not just learning; you're gaining valuable knowledge and experiences that will make your resume shine in a competitive job market.",
  },
  {
    question: "How to Submit the certification solution?",
    answer:
      "Step 1: Select Upload Solution from Dashboard and select the certification for which you want to submit.\n Step 2: Add the Github link for the selected problem and then select submit for review.\n Step 3: Once Successful Review we will send you the certificate on your registered email id within 24 - 48 hours.",
  },
];

function Support() {
  return (
    <div style={{ display: "flex" }}>
      <LeftReusableScreen />
      <div className="support_main_container">
        <img width={350} src={ImageConstants.getSupportImage} alt="" />
        <h2>
          Please mail us at{" "}
          <span style={{ fontWeight: "800", fontSize: "26px" }}>
            support@acetechies.co.in
          </span>{" "}
          for any queries regarding certificates or courses.
        </h2>
        <div>
          <div>
            <h2>Frequently Asked Questions (FAQs):</h2>
          </div>
          {data.map((e) => {
            return (
              <Accordion style={{ marginBottom: "30px", width: "52vw" }}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h3 style={{ margin: "5px 0px" }}>{e.question}</h3>
                </AccordionSummary>
                <AccordionDetails>
                  <h4
                    dangerouslySetInnerHTML={{ __html: e.answer }}
                    style={{
                      margin: "0px",
                      fontWeight: "500",
                      lineHeight: "28px",
                      fontFamily: "sans-serif",
                      whiteSpace: "pre-line",
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Support;
