import React from "react";
import EducationTopBar from "../education/topbar/EducationTopBar";
import EducationFooter from "../education/footer/education_footer";

function PrivacyPolicy({ policyData }) {
  const getPoints = () => {
    return (
      <div>
        {policyData.details.map((data) => {
          return (
            <div>
              <h2>{data.title}</h2>
              <ul>
                {data.points.map((point) => (
                  <li>
                    <p style={{ fontSize: "18px" }}> {point}</p>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </div>
    );
  };
  return (
    <div>
      <EducationTopBar />
      <div style={{ marginTop: "100px", padding: "0px 5rem" }}>
        <h1
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {policyData.title}
        </h1>
        <div style={{ marginTop: "40px" }}>
          <div>
            <p>{policyData.date}</p>
          </div>
          <div>
            <p>{policyData.subtext}</p>
            {getPoints()}
          </div>
        </div>
      </div>
      <EducationFooter margin={"20px"} />
    </div>
  );
}

export default PrivacyPolicy;
