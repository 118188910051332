import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import InfoIcon from "@mui/icons-material/Info";
import HomeIcon from "@mui/icons-material/Home";
import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import { userLogoutAction } from "../../../actions/userActions";

export default function TemporaryDrawer({ isOpen, toggleDrawer }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const history = useHistory();
  const handleListItemClicked = (index) => {
    if (index === 0) {
      history.push("/");
    } else if (index === 1) {
      history.push("/events");
    } else if (index === 2) {
      history.push("/courses");
    } else if (index === 3) {
      history.push("/certificates");
    } else if (index === 4) {
      history.push("/about");
    }
  };
  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(userLogoutAction());
  };
  const list = (anchor) => (
    <Box
      sx={{
        color: "#222",

        width: anchor === "top" || anchor === "bottom" ? "auto" : 250,
      }}
      role="presentation"
      onClick={toggleDrawer}
      onKeyDown={toggleDrawer}
    >
      <List>
        {["Home", "Events", "Courses", "Certificates", "About Us"].map(
          (text, index) => (
            <ListItem key={index} disablePadding>
              <ListItemButton onClick={() => handleListItemClicked(index)}>
                <ListItemIcon style={{ color: "#222" }}>
                  {index === 0 ? (
                    <HomeIcon />
                  ) : index === 1 ? (
                    <SubscriptionsIcon />
                  ) : index === 2 ? (
                    <LibraryBooksIcon />
                  ) : index === 3 ? (
                    <CardMembershipIcon />
                  ) : (
                    <InfoIcon />
                  )}
                </ListItemIcon>
                <ListItemText
                  primary={
                    <span style={{ fontWeight: "500", fontSize: "18px" }}>
                      {text}
                    </span>
                  }
                />
              </ListItemButton>
            </ListItem>
          )
        )}
      </List>
      <Divider />
      {userInfo ? (
        <div>
          <Button
            onClick={handleSignOut}
            style={{
              width: "90%",
              backgroundColor: "#FF3131",
              padding: "10px",
              color: "#fff",
              textTransform: "inherit",
              fontSize: "18px",
              fontWeight: "500",
              margin: "30px 10px",
            }}
          >
            Sign out
          </Button>
        </div>
      ) : (
        <List>
          {["Sign In", "Sign UP"].map((text, index) => (
            <ListItem key={index} disablePadding>
              {index === 0 ? (
                <ListItemButton
                  onClick={() => history.push("/login")}
                  style={{
                    border: "1px solid #FF3131",
                    color: "#222",
                    textTransform: "inherit",
                    fontSize: "14px",
                    padding: "5px",
                    width: "130px",
                    textAlign: "center",
                    margin: "10px",
                    borderRadius: "12px",
                  }}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              ) : (
                <ListItemButton
                  onClick={() => history.push("/signup")}
                  style={{
                    background: "#FF3131",
                    color: "#fff",
                    textTransform: "inherit",
                    fontSize: "14px",
                    padding: "5px",
                    width: "130px",
                    textAlign: "center",
                    margin: "10px",
                    borderRadius: "12px",
                  }}
                >
                  <ListItemText primary={text} />
                </ListItemButton>
              )}
            </ListItem>
          ))}
        </List>
      )}
      {/* <div>
        <div>
          <Button
            style={{
              background: "#FF3131",
              color: "#fff",
              textTransform: "inherit",
              fontSize: "14px",
              padding: "5px",
              width: "130px",
            }}
          >
            Sign in
          </Button>
        </div>
        <div>
          <Button>Sign Up</Button>
        </div>
      </div> */}
    </Box>
  );

  return (
    <div>
      <React.Fragment key={"right"}>
        <Drawer
          anchor={"right"}
          open={isOpen}
          onClose={toggleDrawer}
          PaperProps={{ sx: { backgroundColor: "#fffcf3", color: "#222" } }}
        >
          {list("right")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
