import {
  DASHBOARD_FILTER_COMPLETED_CERTIFICATIONS,
  DASHBOARD_FILTER_INPROGRESS_CERTIFICATIONS,
  DASHBOARD_FILTER_INREVIEW_CERTIFICATIONS,
  DASHBOARD_GET_ALL_SUBMISSIONS_FAILED,
  DASHBOARD_GET_ALL_SUBMISSIONS_LOADING,
  DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_FAIL,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_REQUEST,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_RESET,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_SUCCESS,
  DASHBOARD_UPLOAD_SOLUTION_RESET,
  DASHBOARD_UPLOAD_SOLUTION_SELCTION,
} from "../../constants/Dashboard/dashboardConstants";
import axios from "axios";
import { APIRequestURI } from "../../utils/api_request_uri";
import { decryptText } from "../../utils/decrypt_cookie";

export const DashboardAllSubmissionsAction =
  () => async (dispatch, getState) => {
    dispatch({ type: DASHBOARD_GET_ALL_SUBMISSIONS_LOADING });
    const {
      userLogin: { userInfo },
    } = getState();

    try {
      if (userInfo !== undefined && userInfo !== null) {
        const cachedData = sessionStorage.getItem(
          "acetechies-certifications-info"
        );
        if (cachedData !== null && cachedData !== undefined) {
          let decryptedData = decryptText(JSON.parse(cachedData));
          dispatch({
            type: DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS,
            payload: decryptedData,
          });
        } else {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${userInfo?.token}`,
            },
          };
          const URI = new APIRequestURI().getBaseURI();
          const { data } = await axios.get(
            `${URI}/api/submission/allsubmissions`,
            config
          );
          let decryptedData = decryptText(data);
          dispatch({
            type: DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS,
            payload: decryptedData,
          });
          sessionStorage.setItem(
            "acetechies-certifications-info",
            JSON.stringify(data)
          );
        }
      }
    } catch (error) {
      dispatch({
        type: DASHBOARD_GET_ALL_SUBMISSIONS_FAILED,
        payload: error.message,
      });
    }
  };

export const DashboardFilterCertificationAction =
  (tag) => (dispatch, getState) => {
    const {
      usersAllSubmissionsStore: { allSubmissions },
    } = getState();

    var data = allSubmissions?.filter(
      (submission) => submission?.current_status === tag
    );
    if (tag === "inprogress") {
      dispatch({
        type: DASHBOARD_FILTER_INPROGRESS_CERTIFICATIONS,
        payload: data,
      });
    }
    if (tag === "completed") {
      dispatch({
        type: DASHBOARD_FILTER_COMPLETED_CERTIFICATIONS,
        payload: data,
      });
    }
    if (tag === "inreview") {
      dispatch({
        type: DASHBOARD_FILTER_INREVIEW_CERTIFICATIONS,
        payload: data,
      });
    }
  };

export const DashboardUploadSolutionAction =
  (selectedProblem) => (dispatch) => {
    dispatch({
      type: DASHBOARD_UPLOAD_SOLUTION_SELCTION,
      payload: selectedProblem,
    });
  };

export const DashboardUploadResetSolutionAction = () => (dispatch) => {
  dispatch({
    type: DASHBOARD_UPLOAD_SOLUTION_RESET,
  });
};
export const DashboardUploadSolutionGithubResetSolutionAction =
  () => (dispatch) => {
    dispatch({
      type: DASHBOARD_UPLOAD_SOLUTION_GITHUB_RESET,
    });
  };

export const DashboardUploadGithublinkAction =
  (githublink, submissionId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: DASHBOARD_UPLOAD_SOLUTION_GITHUB_REQUEST,
      });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.put(
        `${URI}/api/submission/submit`,
        { submissionId, githublink },
        config
      );

      dispatch({
        type: DASHBOARD_UPLOAD_SOLUTION_GITHUB_SUCCESS,
        payload: data,
      });
      sessionStorage.removeItem("acetechies-certifications-info");
    } catch (error) {
      dispatch({
        type: DASHBOARD_UPLOAD_SOLUTION_GITHUB_FAIL,
        payload: error.message,
      });
    }
  };
