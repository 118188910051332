import React from "react";
import "./ImageOpeningComponent.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { Button } from "@mui/material";

function ImageOpeningComponent({ images, bigImageWidth }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.transparent",
    border: "none",
    p: 4,
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = (reason) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const rightArrow = (clickHandler) => {
    return (
      <div
        onClick={clickHandler}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#fff",
          position: "absolute",
          zIndex: "100",
          top: "50%",
          right: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArrowForwardIosIcon
          style={{
            fontSize: "22px",
          }}
        />
      </div>
    );
  };
  const leftArrow = (clickHandler) => {
    return (
      <div
        onClick={clickHandler}
        style={{
          width: "40px",
          height: "40px",
          borderRadius: "50%",
          backgroundColor: "#fff",
          position: "absolute",
          zIndex: "100",
          top: "50%",
          left: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArrowBackIosNewIcon
          style={{
            fontSize: "22px",
          }}
        />
      </div>
    );
  };

  const image_carousal = (images) => {
    return (
      <Carousel
        infiniteLoop={images?.length > 1}
        centerMode
        showArrows={true}
        swipeScrollTolerance={30}
        preventMovementUntilSwipeScrollTolerance={true}
        renderArrowNext={(clickHandler, hasPrev) => rightArrow(clickHandler)}
        renderArrowPrev={(clickHandler, hasNext) => leftArrow(clickHandler)}
      >
        {images?.map((image, index) => {
          return (
            <div key={index} style={{ minWidth: "110px" }}>
              <img width={650} src={image} alt="" />;
            </div>
          );
        })}
      </Carousel>
    );
  };

  return (
    <div>
      <div onClick={handleOpen} className="image-opening-container">
        <div className="big-image">
          {
            <img
              style={{ width: `${bigImageWidth ? bigImageWidth : "20vw"}` }}
              src={images !== undefined && images[0]}
              alt=""
            />
          }
        </div>
        <div className="small-images">
          {images?.length > 1 && (
            <div className="small-image">
              <img src={images !== undefined && images[1]} alt="" />
            </div>
          )}
          {images?.length > 2 && (
            <div className="small-image">
              <img src={images !== undefined && images[2]} alt="" />
            </div>
          )}
          {images?.length > 3 && (
            <div className="small-image overlay">
              <img src={images !== undefined && images[3]} alt="" />
              <div className="overlay-content">
                <h5 style={{ color: "#FF3131" }}>view more</h5>
              </div>
            </div>
          )}
        </div>
        {images?.length <= 3 && (
          <div style={{ marginTop: "10px" }}>
            <Button className="view_result_button" onClick={handleOpen}>
              View
            </Button>
          </div>
        )}
      </div>
      <Modal
        open={open}
        onClose={(e, reason) => handleClose(reason)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <div
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "30px",
              right: "-40px",
              backgroundColor: "#fff",
              padding: "8px",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
            }}
          >
            <CloseIcon
              style={{
                fontSize: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            />
          </div>

          <div>{image_carousal(images)}</div>
        </Box>
      </Modal>
    </div>
  );
}

export default ImageOpeningComponent;
