import {
  COURSE_PAYMENT_FAILED,
  COURSE_PAYMENT_INITIATED,
  COURSE_PAYMENT_SUCCESS,
  GET_SELECTED_COURSE_DETAILS_ERROR,
  GET_SELECTED_COURSE_DETAILS_PROGRESS,
  GET_SELECTED_COURSE_DETAILS_SUCCESS,
  GET_SUGGESTED_COURESES_FAIL,
  GET_SUGGESTED_COURESES_SUCCESS,
  GET_SUGGESTED_COURSES_PROGRESS,
  SELECTED_COURSE_FROM_LIST,
  SELECTED_EXPLORE_COURSE,
} from "../../constants/Education/coursesConstants";

export const selectedExploreCourseReducer = (
  state = { selectedFromExplore: "frontend" },
  action
) => {
  switch (action.type) {
    case SELECTED_EXPLORE_COURSE:
      return { ...state, selectedFromExplore: action.selectedCourse };

    default:
      return state;
  }
};

export const selectedCourseFromListReducer = (
  state = { selectedCourseFromList: 0 },
  action
) => {
  if (action.type === SELECTED_COURSE_FROM_LIST) {
    return {
      ...state,
      selectedCourseFromList: action.selectedCourse,
      myClickCategory: action.myClickCategory,
    };
  } else {
    return state;
  }
};

export const getSelectedCourseDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SELECTED_COURSE_DETAILS_PROGRESS:
      return {
        ...state,
        loading: true,
        succcess: false,
      };
    case GET_SELECTED_COURSE_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        selectedCourseList: action.payload,
        succcess: true,
      };
    case GET_SELECTED_COURSE_DETAILS_ERROR:
      return {
        ...state,
        loading: false,
        succcess: false,
        error: action.error,
      };

    default:
      return state;
  }
};

export const getSuggestedCoursesReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_SUGGESTED_COURSES_PROGRESS:
      return { loading: true, success: false };
    case GET_SUGGESTED_COURESES_SUCCESS:
      return {
        loading: false,
        suggestedCourses: action.payload,
        success: true,
      };
    case GET_SUGGESTED_COURESES_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const coursePaymentReducer = (state = {}, action) => {
  switch (action.type) {
    case COURSE_PAYMENT_INITIATED:
      return { loading: true, success: false };
    case COURSE_PAYMENT_SUCCESS:
      return {
        loading: false,
        redirectUrl: action.payload,
        success: true,
      };
    case COURSE_PAYMENT_FAILED:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
