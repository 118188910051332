import React from "react";
import "./CertificationEnrollment.css";
import { Helmet } from "react-helmet";
import { ImageConstants } from "../../utils/constants";
import { MyConversion } from "../../utils/conversion";
import MyStepper from "./reusables/MyStepper";
import useWindowResize from "../../utils/WindowResize";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MyMobileStepper from "./reusables/MyMobileStepper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { IconButton } from "@mui/material";

function CertificationErollment({ certificate_type }) {
  const isSmallScreen = useWindowResize();
  const url = window.location.href.split("/")[4];
  const conversion = new MyConversion().toCamelCase(url);
  var tabTitle = certificate_type === undefined ? conversion : certificate_type;
  const history = useHistory();
  const handleLogoClick = () => {
    history.goBack();
  };
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{`${tabTitle} Enrollment`}</title>
        <link
          rel="canonical"
          href={
            "https://res.cloudinary.com/cqn/image/upload/v1705319231/icons_clobgy.png"
          }
        />
      </Helmet>
      {isMobile || isSmallScreen ? (
        <div>
          <div className="mobile_stepper_logo_container">
            <IconButton onClick={() => history.goBack()}>
              <ArrowBackIcon
                style={{ marginTop: "10px", marginRight: "10px" }}
              />
            </IconButton>

            <img src={ImageConstants.courses_page_logo} alt="" />
            <h3>Internship Enrollment</h3>
          </div>
          <MyMobileStepper />
        </div>
      ) : (
        <div className="certification_enrollment_main_container">
          <div
            onClick={handleLogoClick}
            className="certification_enrollment_logo_container"
          >
            <img src={ImageConstants.courses_page_logo} alt="" />
          </div>
          <div style={{ padding: "30px 30px 0px 30px", width: "100%" }}>
            <MyStepper />
          </div>
        </div>
      )}
    </div>
  );
}

export default CertificationErollment;
