import React, { useState } from "react";
import "./CertificatesScreen.css";
import { Helmet } from "react-helmet";
import EducationTopBar from "./topbar/EducationTopBar";
import { ImageConstants, StringConstants } from "../../utils/constants";
import { Button } from "@mui/material";
import Typewriter from "typewriter-effect";
import CertificateMotionSection from "./reusables/CertificateMotionSection";
import ContinuousCarousel from "./reusables/ContinuousCarousel";
import EducationFooter from "./footer/education_footer";


function CertificatesScreen() {
  const [selectedLevel, setSelectedLevel] = useState("frontendbasic");
  const [selectedBackEndLevel, setSelectedBackEndLevel] =
    useState("backendbasic");
  const [selectedFullStackLevel, setSelectedFullStackLevel] =
    useState("fullstackbasic");
  const getHeadlineImage = () => {
    return (
      <>
        <div className="certificates_headline_image_container">
          <img src={ImageConstants.getCertificatesListImage} alt="" />
        </div>
      </>
    );
  };
  const getHeadline = () => {
    return (
      <div className="certificates_headline_container">
        <div className="certificates_headline">
          <div>
            <h1 style={{ fontSize: "3.2rem", margin: "10px 0px" }}>
              {" "}
              Grab Your Certificates for{" "}
            </h1>
            <Typewriter
              options={{
                strings: ["<span>FrontEnd</span>", "BackEnd", "FullStack"],
                autoStart: true,
                loop: true,
                wrapperClassName: "certificate_typewriter",
                cursorClassName: "certificate_cursor",
              }}
            />{" "}
            <h1 style={{ fontSize: "3.2rem", margin: "10px 0px" }}>
              {" "}
              Internship
            </h1>
          </div>
          <p>
            "Empowering students with comprehensive certification programs in
            frontend, backend, and full-stack development. Our curriculum spans
            basic, intermediate, and advanced levels, ensuring holistic skill
            development. Join us to embark on a journey of mastery and unlock
            limitless opportunities in the dynamic field of web development."
          </p>
        </div>
        <div className="certificate_headline_buttonContainer">
          <div className="certificate_headline_button">
            <Button>See how it works</Button>
          </div>
        </div>
      </div>
    );
  };
  const getBoxContainer = () => {
    return (
      <div className="certificates_getBoxContainer">
        {getIconWithLabel(
          StringConstants.certifyNow,
          ImageConstants.getBadgeImage
        )}
        <img
          style={{ width: "80px", height: "150px" }}
          src={ImageConstants.getLinesImage}
          alt=""
        />
        {getIconWithLabel(
          StringConstants.revenueBoost,
          ImageConstants.getIncreasingRevenue
        )}
        <img
          style={{ width: "80px", height: "150px" }}
          src={ImageConstants.getLinesImage}
          alt=""
        />
        {getIconWithLabel(
          StringConstants.recruiterSearch,
          ImageConstants.getRecruiterImage
        )}
      </div>
    );
  };
  const getIconWithLabel = (label, src) => {
    return (
      <div className="certificates_getIcon_container">
        <img src={src} alt="" />
        <h4>{label}</h4>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Certificates</title>
        <link
          rel="canonical"
          href={
            "https://res.cloudinary.com/cqn/image/upload/v1705319231/icons_clobgy.png"
          }
        />
      </Helmet>
      <EducationTopBar />
      <div style={{ display: "flex", marginTop: "50px" }}>
        <div className="certificate_education_circle">
          <img src={ImageConstants.my_semi_circle} alt="" />
        </div>
        <div className="certificate_top_container">
          <div>{getHeadline()}</div>
          {<div>{getHeadlineImage()}</div>}
        </div>
      </div>
      {getBoxContainer()}
      <CertificateMotionSection
        selectedLevel={selectedLevel}
        setSelectedLevel={setSelectedLevel}
        currentCourseLevel={"frontend"}
      />
      <CertificateMotionSection
        selectedLevel={selectedBackEndLevel}
        setSelectedLevel={setSelectedBackEndLevel}
        currentCourseLevel={"backend"}
      />
      <CertificateMotionSection
        selectedLevel={selectedFullStackLevel}
        setSelectedLevel={setSelectedFullStackLevel}
        currentCourseLevel={"fullstack"}
      />
      <div className="certificates_acknowledgements_container">
        <h1>Acknowledgement</h1>
        <ContinuousCarousel />
      </div>
      <div>
        <EducationFooter margin={"80px"} />
      </div>
    </div>
  );
}

export default CertificatesScreen;
