import React from "react";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import "./education_footer.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function EducationFooter({ margin }) {
  const history = useHistory();
  const currentYear = new Date().getFullYear();
  const getOurDetails = (title, subtitle, icon) => {
    return (
      <div className="our_details">
        {icon}
        <div className="our_details_data">
          <p>{title}</p>
          <a
            href={`https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=${subtitle}`}
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            {subtitle}
          </a>
        </div>
      </div>
    );
  };
  return (
    <div style={{ marginTop: `${margin}` }} className="education_footer">
      <div className="education_footer_container">
        <div>
          <div className="education_footer_title">
            <h1>AceTech</h1>
          </div>
          {getOurDetails(
            "Customer Queries",
            "support@acetechies.co.in",
            <SupportAgentIcon style={{ color: "#fff" }} />
          )}
          {getOurDetails(
            "General Queries",
            "acetech@acetechies.co.in",
            <HeadsetMicIcon style={{ color: "#fff" }} />
          )}
        </div>
        <div className="education_footer_certificate">
          <div>
            <h1>Certifications</h1>
          </div>
          <div>
            <div>
              <h3>Basic</h3>
              <li>FrontEnd Certificate</li>
              <li>BackEnd Certificate</li>
              <li>FullStack Certificate</li>
            </div>
            <div>
              <h3>Intermediate</h3>
              <li>FrontEnd Certificate</li>
              <li>BackEnd Certificate</li>
              <li>FullStack Certificate</li>
            </div>
            <div>
              <h3>Advanced</h3>
              <li>FrontEnd Certificate</li>
              <li>BackEnd Certificate</li>
              <li>FullStack Certificate</li>
            </div>
          </div>
        </div>

        <div className="education_footer_business">
          <div>
            <h1>Business</h1>
          </div>
          <div>
            <p onClick={() => history.push("terms")}>Terms Of Use</p>
            <p onClick={() => history.push("privacy")}>Privacy Policy</p>
            <p onClick={() => history.push("/cancellation-refund")}>
              Refund & Cancellation
            </p>
            <p onClick={() => history.push("contact")}>Contact Us</p>
          </div>
          <div style={{ marginTop: "30px" }}>
            <InstagramIcon
              style={{ color: "#fff", marginRight: "30px", cursor: "pointer" }}
            />
            <FacebookIcon
              style={{ color: "#fff", marginRight: "30px", cursor: "pointer" }}
            />
            <LinkedInIcon style={{ color: "#fff", cursor: "pointer" }} />
          </div>
        </div>
      </div>
      <div>
        <hr color="#fff" style={{ margin: "0px 50px" }} />
      </div>
      <div style={{ margin: "0px 50px" }}>
        <p
          style={{
            color: "#fff",
            margin: "20px 0px 0px 0px",
            paddingBottom: "20px",
          }}
        >
          Copyright © {currentYear} AceTech. All rights reserved.
        </p>
      </div>
    </div>
  );
}

export default EducationFooter;
