import {
  COMPLETE_USER_SUBMISSION_FAIL,
  COMPLETE_USER_SUBMISSION_REQUEST,
  COMPLETE_USER_SUBMISSION_SUCCESS,
  GETALL_SUBMITTEDUSERS_FAIL,
  GETALL_SUBMITTEDUSERS_REQUEST,
  GETALL_SUBMITTEDUSERS_SUCCESS,
} from "../../constants/Admin-Panels/adminPanelConstants";

export const getAllSubmittedUsersReducer = (state = {}, action) => {
  switch (action.type) {
    case GETALL_SUBMITTEDUSERS_REQUEST:
      return {
        loading: true,
      };
    case GETALL_SUBMITTEDUSERS_SUCCESS:
      return {
        loading: false,
        success: true,
        getAllSubmittedUsers: action.payload,
      };
    case GETALL_SUBMITTEDUSERS_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export const completeUserSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case COMPLETE_USER_SUBMISSION_REQUEST:
      return {
        loading: true,
      };
    case COMPLETE_USER_SUBMISSION_SUCCESS:
      return {
        loading: false,
        success: true,
        completedSubmission: action.payload,
      };
    case COMPLETE_USER_SUBMISSION_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
