import {
  COMPLETE_USER_SUBMISSION_FAIL,
  COMPLETE_USER_SUBMISSION_REQUEST,
  COMPLETE_USER_SUBMISSION_SUCCESS,
  GETALL_SUBMITTEDUSERS_FAIL,
  GETALL_SUBMITTEDUSERS_REQUEST,
  GETALL_SUBMITTEDUSERS_SUCCESS,
} from "../../constants/Admin-Panels/adminPanelConstants";
import { APIRequestURI } from "../../utils/api_request_uri";
import axios from "axios";

export const getAllSubmittedUsersAction =
  (certificate_status) => async (dispatch, getState) => {
    try {
      dispatch({ type: GETALL_SUBMITTEDUSERS_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.post(
        `${URI}/api/submission/superadmin/allsubmissions`,
        { certificate_status },
        config
      );

      dispatch({
        type: GETALL_SUBMITTEDUSERS_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: GETALL_SUBMITTEDUSERS_FAIL,
        payload: error.message,
      });
    }
  };

export const completeUserSubmissionAction =
  (submission_id) => async (dispatch, getState) => {
    try {
      dispatch({ type: COMPLETE_USER_SUBMISSION_REQUEST });
      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.put(
        `${URI}/api/submission/submit/complete`,
        { submission_id },
        config
      );

      dispatch({
        type: COMPLETE_USER_SUBMISSION_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({
        type: COMPLETE_USER_SUBMISSION_FAIL,
        payload: error.message,
      });
    }
  };
