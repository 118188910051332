import React from "react";
import { Helmet } from "react-helmet";

function MyReactHelmet({ value }) {
  return (
    <Helmet>
      <meta charSet="utf-8" />
      <title>{value}</title>
      <link
        rel="canonical"
        href="https://res.cloudinary.com/cqn/image/upload/v1705319231/icons_clobgy.png"
      />
    </Helmet>
  );
}

export default MyReactHelmet;
