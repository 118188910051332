import React, { useEffect, useRef, useState } from "react";
import "./MyFilterAndSlide.css";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { IconButton } from "@mui/material";

function MyFilterAndSlide({ data }) {
  const [isScrollbarVisible, setIsScrollbarVisible] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const element = ref.current;
      if (element) {
        setIsScrollbarVisible(element.scrollWidth > element.clientWidth);
      }
    };

    // Initially check if scrollbar is visible
    handleScroll();

    // Add event listener for scroll
    window.addEventListener("scroll", handleScroll);

    // Cleanup
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  });
  const ref = useRef(null);
  const forwardScroll = (scrollOffset) => {
    const element = ref.current;
    if (element) {
      const currentScrollLeft = element.scrollLeft;
      const targetScrollLeft = currentScrollLeft + scrollOffset;
      element.scrollTo({
        left: targetScrollLeft,
        behavior: "smooth",
      });
    }
  };
  const backwardScroll = (scrollOffset) => {
    const element = ref.current;
    if (element) {
      const currentScrollLeft = element.scrollLeft;
      const targetScrollLeft = currentScrollLeft - scrollOffset;
      element.scrollTo({
        left: targetScrollLeft,
        behavior: "smooth",
      });
    }
  };

  return (
    <div className="myFilterAndSlide">
      {isScrollbarVisible && (
        <div style={{ display: "flex", justifyContent: "end" }}>
          <IconButton onClick={() => backwardScroll(350)}>
            <div
              style={{
                width: "20px",
                height: "20px",
                padding: "10px",
                borderRadius: "52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid black",
              }}
            >
              <ArrowBackIosIcon
                style={{ fontSize: "20px", marginLeft: "5px" }}
              />
            </div>
          </IconButton>
          <IconButton onClick={() => forwardScroll(350)}>
            <div
              style={{
                width: "20px",
                height: "20px",
                padding: "10px",
                borderRadius: "52px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                border: "1px solid black",
              }}
            >
              <ArrowForwardIosIcon style={{ fontSize: "20px" }} />
            </div>
          </IconButton>
        </div>
      )}
      <div ref={ref} className="myFilterAndSlide_main_container">
        {data?.map((e) => {
          return e;
        })}
      </div>
    </div>
  );
}

export default MyFilterAndSlide;
