export const countryData = [
  {
    continent_name: "Europe",
    country_code: "AD",
    country_name: "Andorra",
    continent_code: "EU",
    capital_name: "Andorra la Vella",
    currency_code: "EUR",
    phone_code: "376",
    three_letter_country_code: "AND",
  },
  {
    continent_name: "Asia",
    country_code: "AE",
    country_name: "United Arab Emirates",
    continent_code: "AS",
    capital_name: "Abu Dhabi",
    currency_code: "AED",
    phone_code: "971",
    three_letter_country_code: "ARE",
  },
  {
    continent_name: "Asia",
    country_code: "AF",
    country_name: "Afghanistan",
    continent_code: "AS",
    capital_name: "Kabul",
    currency_code: "AFN",
    phone_code: "93",
    three_letter_country_code: "AFG",
  },
  {
    continent_name: "North America",
    country_code: "AG",
    country_name: "Antigua and Barbuda",
    continent_code: "NA",
    capital_name: "St. John's",
    currency_code: "XCD",
    phone_code: "+1-268",
    three_letter_country_code: "ATG",
  },
  {
    continent_name: "North America",
    country_code: "AI",
    country_name: "Anguilla",
    continent_code: "NA",
    capital_name: "The Valley",
    currency_code: "XCD",
    phone_code: "+1-264",
    three_letter_country_code: "AIA",
  },
  {
    continent_name: "Europe",
    country_code: "AL",
    country_name: "Albania",
    continent_code: "EU",
    capital_name: "Tirana",
    currency_code: "ALL",
    phone_code: "355",
    three_letter_country_code: "ALB",
  },
  {
    continent_name: "Asia",
    country_code: "AM",
    country_name: "Armenia",
    continent_code: "AS",
    capital_name: "Yerevan",
    currency_code: "AMD",
    phone_code: "374",
    three_letter_country_code: "ARM",
  },
  {
    continent_name: "Africa",
    country_code: "AO",
    country_name: "Angola",
    continent_code: "AF",
    capital_name: "Luanda",
    currency_code: "AOA",
    phone_code: "244",
    three_letter_country_code: "AGO",
  },
  {
    continent_name: "Antarctica",
    country_code: "AQ",
    country_name: "Antarctica",
    continent_code: "AN",
    capital_name: "",
    currency_code: "",
    phone_code: "",
    three_letter_country_code: "ATA",
  },
  {
    continent_name: "South America",
    country_code: "AR",
    country_name: "Argentina",
    continent_code: "SA",
    capital_name: "Buenos Aires",
    currency_code: "ARS",
    phone_code: "54",
    three_letter_country_code: "ARG",
  },
  {
    continent_name: "Oceania",
    country_code: "AS",
    country_name: "American Samoa",
    continent_code: "OC",
    capital_name: "Pago Pago",
    currency_code: "USD",
    phone_code: "+1-684",
    three_letter_country_code: "ASM",
  },
  {
    continent_name: "Europe",
    country_code: "AT",
    country_name: "Austria",
    continent_code: "EU",
    capital_name: "Vienna",
    currency_code: "EUR",
    phone_code: "43",
    three_letter_country_code: "AUT",
  },
  {
    continent_name: "Oceania",
    country_code: "AU",
    country_name: "Australia",
    continent_code: "OC",
    capital_name: "Canberra",
    currency_code: "AUD",
    phone_code: "61",
    three_letter_country_code: "AUS",
  },
  {
    continent_name: "North America",
    country_code: "AW",
    country_name: "Aruba",
    continent_code: "NA",
    capital_name: "Oranjestad",
    currency_code: "AWG",
    phone_code: "297",
    three_letter_country_code: "ABW",
  },
  {
    continent_name: "Europe",
    country_code: "AX",
    country_name: "Aland Islands",
    continent_code: "EU",
    capital_name: "Mariehamn",
    currency_code: "EUR",
    phone_code: "+358-18",
    three_letter_country_code: "ALA",
  },
  {
    continent_name: "Asia",
    country_code: "AZ",
    country_name: "Azerbaijan",
    continent_code: "AS",
    capital_name: "Baku",
    currency_code: "AZN",
    phone_code: "994",
    three_letter_country_code: "AZE",
  },
  {
    continent_name: "Europe",
    country_code: "BA",
    country_name: "Bosnia and Herzegovina",
    continent_code: "EU",
    capital_name: "Sarajevo",
    currency_code: "BAM",
    phone_code: "387",
    three_letter_country_code: "BIH",
  },
  {
    continent_name: "North America",
    country_code: "BB",
    country_name: "Barbados",
    continent_code: "NA",
    capital_name: "Bridgetown",
    currency_code: "BBD",
    phone_code: "+1-246",
    three_letter_country_code: "BRB",
  },
  {
    continent_name: "Asia",
    country_code: "BD",
    country_name: "Bangladesh",
    continent_code: "AS",
    capital_name: "Dhaka",
    currency_code: "BDT",
    phone_code: "880",
    three_letter_country_code: "BGD",
  },
  {
    continent_name: "Europe",
    country_code: "BE",
    country_name: "Belgium",
    continent_code: "EU",
    capital_name: "Brussels",
    currency_code: "EUR",
    phone_code: "32",
    three_letter_country_code: "BEL",
  },
  {
    continent_name: "Africa",
    country_code: "BF",
    country_name: "Burkina Faso",
    continent_code: "AF",
    capital_name: "Ouagadougou",
    currency_code: "XOF",
    phone_code: "226",
    three_letter_country_code: "BFA",
  },
  {
    continent_name: "Europe",
    country_code: "BG",
    country_name: "Bulgaria",
    continent_code: "EU",
    capital_name: "Sofia",
    currency_code: "BGN",
    phone_code: "359",
    three_letter_country_code: "BGR",
  },
  {
    continent_name: "Asia",
    country_code: "BH",
    country_name: "Bahrain",
    continent_code: "AS",
    capital_name: "Manama",
    currency_code: "BHD",
    phone_code: "973",
    three_letter_country_code: "BHR",
  },
  {
    continent_name: "Africa",
    country_code: "BI",
    country_name: "Burundi",
    continent_code: "AF",
    capital_name: "Bujumbura",
    currency_code: "BIF",
    phone_code: "257",
    three_letter_country_code: "BDI",
  },
  {
    continent_name: "Africa",
    country_code: "BJ",
    country_name: "Benin",
    continent_code: "AF",
    capital_name: "Porto-Novo",
    currency_code: "XOF",
    phone_code: "229",
    three_letter_country_code: "BEN",
  },
  {
    continent_name: "North America",
    country_code: "BL",
    country_name: "Saint Barthelemy",
    continent_code: "NA",
    capital_name: "Gustavia",
    currency_code: "EUR",
    phone_code: "590",
    three_letter_country_code: "BLM",
  },
  {
    continent_name: "North America",
    country_code: "BM",
    country_name: "Bermuda",
    continent_code: "NA",
    capital_name: "Hamilton",
    currency_code: "BMD",
    phone_code: "+1-441",
    three_letter_country_code: "BMU",
  },
  {
    continent_name: "Asia",
    country_code: "BN",
    country_name: "Brunei",
    continent_code: "AS",
    capital_name: "Bandar Seri Begawan",
    currency_code: "BND",
    phone_code: "673",
    three_letter_country_code: "BRN",
  },
  {
    continent_name: "South America",
    country_code: "BO",
    country_name: "Bolivia",
    continent_code: "SA",
    capital_name: "Sucre",
    currency_code: "BOB",
    phone_code: "591",
    three_letter_country_code: "BOL",
  },
  {
    continent_name: "North America",
    country_code: "BQ",
    country_name: "Bonaire, Saint Eustatius and Saba ",
    continent_code: "NA",
    capital_name: "",
    currency_code: "USD",
    phone_code: "599",
    three_letter_country_code: "BES",
  },
  {
    continent_name: "South America",
    country_code: "BR",
    country_name: "Brazil",
    continent_code: "SA",
    capital_name: "Brasilia",
    currency_code: "BRL",
    phone_code: "55",
    three_letter_country_code: "BRA",
  },
  {
    continent_name: "North America",
    country_code: "BS",
    country_name: "Bahamas",
    continent_code: "NA",
    capital_name: "Nassau",
    currency_code: "BSD",
    phone_code: "+1-242",
    three_letter_country_code: "BHS",
  },
  {
    continent_name: "Asia",
    country_code: "BT",
    country_name: "Bhutan",
    continent_code: "AS",
    capital_name: "Thimphu",
    currency_code: "BTN",
    phone_code: "975",
    three_letter_country_code: "BTN",
  },
  {
    continent_name: "Antarctica",
    country_code: "BV",
    country_name: "Bouvet Island",
    continent_code: "AN",
    capital_name: "",
    currency_code: "NOK",
    phone_code: "",
    three_letter_country_code: "BVT",
  },
  {
    continent_name: "Africa",
    country_code: "BW",
    country_name: "Botswana",
    continent_code: "AF",
    capital_name: "Gaborone",
    currency_code: "BWP",
    phone_code: "267",
    three_letter_country_code: "BWA",
  },
  {
    continent_name: "Europe",
    country_code: "BY",
    country_name: "Belarus",
    continent_code: "EU",
    capital_name: "Minsk",
    currency_code: "BYR",
    phone_code: "375",
    three_letter_country_code: "BLR",
  },
  {
    continent_name: "North America",
    country_code: "BZ",
    country_name: "Belize",
    continent_code: "NA",
    capital_name: "Belmopan",
    currency_code: "BZD",
    phone_code: "501",
    three_letter_country_code: "BLZ",
  },
  {
    continent_name: "North America",
    country_code: "CA",
    country_name: "Canada",
    continent_code: "NA",
    capital_name: "Ottawa",
    currency_code: "CAD",
    phone_code: "1",
    three_letter_country_code: "CAN",
  },
  {
    continent_name: "Asia",
    country_code: "CC",
    country_name: "Cocos Islands",
    continent_code: "AS",
    capital_name: "West Island",
    currency_code: "AUD",
    phone_code: "61",
    three_letter_country_code: "CCK",
  },
  {
    continent_name: "Africa",
    country_code: "CD",
    country_name: "Democratic Republic of the Congo",
    continent_code: "AF",
    capital_name: "Kinshasa",
    currency_code: "CDF",
    phone_code: "243",
    three_letter_country_code: "COD",
  },
  {
    continent_name: "Africa",
    country_code: "CF",
    country_name: "Central African Republic",
    continent_code: "AF",
    capital_name: "Bangui",
    currency_code: "XAF",
    phone_code: "236",
    three_letter_country_code: "CAF",
  },
  {
    continent_name: "Africa",
    country_code: "CG",
    country_name: "Republic of the Congo",
    continent_code: "AF",
    capital_name: "Brazzaville",
    currency_code: "XAF",
    phone_code: "242",
    three_letter_country_code: "COG",
  },
  {
    continent_name: "Europe",
    country_code: "CH",
    country_name: "Switzerland",
    continent_code: "EU",
    capital_name: "Berne",
    currency_code: "CHF",
    phone_code: "41",
    three_letter_country_code: "CHE",
  },
  {
    continent_name: "Africa",
    country_code: "CI",
    country_name: "Ivory Coast",
    continent_code: "AF",
    capital_name: "Yamoussoukro",
    currency_code: "XOF",
    phone_code: "225",
    three_letter_country_code: "CIV",
  },
  {
    continent_name: "Oceania",
    country_code: "CK",
    country_name: "Cook Islands",
    continent_code: "OC",
    capital_name: "Avarua",
    currency_code: "NZD",
    phone_code: "682",
    three_letter_country_code: "COK",
  },
  {
    continent_name: "South America",
    country_code: "CL",
    country_name: "Chile",
    continent_code: "SA",
    capital_name: "Santiago",
    currency_code: "CLP",
    phone_code: "56",
    three_letter_country_code: "CHL",
  },
  {
    continent_name: "Africa",
    country_code: "CM",
    country_name: "Cameroon",
    continent_code: "AF",
    capital_name: "Yaounde",
    currency_code: "XAF",
    phone_code: "237",
    three_letter_country_code: "CMR",
  },
  {
    continent_name: "Asia",
    country_code: "CN",
    country_name: "China",
    continent_code: "AS",
    capital_name: "Beijing",
    currency_code: "CNY",
    phone_code: "86",
    three_letter_country_code: "CHN",
  },
  {
    continent_name: "South America",
    country_code: "CO",
    country_name: "Colombia",
    continent_code: "SA",
    capital_name: "Bogota",
    currency_code: "COP",
    phone_code: "57",
    three_letter_country_code: "COL",
  },
  {
    continent_name: "North America",
    country_code: "CR",
    country_name: "Costa Rica",
    continent_code: "NA",
    capital_name: "San Jose",
    currency_code: "CRC",
    phone_code: "506",
    three_letter_country_code: "CRI",
  },
  {
    continent_name: "North America",
    country_code: "CU",
    country_name: "Cuba",
    continent_code: "NA",
    capital_name: "Havana",
    currency_code: "CUP",
    phone_code: "53",
    three_letter_country_code: "CUB",
  },
  {
    continent_name: "Africa",
    country_code: "CV",
    country_name: "Cape Verde",
    continent_code: "AF",
    capital_name: "Praia",
    currency_code: "CVE",
    phone_code: "238",
    three_letter_country_code: "CPV",
  },
  {
    continent_name: "North America",
    country_code: "CW",
    country_name: "Curacao",
    continent_code: "NA",
    capital_name: " Willemstad",
    currency_code: "ANG",
    phone_code: "599",
    three_letter_country_code: "CUW",
  },
  {
    continent_name: "Asia",
    country_code: "CX",
    country_name: "Christmas Island",
    continent_code: "AS",
    capital_name: "Flying Fish Cove",
    currency_code: "AUD",
    phone_code: "61",
    three_letter_country_code: "CXR",
  },
  {
    continent_name: "Europe",
    country_code: "CY",
    country_name: "Cyprus",
    continent_code: "EU",
    capital_name: "Nicosia",
    currency_code: "EUR",
    phone_code: "357",
    three_letter_country_code: "CYP",
  },
  {
    continent_name: "Europe",
    country_code: "CZ",
    country_name: "Czech Republic",
    continent_code: "EU",
    capital_name: "Prague",
    currency_code: "CZK",
    phone_code: "420",
    three_letter_country_code: "CZE",
  },
  {
    continent_name: "Europe",
    country_code: "DE",
    country_name: "Germany",
    continent_code: "EU",
    capital_name: "Berlin",
    currency_code: "EUR",
    phone_code: "49",
    three_letter_country_code: "DEU",
  },
  {
    continent_name: "Africa",
    country_code: "DJ",
    country_name: "Djibouti",
    continent_code: "AF",
    capital_name: "Djibouti",
    currency_code: "DJF",
    phone_code: "253",
    three_letter_country_code: "DJI",
  },
  {
    continent_name: "Europe",
    country_code: "DK",
    country_name: "Denmark",
    continent_code: "EU",
    capital_name: "Copenhagen",
    currency_code: "DKK",
    phone_code: "45",
    three_letter_country_code: "DNK",
  },
  {
    continent_name: "North America",
    country_code: "DM",
    country_name: "Dominica",
    continent_code: "NA",
    capital_name: "Roseau",
    currency_code: "XCD",
    phone_code: "+1-767",
    three_letter_country_code: "DMA",
  },
  {
    continent_name: "North America",
    country_code: "DO",
    country_name: "Dominican Republic",
    continent_code: "NA",
    capital_name: "Santo Domingo",
    currency_code: "DOP",
    phone_code: "+1-809 and 1-829",
    three_letter_country_code: "DOM",
  },
  {
    continent_name: "Africa",
    country_code: "DZ",
    country_name: "Algeria",
    continent_code: "AF",
    capital_name: "Algiers",
    currency_code: "DZD",
    phone_code: "213",
    three_letter_country_code: "DZA",
  },
  {
    continent_name: "South America",
    country_code: "EC",
    country_name: "Ecuador",
    continent_code: "SA",
    capital_name: "Quito",
    currency_code: "USD",
    phone_code: "593",
    three_letter_country_code: "ECU",
  },
  {
    continent_name: "Europe",
    country_code: "EE",
    country_name: "Estonia",
    continent_code: "EU",
    capital_name: "Tallinn",
    currency_code: "EUR",
    phone_code: "372",
    three_letter_country_code: "EST",
  },
  {
    continent_name: "Africa",
    country_code: "EG",
    country_name: "Egypt",
    continent_code: "AF",
    capital_name: "Cairo",
    currency_code: "EGP",
    phone_code: "20",
    three_letter_country_code: "EGY",
  },
  {
    continent_name: "Africa",
    country_code: "EH",
    country_name: "Western Sahara",
    continent_code: "AF",
    capital_name: "El-Aaiun",
    currency_code: "MAD",
    phone_code: "212",
    three_letter_country_code: "ESH",
  },
  {
    continent_name: "Africa",
    country_code: "ER",
    country_name: "Eritrea",
    continent_code: "AF",
    capital_name: "Asmara",
    currency_code: "ERN",
    phone_code: "291",
    three_letter_country_code: "ERI",
  },
  {
    continent_name: "Europe",
    country_code: "ES",
    country_name: "Spain",
    continent_code: "EU",
    capital_name: "Madrid",
    currency_code: "EUR",
    phone_code: "34",
    three_letter_country_code: "ESP",
  },
  {
    continent_name: "Africa",
    country_code: "ET",
    country_name: "Ethiopia",
    continent_code: "AF",
    capital_name: "Addis Ababa",
    currency_code: "ETB",
    phone_code: "251",
    three_letter_country_code: "ETH",
  },
  {
    continent_name: "Europe",
    country_code: "FI",
    country_name: "Finland",
    continent_code: "EU",
    capital_name: "Helsinki",
    currency_code: "EUR",
    phone_code: "358",
    three_letter_country_code: "FIN",
  },
  {
    continent_name: "Oceania",
    country_code: "FJ",
    country_name: "Fiji",
    continent_code: "OC",
    capital_name: "Suva",
    currency_code: "FJD",
    phone_code: "679",
    three_letter_country_code: "FJI",
  },
  {
    continent_name: "South America",
    country_code: "FK",
    country_name: "Falkland Islands",
    continent_code: "SA",
    capital_name: "Stanley",
    currency_code: "FKP",
    phone_code: "500",
    three_letter_country_code: "FLK",
  },
  {
    continent_name: "Oceania",
    country_code: "FM",
    country_name: "Micronesia",
    continent_code: "OC",
    capital_name: "Palikir",
    currency_code: "USD",
    phone_code: "691",
    three_letter_country_code: "FSM",
  },
  {
    continent_name: "Europe",
    country_code: "FO",
    country_name: "Faroe Islands",
    continent_code: "EU",
    capital_name: "Torshavn",
    currency_code: "DKK",
    phone_code: "298",
    three_letter_country_code: "FRO",
  },
  {
    continent_name: "Europe",
    country_code: "FR",
    country_name: "France",
    continent_code: "EU",
    capital_name: "Paris",
    currency_code: "EUR",
    phone_code: "33",
    three_letter_country_code: "FRA",
  },
  {
    continent_name: "Africa",
    country_code: "GA",
    country_name: "Gabon",
    continent_code: "AF",
    capital_name: "Libreville",
    currency_code: "XAF",
    phone_code: "241",
    three_letter_country_code: "GAB",
  },
  {
    continent_name: "Europe",
    country_code: "GB",
    country_name: "United Kingdom",
    continent_code: "EU",
    capital_name: "London",
    currency_code: "GBP",
    phone_code: "44",
    three_letter_country_code: "GBR",
  },
  {
    continent_name: "North America",
    country_code: "GD",
    country_name: "Grenada",
    continent_code: "NA",
    capital_name: "St. George's",
    currency_code: "XCD",
    phone_code: "+1-473",
    three_letter_country_code: "GRD",
  },
  {
    continent_name: "Asia",
    country_code: "GE",
    country_name: "Georgia",
    continent_code: "AS",
    capital_name: "Tbilisi",
    currency_code: "GEL",
    phone_code: "995",
    three_letter_country_code: "GEO",
  },
  {
    continent_name: "South America",
    country_code: "GF",
    country_name: "French Guiana",
    continent_code: "SA",
    capital_name: "Cayenne",
    currency_code: "EUR",
    phone_code: "594",
    three_letter_country_code: "GUF",
  },
  {
    continent_name: "Europe",
    country_code: "GG",
    country_name: "Guernsey",
    continent_code: "EU",
    capital_name: "St Peter Port",
    currency_code: "GBP",
    phone_code: "+44-1481",
    three_letter_country_code: "GGY",
  },
  {
    continent_name: "Africa",
    country_code: "GH",
    country_name: "Ghana",
    continent_code: "AF",
    capital_name: "Accra",
    currency_code: "GHS",
    phone_code: "233",
    three_letter_country_code: "GHA",
  },
  {
    continent_name: "Europe",
    country_code: "GI",
    country_name: "Gibraltar",
    continent_code: "EU",
    capital_name: "Gibraltar",
    currency_code: "GIP",
    phone_code: "350",
    three_letter_country_code: "GIB",
  },
  {
    continent_name: "North America",
    country_code: "GL",
    country_name: "Greenland",
    continent_code: "NA",
    capital_name: "Nuuk",
    currency_code: "DKK",
    phone_code: "299",
    three_letter_country_code: "GRL",
  },
  {
    continent_name: "Africa",
    country_code: "GM",
    country_name: "Gambia",
    continent_code: "AF",
    capital_name: "Banjul",
    currency_code: "GMD",
    phone_code: "220",
    three_letter_country_code: "GMB",
  },
  {
    continent_name: "Africa",
    country_code: "GN",
    country_name: "Guinea",
    continent_code: "AF",
    capital_name: "Conakry",
    currency_code: "GNF",
    phone_code: "224",
    three_letter_country_code: "GIN",
  },
  {
    continent_name: "North America",
    country_code: "GP",
    country_name: "Guadeloupe",
    continent_code: "NA",
    capital_name: "Basse-Terre",
    currency_code: "EUR",
    phone_code: "590",
    three_letter_country_code: "GLP",
  },
  {
    continent_name: "Africa",
    country_code: "GQ",
    country_name: "Equatorial Guinea",
    continent_code: "AF",
    capital_name: "Malabo",
    currency_code: "XAF",
    phone_code: "240",
    three_letter_country_code: "GNQ",
  },
  {
    continent_name: "Europe",
    country_code: "GR",
    country_name: "Greece",
    continent_code: "EU",
    capital_name: "Athens",
    currency_code: "EUR",
    phone_code: "30",
    three_letter_country_code: "GRC",
  },
  {
    continent_name: "Antarctica",
    country_code: "GS",
    country_name: "South Georgia and the South Sandwich Islands",
    continent_code: "AN",
    capital_name: "Grytviken",
    currency_code: "GBP",
    phone_code: "",
    three_letter_country_code: "SGS",
  },
  {
    continent_name: "North America",
    country_code: "GT",
    country_name: "Guatemala",
    continent_code: "NA",
    capital_name: "Guatemala City",
    currency_code: "GTQ",
    phone_code: "502",
    three_letter_country_code: "GTM",
  },
  {
    continent_name: "Oceania",
    country_code: "GU",
    country_name: "Guam",
    continent_code: "OC",
    capital_name: "Hagatna",
    currency_code: "USD",
    phone_code: "+1-671",
    three_letter_country_code: "GUM",
  },
  {
    continent_name: "Africa",
    country_code: "GW",
    country_name: "Guinea-Bissau",
    continent_code: "AF",
    capital_name: "Bissau",
    currency_code: "XOF",
    phone_code: "245",
    three_letter_country_code: "GNB",
  },
  {
    continent_name: "South America",
    country_code: "GY",
    country_name: "Guyana",
    continent_code: "SA",
    capital_name: "Georgetown",
    currency_code: "GYD",
    phone_code: "592",
    three_letter_country_code: "GUY",
  },
  {
    continent_name: "Asia",
    country_code: "HK",
    country_name: "Hong Kong",
    continent_code: "AS",
    capital_name: "Hong Kong",
    currency_code: "HKD",
    phone_code: "852",
    three_letter_country_code: "HKG",
  },
  {
    continent_name: "Antarctica",
    country_code: "HM",
    country_name: "Heard Island and McDonald Islands",
    continent_code: "AN",
    capital_name: "",
    currency_code: "AUD",
    phone_code: " ",
    three_letter_country_code: "HMD",
  },
  {
    continent_name: "North America",
    country_code: "HN",
    country_name: "Honduras",
    continent_code: "NA",
    capital_name: "Tegucigalpa",
    currency_code: "HNL",
    phone_code: "504",
    three_letter_country_code: "HND",
  },
  {
    continent_name: "Europe",
    country_code: "HR",
    country_name: "Croatia",
    continent_code: "EU",
    capital_name: "Zagreb",
    currency_code: "HRK",
    phone_code: "385",
    three_letter_country_code: "HRV",
  },
  {
    continent_name: "North America",
    country_code: "HT",
    country_name: "Haiti",
    continent_code: "NA",
    capital_name: "Port-au-Prince",
    currency_code: "HTG",
    phone_code: "509",
    three_letter_country_code: "HTI",
  },
  {
    continent_name: "Europe",
    country_code: "HU",
    country_name: "Hungary",
    continent_code: "EU",
    capital_name: "Budapest",
    currency_code: "HUF",
    phone_code: "36",
    three_letter_country_code: "HUN",
  },
  {
    continent_name: "Asia",
    country_code: "ID",
    country_name: "Indonesia",
    continent_code: "AS",
    capital_name: "Jakarta",
    currency_code: "IDR",
    phone_code: "62",
    three_letter_country_code: "IDN",
  },
  {
    continent_name: "Europe",
    country_code: "IE",
    country_name: "Ireland",
    continent_code: "EU",
    capital_name: "Dublin",
    currency_code: "EUR",
    phone_code: "353",
    three_letter_country_code: "IRL",
  },
  {
    continent_name: "Asia",
    country_code: "IL",
    country_name: "Israel",
    continent_code: "AS",
    capital_name: "Jerusalem",
    currency_code: "ILS",
    phone_code: "972",
    three_letter_country_code: "ISR",
  },
  {
    continent_name: "Europe",
    country_code: "IM",
    country_name: "Isle of Man",
    continent_code: "EU",
    capital_name: "Douglas, Isle of Man",
    currency_code: "GBP",
    phone_code: "+44-1624",
    three_letter_country_code: "IMN",
  },
  {
    continent_name: "Asia",
    country_code: "IN",
    country_name: "India",
    continent_code: "AS",
    capital_name: "New Delhi",
    currency_code: "INR",
    phone_code: "91",
    three_letter_country_code: "IND",
  },
  {
    continent_name: "Asia",
    country_code: "IO",
    country_name: "British Indian Ocean Territory",
    continent_code: "AS",
    capital_name: "Diego Garcia",
    currency_code: "USD",
    phone_code: "246",
    three_letter_country_code: "IOT",
  },
  {
    continent_name: "Asia",
    country_code: "IQ",
    country_name: "Iraq",
    continent_code: "AS",
    capital_name: "Baghdad",
    currency_code: "IQD",
    phone_code: "964",
    three_letter_country_code: "IRQ",
  },
  {
    continent_name: "Asia",
    country_code: "IR",
    country_name: "Iran",
    continent_code: "AS",
    capital_name: "Tehran",
    currency_code: "IRR",
    phone_code: "98",
    three_letter_country_code: "IRN",
  },
  {
    continent_name: "Europe",
    country_code: "IS",
    country_name: "Iceland",
    continent_code: "EU",
    capital_name: "Reykjavik",
    currency_code: "ISK",
    phone_code: "354",
    three_letter_country_code: "ISL",
  },
  {
    continent_name: "Europe",
    country_code: "IT",
    country_name: "Italy",
    continent_code: "EU",
    capital_name: "Rome",
    currency_code: "EUR",
    phone_code: "39",
    three_letter_country_code: "ITA",
  },
  {
    continent_name: "Europe",
    country_code: "JE",
    country_name: "Jersey",
    continent_code: "EU",
    capital_name: "Saint Helier",
    currency_code: "GBP",
    phone_code: "+44-1534",
    three_letter_country_code: "JEY",
  },
  {
    continent_name: "North America",
    country_code: "JM",
    country_name: "Jamaica",
    continent_code: "NA",
    capital_name: "Kingston",
    currency_code: "JMD",
    phone_code: "+1-876",
    three_letter_country_code: "JAM",
  },
  {
    continent_name: "Asia",
    country_code: "JO",
    country_name: "Jordan",
    continent_code: "AS",
    capital_name: "Amman",
    currency_code: "JOD",
    phone_code: "962",
    three_letter_country_code: "JOR",
  },
  {
    continent_name: "Asia",
    country_code: "JP",
    country_name: "Japan",
    continent_code: "AS",
    capital_name: "Tokyo",
    currency_code: "JPY",
    phone_code: "81",
    three_letter_country_code: "JPN",
  },
  {
    continent_name: "Africa",
    country_code: "KE",
    country_name: "Kenya",
    continent_code: "AF",
    capital_name: "Nairobi",
    currency_code: "KES",
    phone_code: "254",
    three_letter_country_code: "KEN",
  },
  {
    continent_name: "Asia",
    country_code: "KG",
    country_name: "Kyrgyzstan",
    continent_code: "AS",
    capital_name: "Bishkek",
    currency_code: "KGS",
    phone_code: "996",
    three_letter_country_code: "KGZ",
  },
  {
    continent_name: "Asia",
    country_code: "KH",
    country_name: "Cambodia",
    continent_code: "AS",
    capital_name: "Phnom Penh",
    currency_code: "KHR",
    phone_code: "855",
    three_letter_country_code: "KHM",
  },
  {
    continent_name: "Oceania",
    country_code: "KI",
    country_name: "Kiribati",
    continent_code: "OC",
    capital_name: "Tarawa",
    currency_code: "AUD",
    phone_code: "686",
    three_letter_country_code: "KIR",
  },
  {
    continent_name: "Africa",
    country_code: "KM",
    country_name: "Comoros",
    continent_code: "AF",
    capital_name: "Moroni",
    currency_code: "KMF",
    phone_code: "269",
    three_letter_country_code: "COM",
  },
  {
    continent_name: "North America",
    country_code: "KN",
    country_name: "Saint Kitts and Nevis",
    continent_code: "NA",
    capital_name: "Basseterre",
    currency_code: "XCD",
    phone_code: "+1-869",
    three_letter_country_code: "KNA",
  },
  {
    continent_name: "Asia",
    country_code: "KP",
    country_name: "North Korea",
    continent_code: "AS",
    capital_name: "Pyongyang",
    currency_code: "KPW",
    phone_code: "850",
    three_letter_country_code: "PRK",
  },
  {
    continent_name: "Asia",
    country_code: "KR",
    country_name: "South Korea",
    continent_code: "AS",
    capital_name: "Seoul",
    currency_code: "KRW",
    phone_code: "82",
    three_letter_country_code: "KOR",
  },
  {
    continent_name: "Asia",
    country_code: "KW",
    country_name: "Kuwait",
    continent_code: "AS",
    capital_name: "Kuwait City",
    currency_code: "KWD",
    phone_code: "965",
    three_letter_country_code: "KWT",
  },
  {
    continent_name: "North America",
    country_code: "KY",
    country_name: "Cayman Islands",
    continent_code: "NA",
    capital_name: "George Town",
    currency_code: "KYD",
    phone_code: "+1-345",
    three_letter_country_code: "CYM",
  },
  {
    continent_name: "Asia",
    country_code: "KZ",
    country_name: "Kazakhstan",
    continent_code: "AS",
    capital_name: "Astana",
    currency_code: "KZT",
    phone_code: "7",
    three_letter_country_code: "KAZ",
  },
  {
    continent_name: "Asia",
    country_code: "LA",
    country_name: "Laos",
    continent_code: "AS",
    capital_name: "Vientiane",
    currency_code: "LAK",
    phone_code: "856",
    three_letter_country_code: "LAO",
  },
  {
    continent_name: "Asia",
    country_code: "LB",
    country_name: "Lebanon",
    continent_code: "AS",
    capital_name: "Beirut",
    currency_code: "LBP",
    phone_code: "961",
    three_letter_country_code: "LBN",
  },
  {
    continent_name: "North America",
    country_code: "LC",
    country_name: "Saint Lucia",
    continent_code: "NA",
    capital_name: "Castries",
    currency_code: "XCD",
    phone_code: "+1-758",
    three_letter_country_code: "LCA",
  },
  {
    continent_name: "Europe",
    country_code: "LI",
    country_name: "Liechtenstein",
    continent_code: "EU",
    capital_name: "Vaduz",
    currency_code: "CHF",
    phone_code: "423",
    three_letter_country_code: "LIE",
  },
  {
    continent_name: "Asia",
    country_code: "LK",
    country_name: "Sri Lanka",
    continent_code: "AS",
    capital_name: "Colombo",
    currency_code: "LKR",
    phone_code: "94",
    three_letter_country_code: "LKA",
  },
  {
    continent_name: "Africa",
    country_code: "LR",
    country_name: "Liberia",
    continent_code: "AF",
    capital_name: "Monrovia",
    currency_code: "LRD",
    phone_code: "231",
    three_letter_country_code: "LBR",
  },
  {
    continent_name: "Africa",
    country_code: "LS",
    country_name: "Lesotho",
    continent_code: "AF",
    capital_name: "Maseru",
    currency_code: "LSL",
    phone_code: "266",
    three_letter_country_code: "LSO",
  },
  {
    continent_name: "Europe",
    country_code: "LT",
    country_name: "Lithuania",
    continent_code: "EU",
    capital_name: "Vilnius",
    currency_code: "LTL",
    phone_code: "370",
    three_letter_country_code: "LTU",
  },
  {
    continent_name: "Europe",
    country_code: "LU",
    country_name: "Luxembourg",
    continent_code: "EU",
    capital_name: "Luxembourg",
    currency_code: "EUR",
    phone_code: "352",
    three_letter_country_code: "LUX",
  },
  {
    continent_name: "Europe",
    country_code: "LV",
    country_name: "Latvia",
    continent_code: "EU",
    capital_name: "Riga",
    currency_code: "EUR",
    phone_code: "371",
    three_letter_country_code: "LVA",
  },
  {
    continent_name: "Africa",
    country_code: "LY",
    country_name: "Libya",
    continent_code: "AF",
    capital_name: "Tripolis",
    currency_code: "LYD",
    phone_code: "218",
    three_letter_country_code: "LBY",
  },
  {
    continent_name: "Africa",
    country_code: "MA",
    country_name: "Morocco",
    continent_code: "AF",
    capital_name: "Rabat",
    currency_code: "MAD",
    phone_code: "212",
    three_letter_country_code: "MAR",
  },
  {
    continent_name: "Europe",
    country_code: "MC",
    country_name: "Monaco",
    continent_code: "EU",
    capital_name: "Monaco",
    currency_code: "EUR",
    phone_code: "377",
    three_letter_country_code: "MCO",
  },
  {
    continent_name: "Europe",
    country_code: "MD",
    country_name: "Moldova",
    continent_code: "EU",
    capital_name: "Chisinau",
    currency_code: "MDL",
    phone_code: "373",
    three_letter_country_code: "MDA",
  },
  {
    continent_name: "Europe",
    country_code: "ME",
    country_name: "Montenegro",
    continent_code: "EU",
    capital_name: "Podgorica",
    currency_code: "EUR",
    phone_code: "382",
    three_letter_country_code: "MNE",
  },
  {
    continent_name: "North America",
    country_code: "MF",
    country_name: "Saint Martin",
    continent_code: "NA",
    capital_name: "Marigot",
    currency_code: "EUR",
    phone_code: "590",
    three_letter_country_code: "MAF",
  },
  {
    continent_name: "Africa",
    country_code: "MG",
    country_name: "Madagascar",
    continent_code: "AF",
    capital_name: "Antananarivo",
    currency_code: "MGA",
    phone_code: "261",
    three_letter_country_code: "MDG",
  },
  {
    continent_name: "Oceania",
    country_code: "MH",
    country_name: "Marshall Islands",
    continent_code: "OC",
    capital_name: "Majuro",
    currency_code: "USD",
    phone_code: "692",
    three_letter_country_code: "MHL",
  },
  {
    continent_name: "Europe",
    country_code: "MK",
    country_name: "Macedonia",
    continent_code: "EU",
    capital_name: "Skopje",
    currency_code: "MKD",
    phone_code: "389",
    three_letter_country_code: "MKD",
  },
  {
    continent_name: "Africa",
    country_code: "ML",
    country_name: "Mali",
    continent_code: "AF",
    capital_name: "Bamako",
    currency_code: "XOF",
    phone_code: "223",
    three_letter_country_code: "MLI",
  },
  {
    continent_name: "Asia",
    country_code: "MM",
    country_name: "Myanmar",
    continent_code: "AS",
    capital_name: "Nay Pyi Taw",
    currency_code: "MMK",
    phone_code: "95",
    three_letter_country_code: "MMR",
  },
  {
    continent_name: "Asia",
    country_code: "MN",
    country_name: "Mongolia",
    continent_code: "AS",
    capital_name: "Ulan Bator",
    currency_code: "MNT",
    phone_code: "976",
    three_letter_country_code: "MNG",
  },
  {
    continent_name: "Asia",
    country_code: "MO",
    country_name: "Macao",
    continent_code: "AS",
    capital_name: "Macao",
    currency_code: "MOP",
    phone_code: "853",
    three_letter_country_code: "MAC",
  },
  {
    continent_name: "Oceania",
    country_code: "MP",
    country_name: "Northern Mariana Islands",
    continent_code: "OC",
    capital_name: "Saipan",
    currency_code: "USD",
    phone_code: "+1-670",
    three_letter_country_code: "MNP",
  },
  {
    continent_name: "North America",
    country_code: "MQ",
    country_name: "Martinique",
    continent_code: "NA",
    capital_name: "Fort-de-France",
    currency_code: "EUR",
    phone_code: "596",
    three_letter_country_code: "MTQ",
  },
  {
    continent_name: "Africa",
    country_code: "MR",
    country_name: "Mauritania",
    continent_code: "AF",
    capital_name: "Nouakchott",
    currency_code: "MRO",
    phone_code: "222",
    three_letter_country_code: "MRT",
  },
  {
    continent_name: "North America",
    country_code: "MS",
    country_name: "Montserrat",
    continent_code: "NA",
    capital_name: "Plymouth",
    currency_code: "XCD",
    phone_code: "+1-664",
    three_letter_country_code: "MSR",
  },
  {
    continent_name: "Europe",
    country_code: "MT",
    country_name: "Malta",
    continent_code: "EU",
    capital_name: "Valletta",
    currency_code: "EUR",
    phone_code: "356",
    three_letter_country_code: "MLT",
  },
  {
    continent_name: "Africa",
    country_code: "MU",
    country_name: "Mauritius",
    continent_code: "AF",
    capital_name: "Port Louis",
    currency_code: "MUR",
    phone_code: "230",
    three_letter_country_code: "MUS",
  },
  {
    continent_name: "Asia",
    country_code: "MV",
    country_name: "Maldives",
    continent_code: "AS",
    capital_name: "Male",
    currency_code: "MVR",
    phone_code: "960",
    three_letter_country_code: "MDV",
  },
  {
    continent_name: "Africa",
    country_code: "MW",
    country_name: "Malawi",
    continent_code: "AF",
    capital_name: "Lilongwe",
    currency_code: "MWK",
    phone_code: "265",
    three_letter_country_code: "MWI",
  },
  {
    continent_name: "North America",
    country_code: "MX",
    country_name: "Mexico",
    continent_code: "NA",
    capital_name: "Mexico City",
    currency_code: "MXN",
    phone_code: "52",
    three_letter_country_code: "MEX",
  },
  {
    continent_name: "Asia",
    country_code: "MY",
    country_name: "Malaysia",
    continent_code: "AS",
    capital_name: "Kuala Lumpur",
    currency_code: "MYR",
    phone_code: "60",
    three_letter_country_code: "MYS",
  },
  {
    continent_name: "Africa",
    country_code: "MZ",
    country_name: "Mozambique",
    continent_code: "AF",
    capital_name: "Maputo",
    currency_code: "MZN",
    phone_code: "258",
    three_letter_country_code: "MOZ",
  },
  {
    continent_name: "Africa",
    country_code: "NA",
    country_name: "Namibia",
    continent_code: "AF",
    capital_name: "Windhoek",
    currency_code: "NAD",
    phone_code: "264",
    three_letter_country_code: "NAM",
  },
  {
    continent_name: "Oceania",
    country_code: "NC",
    country_name: "New Caledonia",
    continent_code: "OC",
    capital_name: "Noumea",
    currency_code: "XPF",
    phone_code: "687",
    three_letter_country_code: "NCL",
  },
  {
    continent_name: "Africa",
    country_code: "NE",
    country_name: "Niger",
    continent_code: "AF",
    capital_name: "Niamey",
    currency_code: "XOF",
    phone_code: "227",
    three_letter_country_code: "NER",
  },
  {
    continent_name: "Oceania",
    country_code: "NF",
    country_name: "Norfolk Island",
    continent_code: "OC",
    capital_name: "Kingston",
    currency_code: "AUD",
    phone_code: "672",
    three_letter_country_code: "NFK",
  },
  {
    continent_name: "Africa",
    country_code: "NG",
    country_name: "Nigeria",
    continent_code: "AF",
    capital_name: "Abuja",
    currency_code: "NGN",
    phone_code: "234",
    three_letter_country_code: "NGA",
  },
  {
    continent_name: "North America",
    country_code: "NI",
    country_name: "Nicaragua",
    continent_code: "NA",
    capital_name: "Managua",
    currency_code: "NIO",
    phone_code: "505",
    three_letter_country_code: "NIC",
  },
  {
    continent_name: "Europe",
    country_code: "NL",
    country_name: "Netherlands",
    continent_code: "EU",
    capital_name: "Amsterdam",
    currency_code: "EUR",
    phone_code: "31",
    three_letter_country_code: "NLD",
  },
  {
    continent_name: "Europe",
    country_code: "NO",
    country_name: "Norway",
    continent_code: "EU",
    capital_name: "Oslo",
    currency_code: "NOK",
    phone_code: "47",
    three_letter_country_code: "NOR",
  },
  {
    continent_name: "Asia",
    country_code: "NP",
    country_name: "Nepal",
    continent_code: "AS",
    capital_name: "Kathmandu",
    currency_code: "NPR",
    phone_code: "977",
    three_letter_country_code: "NPL",
  },
  {
    continent_name: "Oceania",
    country_code: "NR",
    country_name: "Nauru",
    continent_code: "OC",
    capital_name: "Yaren",
    currency_code: "AUD",
    phone_code: "674",
    three_letter_country_code: "NRU",
  },
  {
    continent_name: "Oceania",
    country_code: "NU",
    country_name: "Niue",
    continent_code: "OC",
    capital_name: "Alofi",
    currency_code: "NZD",
    phone_code: "683",
    three_letter_country_code: "NIU",
  },
  {
    continent_name: "Oceania",
    country_code: "NZ",
    country_name: "New Zealand",
    continent_code: "OC",
    capital_name: "Wellington",
    currency_code: "NZD",
    phone_code: "64",
    three_letter_country_code: "NZL",
  },
  {
    continent_name: "Asia",
    country_code: "OM",
    country_name: "Oman",
    continent_code: "AS",
    capital_name: "Muscat",
    currency_code: "OMR",
    phone_code: "968",
    three_letter_country_code: "OMN",
  },
  {
    continent_name: "North America",
    country_code: "PA",
    country_name: "Panama",
    continent_code: "NA",
    capital_name: "Panama City",
    currency_code: "PAB",
    phone_code: "507",
    three_letter_country_code: "PAN",
  },
  {
    continent_name: "South America",
    country_code: "PE",
    country_name: "Peru",
    continent_code: "SA",
    capital_name: "Lima",
    currency_code: "PEN",
    phone_code: "51",
    three_letter_country_code: "PER",
  },
  {
    continent_name: "Oceania",
    country_code: "PF",
    country_name: "French Polynesia",
    continent_code: "OC",
    capital_name: "Papeete",
    currency_code: "XPF",
    phone_code: "689",
    three_letter_country_code: "PYF",
  },
  {
    continent_name: "Oceania",
    country_code: "PG",
    country_name: "Papua New Guinea",
    continent_code: "OC",
    capital_name: "Port Moresby",
    currency_code: "PGK",
    phone_code: "675",
    three_letter_country_code: "PNG",
  },
  {
    continent_name: "Asia",
    country_code: "PH",
    country_name: "Philippines",
    continent_code: "AS",
    capital_name: "Manila",
    currency_code: "PHP",
    phone_code: "63",
    three_letter_country_code: "PHL",
  },
  {
    continent_name: "Asia",
    country_code: "PK",
    country_name: "Pakistan",
    continent_code: "AS",
    capital_name: "Islamabad",
    currency_code: "PKR",
    phone_code: "92",
    three_letter_country_code: "PAK",
  },
  {
    continent_name: "Europe",
    country_code: "PL",
    country_name: "Poland",
    continent_code: "EU",
    capital_name: "Warsaw",
    currency_code: "PLN",
    phone_code: "48",
    three_letter_country_code: "POL",
  },
  {
    continent_name: "North America",
    country_code: "PM",
    country_name: "Saint Pierre and Miquelon",
    continent_code: "NA",
    capital_name: "Saint-Pierre",
    currency_code: "EUR",
    phone_code: "508",
    three_letter_country_code: "SPM",
  },
  {
    continent_name: "Oceania",
    country_code: "PN",
    country_name: "Pitcairn",
    continent_code: "OC",
    capital_name: "Adamstown",
    currency_code: "NZD",
    phone_code: "870",
    three_letter_country_code: "PCN",
  },
  {
    continent_name: "North America",
    country_code: "PR",
    country_name: "Puerto Rico",
    continent_code: "NA",
    capital_name: "San Juan",
    currency_code: "USD",
    phone_code: "+1-787 and 1-939",
    three_letter_country_code: "PRI",
  },
  {
    continent_name: "Asia",
    country_code: "PS",
    country_name: "Palestinian Territory",
    continent_code: "AS",
    capital_name: "East Jerusalem",
    currency_code: "ILS",
    phone_code: "970",
    three_letter_country_code: "PSE",
  },
  {
    continent_name: "Europe",
    country_code: "PT",
    country_name: "Portugal",
    continent_code: "EU",
    capital_name: "Lisbon",
    currency_code: "EUR",
    phone_code: "351",
    three_letter_country_code: "PRT",
  },
  {
    continent_name: "Oceania",
    country_code: "PW",
    country_name: "Palau",
    continent_code: "OC",
    capital_name: "Melekeok",
    currency_code: "USD",
    phone_code: "680",
    three_letter_country_code: "PLW",
  },
  {
    continent_name: "South America",
    country_code: "PY",
    country_name: "Paraguay",
    continent_code: "SA",
    capital_name: "Asuncion",
    currency_code: "PYG",
    phone_code: "595",
    three_letter_country_code: "PRY",
  },
  {
    continent_name: "Asia",
    country_code: "QA",
    country_name: "Qatar",
    continent_code: "AS",
    capital_name: "Doha",
    currency_code: "QAR",
    phone_code: "974",
    three_letter_country_code: "QAT",
  },
  {
    continent_name: "Africa",
    country_code: "RE",
    country_name: "Reunion",
    continent_code: "AF",
    capital_name: "Saint-Denis",
    currency_code: "EUR",
    phone_code: "262",
    three_letter_country_code: "REU",
  },
  {
    continent_name: "Europe",
    country_code: "RO",
    country_name: "Romania",
    continent_code: "EU",
    capital_name: "Bucharest",
    currency_code: "RON",
    phone_code: "40",
    three_letter_country_code: "ROU",
  },
  {
    continent_name: "Europe",
    country_code: "RS",
    country_name: "Serbia",
    continent_code: "EU",
    capital_name: "Belgrade",
    currency_code: "RSD",
    phone_code: "381",
    three_letter_country_code: "SRB",
  },
  {
    continent_name: "Europe",
    country_code: "RU",
    country_name: "Russia",
    continent_code: "EU",
    capital_name: "Moscow",
    currency_code: "RUB",
    phone_code: "7",
    three_letter_country_code: "RUS",
  },
  {
    continent_name: "Africa",
    country_code: "RW",
    country_name: "Rwanda",
    continent_code: "AF",
    capital_name: "Kigali",
    currency_code: "RWF",
    phone_code: "250",
    three_letter_country_code: "RWA",
  },
  {
    continent_name: "Asia",
    country_code: "SA",
    country_name: "Saudi Arabia",
    continent_code: "AS",
    capital_name: "Riyadh",
    currency_code: "SAR",
    phone_code: "966",
    three_letter_country_code: "SAU",
  },
  {
    continent_name: "Oceania",
    country_code: "SB",
    country_name: "Solomon Islands",
    continent_code: "OC",
    capital_name: "Honiara",
    currency_code: "SBD",
    phone_code: "677",
    three_letter_country_code: "SLB",
  },
  {
    continent_name: "Africa",
    country_code: "SC",
    country_name: "Seychelles",
    continent_code: "AF",
    capital_name: "Victoria",
    currency_code: "SCR",
    phone_code: "248",
    three_letter_country_code: "SYC",
  },
  {
    continent_name: "Africa",
    country_code: "SD",
    country_name: "Sudan",
    continent_code: "AF",
    capital_name: "Khartoum",
    currency_code: "SDG",
    phone_code: "249",
    three_letter_country_code: "SDN",
  },
  {
    continent_name: "Europe",
    country_code: "SE",
    country_name: "Sweden",
    continent_code: "EU",
    capital_name: "Stockholm",
    currency_code: "SEK",
    phone_code: "46",
    three_letter_country_code: "SWE",
  },
  {
    continent_name: "Asia",
    country_code: "SG",
    country_name: "Singapore",
    continent_code: "AS",
    capital_name: "Singapur",
    currency_code: "SGD",
    phone_code: "65",
    three_letter_country_code: "SGP",
  },
  {
    continent_name: "Africa",
    country_code: "SH",
    country_name: "Saint Helena",
    continent_code: "AF",
    capital_name: "Jamestown",
    currency_code: "SHP",
    phone_code: "290",
    three_letter_country_code: "SHN",
  },
  {
    continent_name: "Europe",
    country_code: "SI",
    country_name: "Slovenia",
    continent_code: "EU",
    capital_name: "Ljubljana",
    currency_code: "EUR",
    phone_code: "386",
    three_letter_country_code: "SVN",
  },
  {
    continent_name: "Europe",
    country_code: "SJ",
    country_name: "Svalbard and Jan Mayen",
    continent_code: "EU",
    capital_name: "Longyearbyen",
    currency_code: "NOK",
    phone_code: "47",
    three_letter_country_code: "SJM",
  },
  {
    continent_name: "Europe",
    country_code: "SK",
    country_name: "Slovakia",
    continent_code: "EU",
    capital_name: "Bratislava",
    currency_code: "EUR",
    phone_code: "421",
    three_letter_country_code: "SVK",
  },
  {
    continent_name: "Africa",
    country_code: "SL",
    country_name: "Sierra Leone",
    continent_code: "AF",
    capital_name: "Freetown",
    currency_code: "SLL",
    phone_code: "232",
    three_letter_country_code: "SLE",
  },
  {
    continent_name: "Europe",
    country_code: "SM",
    country_name: "San Marino",
    continent_code: "EU",
    capital_name: "San Marino",
    currency_code: "EUR",
    phone_code: "378",
    three_letter_country_code: "SMR",
  },
  {
    continent_name: "Africa",
    country_code: "SN",
    country_name: "Senegal",
    continent_code: "AF",
    capital_name: "Dakar",
    currency_code: "XOF",
    phone_code: "221",
    three_letter_country_code: "SEN",
  },
  {
    continent_name: "Africa",
    country_code: "SO",
    country_name: "Somalia",
    continent_code: "AF",
    capital_name: "Mogadishu",
    currency_code: "SOS",
    phone_code: "252",
    three_letter_country_code: "SOM",
  },
  {
    continent_name: "South America",
    country_code: "SR",
    country_name: "Suriname",
    continent_code: "SA",
    capital_name: "Paramaribo",
    currency_code: "SRD",
    phone_code: "597",
    three_letter_country_code: "SUR",
  },
  {
    continent_name: "Africa",
    country_code: "SS",
    country_name: "South Sudan",
    continent_code: "AF",
    capital_name: "Juba",
    currency_code: "SSP",
    phone_code: "211",
    three_letter_country_code: "SSD",
  },
  {
    continent_name: "Africa",
    country_code: "ST",
    country_name: "Sao Tome and Principe",
    continent_code: "AF",
    capital_name: "Sao Tome",
    currency_code: "STD",
    phone_code: "239",
    three_letter_country_code: "STP",
  },
  {
    continent_name: "North America",
    country_code: "SV",
    country_name: "El Salvador",
    continent_code: "NA",
    capital_name: "San Salvador",
    currency_code: "USD",
    phone_code: "503",
    three_letter_country_code: "SLV",
  },
  {
    continent_name: "North America",
    country_code: "SX",
    country_name: "Sint Maarten",
    continent_code: "NA",
    capital_name: "Philipsburg",
    currency_code: "ANG",
    phone_code: "599",
    three_letter_country_code: "SXM",
  },
  {
    continent_name: "Asia",
    country_code: "SY",
    country_name: "Syria",
    continent_code: "AS",
    capital_name: "Damascus",
    currency_code: "SYP",
    phone_code: "963",
    three_letter_country_code: "SYR",
  },
  {
    continent_name: "Africa",
    country_code: "SZ",
    country_name: "Swaziland",
    continent_code: "AF",
    capital_name: "Mbabane",
    currency_code: "SZL",
    phone_code: "268",
    three_letter_country_code: "SWZ",
  },
  {
    continent_name: "North America",
    country_code: "TC",
    country_name: "Turks and Caicos Islands",
    continent_code: "NA",
    capital_name: "Cockburn Town",
    currency_code: "USD",
    phone_code: "+1-649",
    three_letter_country_code: "TCA",
  },
  {
    continent_name: "Africa",
    country_code: "TD",
    country_name: "Chad",
    continent_code: "AF",
    capital_name: "N'Djamena",
    currency_code: "XAF",
    phone_code: "235",
    three_letter_country_code: "TCD",
  },
  {
    continent_name: "Antarctica",
    country_code: "TF",
    country_name: "French Southern Territories",
    continent_code: "AN",
    capital_name: "Port-aux-Francais",
    currency_code: "EUR",
    phone_code: "",
    three_letter_country_code: "ATF",
  },
  {
    continent_name: "Africa",
    country_code: "TG",
    country_name: "Togo",
    continent_code: "AF",
    capital_name: "Lome",
    currency_code: "XOF",
    phone_code: "228",
    three_letter_country_code: "TGO",
  },
  {
    continent_name: "Asia",
    country_code: "TH",
    country_name: "Thailand",
    continent_code: "AS",
    capital_name: "Bangkok",
    currency_code: "THB",
    phone_code: "66",
    three_letter_country_code: "THA",
  },
  {
    continent_name: "Asia",
    country_code: "TJ",
    country_name: "Tajikistan",
    continent_code: "AS",
    capital_name: "Dushanbe",
    currency_code: "TJS",
    phone_code: "992",
    three_letter_country_code: "TJK",
  },
  {
    continent_name: "Oceania",
    country_code: "TK",
    country_name: "Tokelau",
    continent_code: "OC",
    capital_name: "",
    currency_code: "NZD",
    phone_code: "690",
    three_letter_country_code: "TKL",
  },
  {
    continent_name: "Oceania",
    country_code: "TL",
    country_name: "East Timor",
    continent_code: "OC",
    capital_name: "Dili",
    currency_code: "USD",
    phone_code: "670",
    three_letter_country_code: "TLS",
  },
  {
    continent_name: "Asia",
    country_code: "TM",
    country_name: "Turkmenistan",
    continent_code: "AS",
    capital_name: "Ashgabat",
    currency_code: "TMT",
    phone_code: "993",
    three_letter_country_code: "TKM",
  },
  {
    continent_name: "Africa",
    country_code: "TN",
    country_name: "Tunisia",
    continent_code: "AF",
    capital_name: "Tunis",
    currency_code: "TND",
    phone_code: "216",
    three_letter_country_code: "TUN",
  },
  {
    continent_name: "Oceania",
    country_code: "TO",
    country_name: "Tonga",
    continent_code: "OC",
    capital_name: "Nuku'alofa",
    currency_code: "TOP",
    phone_code: "676",
    three_letter_country_code: "TON",
  },
  {
    continent_name: "Asia",
    country_code: "TR",
    country_name: "Turkey",
    continent_code: "AS",
    capital_name: "Ankara",
    currency_code: "TRY",
    phone_code: "90",
    three_letter_country_code: "TUR",
  },
  {
    continent_name: "North America",
    country_code: "TT",
    country_name: "Trinidad and Tobago",
    continent_code: "NA",
    capital_name: "Port of Spain",
    currency_code: "TTD",
    phone_code: "+1-868",
    three_letter_country_code: "TTO",
  },
  {
    continent_name: "Oceania",
    country_code: "TV",
    country_name: "Tuvalu",
    continent_code: "OC",
    capital_name: "Funafuti",
    currency_code: "AUD",
    phone_code: "688",
    three_letter_country_code: "TUV",
  },
  {
    continent_name: "Asia",
    country_code: "TW",
    country_name: "Taiwan",
    continent_code: "AS",
    capital_name: "Taipei",
    currency_code: "TWD",
    phone_code: "886",
    three_letter_country_code: "TWN",
  },
  {
    continent_name: "Africa",
    country_code: "TZ",
    country_name: "Tanzania",
    continent_code: "AF",
    capital_name: "Dodoma",
    currency_code: "TZS",
    phone_code: "255",
    three_letter_country_code: "TZA",
  },
  {
    continent_name: "Europe",
    country_code: "UA",
    country_name: "Ukraine",
    continent_code: "EU",
    capital_name: "Kiev",
    currency_code: "UAH",
    phone_code: "380",
    three_letter_country_code: "UKR",
  },
  {
    continent_name: "Africa",
    country_code: "UG",
    country_name: "Uganda",
    continent_code: "AF",
    capital_name: "Kampala",
    currency_code: "UGX",
    phone_code: "256",
    three_letter_country_code: "UGA",
  },
  {
    continent_name: "Oceania",
    country_code: "UM",
    country_name: "United States Minor Outlying Islands",
    continent_code: "OC",
    capital_name: "",
    currency_code: "USD",
    phone_code: "1",
    three_letter_country_code: "UMI",
  },
  {
    continent_name: "North America",
    country_code: "US",
    country_name: "United States",
    continent_code: "NA",
    capital_name: "Washington",
    currency_code: "USD",
    phone_code: "1",
    three_letter_country_code: "USA",
  },
  {
    continent_name: "South America",
    country_code: "UY",
    country_name: "Uruguay",
    continent_code: "SA",
    capital_name: "Montevideo",
    currency_code: "UYU",
    phone_code: "598",
    three_letter_country_code: "URY",
  },
  {
    continent_name: "Asia",
    country_code: "UZ",
    country_name: "Uzbekistan",
    continent_code: "AS",
    capital_name: "Tashkent",
    currency_code: "UZS",
    phone_code: "998",
    three_letter_country_code: "UZB",
  },
  {
    continent_name: "Europe",
    country_code: "VA",
    country_name: "Vatican",
    continent_code: "EU",
    capital_name: "Vatican City",
    currency_code: "EUR",
    phone_code: "379",
    three_letter_country_code: "VAT",
  },
  {
    continent_name: "North America",
    country_code: "VC",
    country_name: "Saint Vincent and the Grenadines",
    continent_code: "NA",
    capital_name: "Kingstown",
    currency_code: "XCD",
    phone_code: "+1-784",
    three_letter_country_code: "VCT",
  },
  {
    continent_name: "South America",
    country_code: "VE",
    country_name: "Venezuela",
    continent_code: "SA",
    capital_name: "Caracas",
    currency_code: "VEF",
    phone_code: "58",
    three_letter_country_code: "VEN",
  },
  {
    continent_name: "North America",
    country_code: "VG",
    country_name: "British Virgin Islands",
    continent_code: "NA",
    capital_name: "Road Town",
    currency_code: "USD",
    phone_code: "+1-284",
    three_letter_country_code: "VGB",
  },
  {
    continent_name: "North America",
    country_code: "VI",
    country_name: "U.S. Virgin Islands",
    continent_code: "NA",
    capital_name: "Charlotte Amalie",
    currency_code: "USD",
    phone_code: "+1-340",
    three_letter_country_code: "VIR",
  },
  {
    continent_name: "Asia",
    country_code: "VN",
    country_name: "Vietnam",
    continent_code: "AS",
    capital_name: "Hanoi",
    currency_code: "VND",
    phone_code: "84",
    three_letter_country_code: "VNM",
  },
  {
    continent_name: "Oceania",
    country_code: "VU",
    country_name: "Vanuatu",
    continent_code: "OC",
    capital_name: "Port Vila",
    currency_code: "VUV",
    phone_code: "678",
    three_letter_country_code: "VUT",
  },
  {
    continent_name: "Oceania",
    country_code: "WF",
    country_name: "Wallis and Futuna",
    continent_code: "OC",
    capital_name: "Mata Utu",
    currency_code: "XPF",
    phone_code: "681",
    three_letter_country_code: "WLF",
  },
  {
    continent_name: "Oceania",
    country_code: "WS",
    country_name: "Samoa",
    continent_code: "OC",
    capital_name: "Apia",
    currency_code: "WST",
    phone_code: "685",
    three_letter_country_code: "WSM",
  },
  {
    country_code: "XK",
    country_name: "Kosovo",
    continent_code: "EU",
    capital_name: "Pristina",
    currency_code: "EUR",
    phone_code: "",
  },
  {
    continent_name: "Asia",
    country_code: "YE",
    country_name: "Yemen",
    continent_code: "AS",
    capital_name: "Sanaa",
    currency_code: "YER",
    phone_code: "967",
    three_letter_country_code: "YEM",
  },
  {
    continent_name: "Africa",
    country_code: "YT",
    country_name: "Mayotte",
    continent_code: "AF",
    capital_name: "Mamoudzou",
    currency_code: "EUR",
    phone_code: "262",
    three_letter_country_code: "MYT",
  },
  {
    continent_name: "Africa",
    country_code: "ZA",
    country_name: "South Africa",
    continent_code: "AF",
    capital_name: "Pretoria",
    currency_code: "ZAR",
    phone_code: "27",
    three_letter_country_code: "ZAF",
  },
  {
    continent_name: "Africa",
    country_code: "ZM",
    country_name: "Zambia",
    continent_code: "AF",
    capital_name: "Lusaka",
    currency_code: "ZMK",
    phone_code: "260",
    three_letter_country_code: "ZMB",
  },
  {
    continent_name: "Africa",
    country_code: "ZW",
    country_name: "Zimbabwe",
    continent_code: "AF",
    capital_name: "Harare",
    currency_code: "ZWL",
    phone_code: "263",
    three_letter_country_code: "ZWE",
  },
];
