export const DASHBOARD_FILTER_INPROGRESS_CERTIFICATIONS =
  "DASHBOARD_FILTER_INPROGRESS_CERTIFICATIONS";
export const DASHBOARD_FILTER_COMPLETED_CERTIFICATIONS =
  "DASHBOARD_FILTER_COMPLETED_CERTIFICATIONS";
export const DASHBOARD_FILTER_INREVIEW_CERTIFICATIONS =
  "DASHBOARD_FILTER_INREVIEW_CERTIFICATIONS";

export const DASHBOARD_UPLOAD_SOLUTION_SELCTION =
  "DASHBOARD_UPLOAD_SOLUTION_SELCTION";

export const DASHBOARD_UPLOAD_SOLUTION_RESET =
  "DASHBOARD_UPLOAD_SOLUTION_RESET";

export const DASHBOARD_UPLOAD_SOLUTION_GITHUB_REQUEST =
  "DASHBOARD_UPLOAD_SOLUTION_GITHUB_REQUEST";
export const DASHBOARD_UPLOAD_SOLUTION_GITHUB_SUCCESS =
  "DASHBOARD_UPLOAD_SOLUTION_GITHUB_SUCCESS";
export const DASHBOARD_UPLOAD_SOLUTION_GITHUB_FAIL =
  "DASHBOARD_UPLOAD_SOLUTION_GITHUB_FAIL";
export const DASHBOARD_UPLOAD_SOLUTION_GITHUB_RESET =
  "DASHBOARD_UPLOAD_SOLUTION_GITHUB_RESET";

export const DASHBOARD_GET_ALL_SUBMISSIONS_LOADING =
  "DASHBOARD_GET_ALL_SUBMISSIONS_LOADING";
export const DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS =
  "DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS";
export const DASHBOARD_GET_ALL_SUBMISSIONS_FAILED =
  "DASHBOARD_GET_ALL_SUBMISSIONS_FAILED";
