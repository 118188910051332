import { Button } from "@mui/material";
import React, { useState } from "react";
import StarRateIcon from "@mui/icons-material/StarRate";
import { motion } from "framer-motion";
import { ImageConstants } from "../../../utils/constants";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getCertificateAction } from "../../../actions/Education/certificateActions";
import { redirectToPageAction } from "../../../actions/userActions";
import { REDIRECT_CERTIFICATE_PAGE } from "../../../constants/Education/certificateConstants";

const data = [
  {
    technology: "frontend",
    id: 1,
    title: "🌟 Grab FrontEnd Internship Certificate 🌟",
    certificateImage: ImageConstants.getBasicCertificateImage,
    tag: "basic",
    listOfPoints: [
      "Looking for Quick internship certificate? Work for us.",
      "Create Basic UI using HTML, CSS for our clients.",
      "More than 8+ LPA Salary for FrontEnd Engineers.",
      "Quick and Effective Certification for you resume.",
    ],
  },
  {
    technology: "frontend",
    id: 2,
    title: "🌟 Join our Internship Programme 🌟",
    certificateImage: ImageConstants.getIntermediateCertificateImage,
    tag: "intermediate",
    listOfPoints: [
      "75% software industry is looking for FrontEnd Developers with Internship Experience.",
      "Increase your chances 45% of getting into MNCs.",
      "Solve Challenges Faced by our Clients and gain your Internship Certificate.",
      "Real World UI challenges to make you smart Frontend Developer.",
    ],
  },
  {
    technology: "frontend",
    id: 3,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "advanced",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "backend",
    id: 4,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "basic",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "backend",
    id: 5,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "intermediate",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "backend",
    id: 6,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "advanced",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "fullstack",
    id: 7,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "basic",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "fullstack",
    id: 8,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "intermediate",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "fullstack",
    id: 9,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "advanced",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
];
function CertificateMotionSection({
  selectedLevel,
  setSelectedLevel,
  currentCourseLevel,
}) {
  const dispatch = useDispatch();
  const [currSelected, setCurrSelected] = useState("basic");
  const history = useHistory();
  const handleEnrollClick = (technology, tag) => {
    history.push(`/certificates/${technology}/${tag}`);
    dispatch(getCertificateAction(technology, tag));

    dispatch(
      redirectToPageAction(
        REDIRECT_CERTIFICATE_PAGE,
        `/certificates/${technology}/${tag}`
      )
    );
  };
  const getLevelCertificates = () => {
    var title =
      currentCourseLevel === "frontend"
        ? "FrontEnd Internship"
        : currentCourseLevel === "backend"
        ? "BackEnd Internship"
        : "FullStack Internship";
    return (
      <div className="levelCertificates_main_container">
        <div style={{ marginBottom: "30px" }}>
          <h1>{title}</h1>
        </div>
        {getLevelCertificatesButton()}
        {getCertificationContainer(
          data.find((value) => {
            return (
              value.technology.toLowerCase() === currentCourseLevel &&
              value.tag === currSelected
            );
          })
        )}
      </div>
    );
  };
  const getLevelCertificatesButton = () => {
    return (
      <div className="levelCertificates_upper_button_container">
        <Button
          className={`${
            selectedLevel === `${currentCourseLevel}basic`
              ? "selected_level"
              : "button_unselected_container"
          }`}
          onClick={() => {
            setSelectedLevel(`${currentCourseLevel}basic`);
            setCurrSelected("basic");
          }}
        >
          Basic
        </Button>
        <Button
          className={`${
            selectedLevel === `${currentCourseLevel}intermediate`
              ? "selected_level"
              : "button_unselected_container"
          }`}
          onClick={() => {
            setSelectedLevel(`${currentCourseLevel}intermediate`);
            setCurrSelected("intermediate");
          }}
        >
          Intermediate
        </Button>
        <Button
          className={`${
            selectedLevel === `${currentCourseLevel}advanced`
              ? "selected_level"
              : "button_unselected_container"
          }`}
          onClick={() => {
            setSelectedLevel(`${currentCourseLevel}advanced`);
            setCurrSelected("advanced");
          }}
        >
          Avanced
        </Button>
      </div>
    );
  };
  const getCertificationRightDetails = (
    title,
    listOfPoints,
    technology,
    tag
  ) => {
    return (
      <div>
        <div className="certificates_points_details_main_container">
          <h1>{title}</h1>
          <div className="certificates_points_container">
            {listOfPoints?.map((points, index) => {
              return getStarPoints(points, index);
            })}
          </div>
          <div className="certificates_points_enroll_button">
            <Button onClick={() => handleEnrollClick(technology, tag)}>
              Enroll Now
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const getStarPoints = (title, index) => {
    return (
      <div key={index} style={{ display: "flex", alignItems: "center" }}>
        <StarRateIcon style={{ color: "#FF416C", fontSize: "18px" }} />
        <span
          style={{
            margin: "18px 10px",
            fontFamily: "sans-serif",
            fontSize: "16px",
          }}
        >
          {title}
        </span>
      </div>
    );
  };
  const getCertificationContainer = (selectedValue) => {
    return (
      <motion.div
        key={selectedLevel}
        initial={{ opacity: 0, scale: 0.5 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{
          duration: 0.7,
          delay: 0.01,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        className="getCertificationContainer_main"
      >
        <div className="certificates_image_container">
          <img src={selectedValue?.certificateImage} alt="" />
        </div>
        {getCertificationRightDetails(
          selectedValue?.title,
          selectedValue?.listOfPoints,
          selectedValue?.technology,
          selectedValue?.tag
        )}
      </motion.div>
    );
  };
  return <div>{getLevelCertificates()}</div>;
}

export default CertificateMotionSection;
