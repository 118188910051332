import { Button, CircularProgress, Drawer } from "@mui/material";
import React, { useEffect } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import YouTube from "react-youtube";
import { MoneySigns } from "../../../utils/constants";
import useCountdown from "../../../utils/countdown";
import "./WebinarEventsMobile.css";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyRegisteredEventsAction,
  registerUserForWebinarAction,
  resetRegisterUserStateAction,
} from "../../../actions/WebinarEvent/webinarEventAction";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

function WebinarDrawer({ open, setOpen, data }) {
  const dispatch = useDispatch();
  const timeRemaining = useCountdown();
  const { loading, success } = useSelector(
    (state) => state.registerUserForWebinarStore
  );
  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift" || event.key === "Escape")
    ) {
      return;
    }

    setOpen({ isOpen: !open });
  };
  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  const handleRegisterNow = () => {
    dispatch(registerUserForWebinarAction(data?._id));
  };

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        setOpen(false);
        dispatch(resetRegisterUserStateAction());
        dispatch(getMyRegisteredEventsAction());
      }
    }, 1500);
  }, [success]);

  const showPriceSection = () => {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          boxShadow: "0 -2px 4px rgba(0,0,0,.08), 0 -4px 12px rgba(0,0,0,.08)",
          width: "100%",
          backgroundColor: "#fffcf3",
          zIndex: "10",
        }}
      >
        {open.isAlreadyRegistered ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div style={{ marginRight: "10px" }}>
              <CalendarMonthIcon
                style={{ fontSize: "25px", marginTop: "5px" }}
              />
            </div>
            <div>
              <h3>
                Event Date:{" "}
                <span
                  style={{
                    color: "#FF3131",
                    fontFamily: "sans-serif",
                    fontWeight: "800",
                    fontSize: "21px",
                  }}
                >
                  {data?.eventDate}
                </span>
              </h3>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 5px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3
                  style={{
                    fontFamily: "sans-serif",
                    textDecoration: "line-through",
                    margin: "10px 0px",
                  }}
                >
                  {MoneySigns.indianRupees}
                  {data?.originalPrice}
                </h3>
                <div
                  style={{
                    margin: "0px 10px",
                    backgroundColor: "#FF3131",
                    padding: "5px 15px",
                    borderRadius: "22px",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    free
                  </span>
                </div>
              </div>

              <div>
                <p
                  style={{
                    margin: "0px",
                    color: "#888",
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  🎉Valid until:{" "}
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#222",
                      marginLeft: "5px",
                    }}
                  >
                    {timeRemaining}
                  </span>
                </p>
              </div>
            </div>
            <div style={{ margin: "0px 10px" }}>
              {success ? (
                <div>
                  <h4 style={{ color: "green" }}>Registered Successfully</h4>
                </div>
              ) : (
                <Button
                  disabled={loading}
                  onClick={() => handleRegisterNow()}
                  style={{
                    width: "140px",
                    padding: "10px",
                    backgroundColor: "#FF3131",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "inherit",
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", width: "30px", height: "30px" }}
                    />
                  ) : (
                    "Register Now"
                  )}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <Drawer
        PaperProps={{
          sx: {
            height: "100%",
            backgroundColor: "#fffcf3",
          },
        }}
        variant="persistent"
        anchor={"bottom"}
        open={open.isOpen}
      >
        <div>
          <div className="webinarDrawer_scrollableContainer">
            <div
              onClick={toggleDrawer()}
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
                padding: "5px",
              }}
            >
              <CancelIcon style={{ fontSize: "30px" }} />
            </div>

            <div
              style={{ marginTop: "10px" }}
              className="webinarDrawer_events_youtube"
            >
              {<YouTube videoId={data?.youtubeUrl} opts={opts} />}
            </div>
            <div style={{ padding: "0px 10px" }}>
              <div>
                <h3
                  style={{
                    color: "#FF3131",
                    fontSize: "22px",
                    lineHeight: "28px",
                  }}
                >
                  {data?.title}
                </h3>
              </div>
              <div>
                <p
                  style={{
                    color: "#888",
                    fontSize: "12px",
                    lineHeight: "22px",
                  }}
                >
                  {data?.subtitle}
                </p>
              </div>
              <div style={{ margin: "50px 0px" }} />
            </div>
          </div>

          <div>{showPriceSection()}</div>
        </div>
      </Drawer>
    </div>
  );
}

export default WebinarDrawer;
