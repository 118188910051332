import React, { useState } from "react";
import "./ProblemStatementScreen.css";
import StarRateIcon from "@mui/icons-material/StarRate";
import ImageOpeningComponent from "./ImageOpeningComponent";
import QuizIcon from "@mui/icons-material/Quiz";
import { useDispatch, useSelector } from "react-redux";
import { certificateProblemStatementSelectedAction } from "../../../actions/Education/certificateActions";

function ProblemStatementScreen({ details }) {
  const [questionDetails, setQuestionDetails] = useState(
    details?.problemStatement[0]
  );
  const dispatch = useDispatch();
  const { problemStatement } = useSelector(
    (state) => state.certificateProblemStatements
  );
  const handleProblemStatementClick = (val, index) => {
    dispatch(certificateProblemStatementSelectedAction(val, index));
    var currQuestion = details?.problemStatement?.find(
      (e) => e._id === val?._id
    );
    setQuestionDetails(currQuestion);
  };

  const getLeftProblemStatement = () => {
    console.log(details);
    return (
      <div className="getLeftProblemStatement_container">
        {details?.problemStatement?.map((e, index) => {
          return (
            <div
              key={index}
              onClick={() => handleProblemStatementClick(e, index + 1)}
              className={`${
                problemStatement?.selectedProblemStatement?._id === e._id
                  ? "getLeftProblemStatement_container_statement_selected"
                  : "getLeftProblemStatement_container_statement"
              }`}
              style={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
              }}
            >
              <QuizIcon style={{ marginRight: "10px" }} />
              <h1>Problem Statement {`${index + 1}`}</h1>
            </div>
          );
        })}
      </div>
    );
  };
  const mainContent = () => {
    return (
      <div className="problemStatement_mainContent">
        <div className="problemStatement_mainContent_question_container">
          <h2>Problem Details:</h2>
          <h4>{questionDetails?.question}</h4>
        </div>
        <div>
          <h2>Requirements:</h2>
          <div>
            {questionDetails?.requirements?.map((requirement) => {
              return getStarPoints(requirement);
            })}
          </div>
        </div>
      </div>
    );
  };
  const getStarPoints = (title, index) => {
    return (
      <div key={index} style={{ display: "flex", alignItems: "center" }}>
        <StarRateIcon style={{ color: "#FF416C", fontSize: "18px" }} />
        <span
          style={{
            margin: "18px 10px",
            fontFamily: "sans-serif",
            fontSize: "16px",
          }}
        >
          {title}
        </span>
      </div>
    );
  };

  const getProblemSolutionImages = () => {
    return (
      <div className="getProblemSolutionImages">
        <div>
          <h2>Results:</h2>
        </div>
        <div>
          <ImageOpeningComponent images={questionDetails?.image} />
        </div>
      </div>
    );
  };
  return (
    <div className="problemStatement_main_container">
      {getLeftProblemStatement()}
      {mainContent()}
      {getProblemSolutionImages()}
    </div>
  );
}

export default ProblemStatementScreen;
