import React from "react";
import "./SuggestedCoursesCard.css";
import { ImageConstants } from "../../../utils/constants";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { addSelectedCourseFromListAction } from "../../../actions/Education/courseActions";
import { useDispatch, useSelector } from "react-redux";

function SuggestedCoursesCard({ course, index }) {
  const dispatch = useDispatch();
  const handleClick = () => {
    dispatch(addSelectedCourseFromListAction(index, "suggestedCourses"));
  };
  const { selectedCourseFromList, myClickCategory } = useSelector(
    (state) => state.selectedCourseFromListStore
  );

  return (
    <div
      onClick={() => handleClick(index)}
      className={`${
        myClickCategory === "suggestedCourses" &&
        selectedCourseFromList === index
          ? "suggestedCourses_selected"
          : "suggestedCourse"
      } `}
    >
      <div className="suggestedCourse_image_container">
        <img src={ImageConstants.shopping_project_image} alt="" />
      </div>
      <div className="suggested_details_container">
        <p>{course?.title}</p>
        <span>{course?.subtitle}</span>
      </div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div className="suggested_courses_below">
          <div>
            <OndemandVideoIcon style={{ fontSize: "16px", color: "grey" }} />
            <span>{course?.numberOfVideos ?? 10} videos</span>
          </div>
          <div>
            <AccessTimeIcon style={{ fontSize: "16px", color: "grey" }} />
            <span>{course?.hours ?? 8} hrs</span>
          </div>
        </div>
        <div className="suggested_price">
          <h4>₹{course?.currPrice}</h4>
        </div>
      </div>
    </div>
  );
}

export default SuggestedCoursesCard;
