import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const AdminProtectedRoute = ({ component: Component, ...rest }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [giveAccess, setGiveAccess] = useState(false);
  useEffect(() => {
    if (userInfo && userInfo.isSuperAdmin) {
      setGiveAccess(true);
    }
  }, [userInfo]);

  if (userInfo === null || userInfo === undefined) {
    // Render a loading spinner or some other loading indicator
    return <div>Loading...</div>;
  }
  if (!userInfo) {
    return <Redirect to="/" />;
  }
  return (
    <Route
      {...rest}
      render={(props) => giveAccess && <Component {...props} />}
    />
  );
};

export default AdminProtectedRoute;
