import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./EducationTopBar.css";
import MenuIcon from "@mui/icons-material/Menu";
import TemporaryDrawer from "../reusables/drawer";
import { ImageConstants } from "../../../utils/constants";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useDispatch, useSelector } from "react-redux";
import { userLogoutAction } from "../../../actions/userActions";
import ProfileDropdown from "../reusables/profile_dropdown";

function EducationTopBar({ logo }) {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  // const [openDrawer, setOpenDrawer] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const history = useHistory();

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });
  const handleNavigation = (navigateTo) => {
    history.push(navigateTo);
  };

  const dispatch = useDispatch();
  const handleSignOut = () => {
    dispatch(userLogoutAction());
  };

  const getTopHeader = () => {
    return (
      <div className="educationTopbarContainer">
        {/* Logo */}
        <div onClick={() => history.push("/")} className="logo">
          <img
            src={logo === undefined ? ImageConstants.main_logo : logo}
            alt=""
          />
        </div>

        {isSmallScreen || isMobile ? (
          <div className="menuIcon">
            <MenuIcon fontSize="large" onClick={toggleDrawer} />
          </div>
        ) : (
          <>
            <div className="top_options_container">
              <h2 onClick={() => history.push("/")}>Home</h2>
              <h2 onClick={() => handleNavigation("events")}>Events</h2>
              <h2 onClick={() => handleNavigation("courses")}>Courses</h2>
              <h2 onClick={() => history.push("/certificates")}>
                Certificates
              </h2>
              <h2>About Us</h2>
            </div>

            {!userInfo ? (
              <div className="button_container">
                <div>
                  <Button
                    onClick={() => history.push("/login")}
                    className="sign_in"
                  >
                    Sign In
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={() => history.push("/signup")}
                    className="sign_up"
                  >
                    Sign UP
                  </Button>
                </div>
              </div>
            ) : (
              <div
                style={{
                  border: "1px solid lightgray",
                  borderRadius: "52px",
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  margin: "0px 40px",
                }}
              >
                <ProfileDropdown
                  // userImage={ImageConstants.getUserProfile_image}
                  userName={userInfo?.firstName}
                />
              </div>
            )}
          </>
        )}
      </div>
    );
  };
  return (
    <div>
      <div>{getTopHeader()}</div>
      <TemporaryDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
    </div>
  );
}

export default EducationTopBar;
