import React, { useEffect, useRef, useState } from "react";
import "./EducationHomePage.css";
import EducationTopBar from "./topbar/EducationTopBar";
import { Button, Card, CardContent } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import {
  ImageConstants,
  MoneySigns,
  StringConstants,
} from "../../utils/constants";
import Carousel from "react-material-ui-carousel";
import { Helmet } from "react-helmet";
import StarRateIcon from "@mui/icons-material/StarRate";
import EducationFooter from "./footer/education_footer";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import YouTube from "react-youtube";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  getWebinarEventAction,
  webinarEventComingFromAction,
} from "../../actions/WebinarEvent/webinarEventAction";
import useCountdown from "../../utils/countdown";
import { redirectToPageAction } from "../../actions/userActions";
import { REDIRECT_WEBINAR_EVENT_PAGE } from "../../constants/WebinarEvents/webinarEventConstants";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import LoadingAnimation from "./reusables/LoadingAnimation";

const comments_data = [
  {
    comment: StringConstants.tejas_comment,
    name: "Tejas Shelar",
    location: "India",
    image: ImageConstants.profile_image_2,
  },
  {
    comment: StringConstants.aditya_comment,
    name: "Aditya Shakya",
    location: "India",
    image: ImageConstants.profile_image_1,
  },
  {
    comment: StringConstants.snehal_comment,
    name: "Snehal More",
    location: "India",
    image: ImageConstants.profile_image_3,
  },
];

const projects_data = [
  {
    title: "Video Player with Ads embedding",
    summary:
      "In this project, we will develop a video player that allows users to load videos and integrate advertisements at specific time frames, which will be displayed to the end users.",
    points: [
      "Create Frontend for Video Player in React",
      "Create Backend APIs",
      "Learn to Embed Ads in between the Video",
      "Play the ads in between the running video at the mention timeframe",
    ],
    image: ImageConstants.video_project_image,
  },
  {
    title: "Instagram Discovery feed with Algorithms",
    summary:
      "In this project, we will create Instagram Discovery feed and create algorithms to update the feed depending how users behave on our feed.",
    points: [
      "Create Instagram like Feed design in React",
      "Send image data from backend using APIs",
      "Learn how to create algorithms which Instagram use",
      "Update the feed based on the created algorithm",
    ],
    image: ImageConstants.instagram_project_image,
  },
  {
    title: "Video Shopping Service",
    summary:
      "In this project, we will develop an online tool where users can directly utilize our video player. We will integrate the ability to add shopping products at specific time frames within the videos, allowing users to view and directly purchase them.",
    points: [
      "Create Frontend for Video Player in React",
      "Create Backend APIs",
      "Learn to Embed Products and purchase button in between the Video",
    ],
    image: ImageConstants.shopping_project_image,
  },
  {
    title: "Generalised Inventory Management",
    summary:
      "In this project, we will develop an Inventory Management System which can be used by any business to manage their inventory and products.",
    points: [
      "Create Full Frontend in React",
      "Create Backend APIs in NodeJS",
      "Scalable Backend with Error handling",
      "Creating generalised database model to accomodate any business easily",
    ],
    image: ImageConstants.distribute_project_image,
  },
];
function EducationHomePage() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { popularResult } = useSelector((state) => state.upcomingEventStore);

  const history = useHistory();
  const videoRef = useRef(null);
  const timeRemaining = useCountdown();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebinarEventAction("upcoming"));
  }, []);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [showPlay, setShowPlay] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  });

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused === true) {
      videoElement.play();
      setShowPlay(true);
    } else {
      videoElement.pause();
      setShowPlay(false);
    }
  };

  const getHeadline = () => {
    return (
      <div className="headline_container">
        <div className="headline">
          <h1>
            Create <span className="headline_specialText">Projects</span>. Land{" "}
            <span className="headline_specialText">Jobs</span>
          </h1>
          <p>
            Experience the difference of comprehensive learning with AceTech.
            Our courses cover frontend, backend, and full-stack development,
            providing you with the hands-on experience needed to excel in
            today's competitive job market.
          </p>
        </div>
        <div className="headline_buttonContainer">
          <div className="headline_button">
            <Button onClick={() => history.push("/events")}>Explore</Button>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <PlayCircleIcon />
            <p>See how it works?</p>
          </div>
        </div>
      </div>
    );
  };

  const getHeadlineImage = () => {
    return (
      <>
        <div className="headline_image_container">
          <img
            src="https://res.cloudinary.com/cqn/image/upload/v1708703465/laptop_girl_qixn4k.png"
            alt=""
          />
        </div>
      </>
    );
  };
  const getHeadlineBanner = () => {
    return (
      <div style={{ marginTop: "-45px", marginBottom: "50px" }}>
        <div className="education_banner">
          <div>
            <h1>8+ LPA</h1>
            <span>Land Jobs in Companies offering more than 8+ LPA.</span>
          </div>

          <div>
            <h1>30+</h1>
            <span>
              Video Lectures on creating unique projects for your resume
            </span>
          </div>

          <div>
            <h1>3+</h1>
            <span>
              Levels of Certifications in FrontEnd, BackEnd & FullStack
              Development
            </span>
          </div>
        </div>
      </div>
    );
  };
  const getCardsContainer = () => {
    return (
      <div>
        <div className="cards_container">
          <div className="cards_title">
            <h1>
              {" "}
              <span className="headline_specialText">
                Why we are best{" "}
              </span>{" "}
              from others?
            </h1>
          </div>
          <div className="cards_subtitle">
            <p>
              We stand out by offering specialized video tutorials for unique
              frontend, backend, and full stack projects. Our commitment to
              demystifying complex concepts ensures you gain tangible skills,
              stand out professionally, and thrive in today's competitive
              market.
            </p>
          </div>
        </div>
        <div className="all_cards_container">
          {getCards(
            0,
            "Real-World Applications",
            ImageConstants.realworld_icon,
            StringConstants.realworld_subtitleString
          )}
          {getCards(
            1,
            "Get Certifications",
            ImageConstants.certificate_icon,
            StringConstants.certification_string
          )}
          {getCards(
            2,
            "Flexible Time",
            ImageConstants.flexible_time_icon,
            StringConstants.flexible_string
          )}
        </div>
      </div>
    );
  };
  const getCards = (id, title, image_src, subtitle) => {
    let moveTo = "/";
    switch (id) {
      case 0:
        moveTo = "/events";
        break;
      case 1:
        moveTo = "/certificates";
        break;
      case 2:
        moveTo = "/events";
        break;

      default:
        break;
    }
    return (
      <div onClick={() => history.push(moveTo)}>
        <Card sx={{ width: 275 }} style={{ cursor: "pointer" }}>
          <CardContent
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <img src={image_src} alt="" style={{ width: "60px" }} />
            </div>
            <div className="card_text_container">
              <p>{title}</p>
              <span>{subtitle}</span>
            </div>
          </CardContent>
        </Card>
      </div>
    );
  };

  const getWhatAboutCompany = () => {
    return (
      <div>
        <div className="about_company_title">
          <h1>
            {" "}
            <span className="headline_specialText">About</span> Company
          </h1>
        </div>
        <div className="about_company_subtitle">
          <p>
            At our company, we specialize in providing tailored video tutorials
            designed to empower students in crafting real-world applications
            across frontend, backend, and full stack development. Our dedication
            lies in unraveling intricate concepts, enabling you to acquire
            concrete skills, distinguish yourself in your field, and excel
            within the dynamic landscape of today's competitive industry.
          </p>
        </div>
        {/* <div className="video_container">
          {showPlay === false ? (
            <div className="video_play_button">
              <PlayCircleIcon style={{ color: "#fff", fontSize: "65px" }} />
            </div>
          ) : (
            <div className="video_pause_button">
              <PauseCircleIcon style={{ color: "#fff", fontSize: "65px" }} />
            </div>
          )}

          <video
            onClick={handleVideoClick}
            ref={videoRef}
            muted
            src={ImageConstants.video_about_company}
            playsInline
          />
        </div> */}
      </div>
    );
  };

  const getOurPrograms = () => {
    return (
      <div className="our_programs_container">
        <div className="our_programs_title">
          <h1>
            <span className="headline_specialText"> Our </span> Programs
          </h1>
        </div>
        <div className="our_programs_cards_container">
          <div>
            {our_programs_cards(
              0,
              "Create Unique Projects",
              StringConstants.our_program_card1,
              "https://res.cloudinary.com/cqn/image/upload/v1716063008/website_image_u5rus6.png"
            )}
          </div>
          <div>
            {our_programs_cards(
              1,
              "Get Certifications",
              StringConstants.our_program_card2,
              "https://res.cloudinary.com/cqn/image/upload/v1716063760/certification_image_usjd5c.png"
            )}
          </div>
          <div>
            {our_programs_cards(
              2,
              "Project Playlist",
              StringConstants.our_program_card3,
              "https://res.cloudinary.com/cqn/image/upload/v1716064290/playlist_obiynq.png"
            )}
          </div>
        </div>
      </div>
    );
  };

  const our_programs_cards = (id, title, subtitle, src) => {
    let moveTo = "";
    switch (id) {
      case 0:
        moveTo = "/events";
        break;
      case 1:
        moveTo = "/certificates";
        break;
      case 2:
        moveTo = "/events";
        break;

      default:
        break;
    }
    return (
      <div className="our_programs_card">
        <div className="our_programs_card_video">
          <img src={src} alt="" />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div>
            <h4 style={{ margin: "10px 0px", fontSize: "24px" }}>{title}</h4>
          </div>
          <div style={{ height: "132px" }}>
            <p
              style={{
                fontSize: "13px",
                fontWeight: "600",
                textAlign: "center",
                lineHeight: "22px",
                margin: "0px 10px",
              }}
            >
              {subtitle}
            </p>
          </div>
        </div>
        <div className="program_readmore_button">
          <Button onClick={() => history.push(moveTo)}>Read More</Button>
        </div>
      </div>
    );
  };

  const getWhatOurStudentsSay = () => {
    return (
      <div className="whatourstudentssay_container">
        <div className="whatourstudentssay_title">
          <h1>
            <span className="headline_specialText"> What </span> our students
            Say
          </h1>
        </div>
        <div className="students_comments_background">
          <div>
            <img
              src={ImageConstants.commas_image}
              alt=""
              width={100}
              style={{ marginTop: "-50px", marginLeft: "30px" }}
            />
          </div>
          <Carousel indicators={false} navButtonsAlwaysInvisible={true}>
            {comments_data.map((item, i) => {
              return (
                <div key={i}>
                  {/* <img src={item} alt="" /> */}
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      padding: "10px 30px",
                    }}
                  >
                    <p
                      style={{
                        color: "#fff",
                        fontSize: "18px",
                        textAlign: "center",
                      }}
                    >
                      {item.comment}
                    </p>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      marginTop: "50px",
                    }}
                  >
                    <div
                      style={{
                        maxWidth: "100px",
                        maxHeight: "120px",
                        borderRadius: "22px",
                        border: "3px solid #fff",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginTop: "-50px",
                      }}
                    >
                      <img
                        src={item.image}
                        alt=""
                        style={{
                          width: "100px",
                          height: "100px",
                          objectFit: "cover",
                          borderRadius: "22px",
                        }}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <h3 style={{ margin: "0px", fontSize: "25px" }}>
                        {item.name}
                      </h3>
                      <p style={{ margin: "0px" }}>{item.location}</p>
                    </div>
                  </div>
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
    );
  };

  const getHowtogetMore = () => {
    return (
      <div className="howtogetmore_container">
        <div className="howtogetmore_title">
          <h1>
            How to Get{" "}
            <span className="headline_specialText">More Than 8+ LPA?</span>
          </h1>
          <p>
            AceTech assists students in creating unique projects that enhance
            their resumes and improve their technical skills to be
            industry-ready.Our students have the advantage of standing out in
            job applications, as they have created unique projects that
            distinguish their resumes, making it easier for them to secure
            positions in companies.
          </p>
        </div>
        <div>
          {" "}
          {!isSmallScreen ? (
            <>
              {" "}
              {leftHowToGetMoreCard(
                projects_data[0].title,
                projects_data[0].summary,
                projects_data[0].points,
                projects_data[0].image
              )}
              {rightHowToGetMoreCard(
                projects_data[1].title,
                projects_data[1].summary,
                projects_data[1].points,
                projects_data[1].image
              )}
              {leftHowToGetMoreCard(
                projects_data[2].title,
                projects_data[2].summary,
                projects_data[2].points,
                projects_data[2].image
              )}
              {rightHowToGetMoreCard(
                projects_data[3].title,
                projects_data[3].summary,
                projects_data[3].points,
                projects_data[3].image
              )}
            </>
          ) : (
            <>
              {leftHowToGetMoreCard(
                projects_data[0].title,
                projects_data[0].summary,
                projects_data[0].points,
                projects_data[0].image
              )}
              {leftHowToGetMoreCard(
                projects_data[1].title,
                projects_data[1].summary,
                projects_data[1].points,
                projects_data[1].image
              )}
              {leftHowToGetMoreCard(
                projects_data[2].title,
                projects_data[2].summary,
                projects_data[2].points,
                projects_data[2].image
              )}
              {leftHowToGetMoreCard(
                projects_data[3].title,
                projects_data[3].summary,
                projects_data[3].points,
                projects_data[3].image
              )}
            </>
          )}
        </div>
      </div>
    );
  };

  const leftHowToGetMoreCard = (title, subtitle, listOfPoints, image) => {
    return (
      <div className="left_image_card">
        <div className="left_image_container">
          <img src={image} alt="" />
        </div>
        <div className="left_image_details">
          <div>
            <h1>{title}</h1>
          </div>
          <div>
            <p>{subtitle}</p>
          </div>
          <div>{listOfPoints.map((point) => getStarPoints(point))}</div>
          <div className="left_image_button">
            <Button>Learn</Button>
          </div>
        </div>
      </div>
    );
  };

  const getStarPoints = (title) => {
    return (
      <div key={title} style={{ display: "flex", alignItems: "center" }}>
        <StarRateIcon style={{ color: "#FF416C", fontSize: "18px" }} />
        <span style={{ margin: "10px 10px" }}>{title}</span>
      </div>
    );
  };
  const rightHowToGetMoreCard = (title, subtitle, listOfPoints, image) => {
    return (
      <div className="left_image_card">
        <div className="left_image_details">
          <div>
            <h1>{title}</h1>
          </div>
          <div>
            <p>{subtitle}</p>
          </div>
          <div>{listOfPoints.map((point) => getStarPoints(point))}</div>
          <div className="left_image_button">
            <Button>Learn</Button>
          </div>
        </div>
        <div className="left_image_container">
          <img src={image} alt="" />
        </div>
      </div>
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    border: "none",
    minWidth: 300,
    maxWidth: 500,
    boxShadow: 24,
    outline: 0,
    borderRadius: "12px",
  };

  const [open, setOpen] = useState(false);
  const handleClose = (reason) => {
    if (reason !== "escapeKeyDown" && reason !== "backdropClick") {
      setOpen(false);
    }
  };

  const closeEventPopup = () => {
    setOpen(false);
    sessionStorage.setItem("isPopupshown", true);
  };

  useEffect(() => {
    const onBeforeUnload = (ev) => {
      sessionStorage.removeItem("isPopupshown");
      return "";
    };

    window.addEventListener("beforeunload", onBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, []);

  useEffect(() => {
    if (!popularResult) {
      setOpen(true);
    }
    setTimeout(() => {
      if (
        popularResult !== undefined &&
        !sessionStorage.getItem("isPopupshown")
      )
        setOpen(true);
    }, 400);
  }, [popularResult]);

  const opts = {
    playerVars: {
      autoplay: 1,
    },
  };

  const registerEventClicked = () => {
    sessionStorage.setItem("isPopupshown", true);
    if (userInfo) {
      history.push("/events");
      dispatch(webinarEventComingFromAction(popularResult));
    } else {
      history.push("/login");
      dispatch(
        redirectToPageAction(REDIRECT_WEBINAR_EVENT_PAGE, {
          url: "/events",
          item: popularResult,
        })
      );
    }
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Home</title>
        <link
          rel="canonical"
          href="https://res.cloudinary.com/cqn/image/upload/v1705319231/icons_clobgy.png"
        />
      </Helmet>
      <EducationTopBar />
      <div style={{ display: "flex", marginTop: "50px" }}>
        <div className="education_circle">
          <img src={ImageConstants.my_semi_circle} alt="" />
        </div>
        <div className="top_container">
          <div>{getHeadline()}</div>
          {!isSmallScreen && <div>{getHeadlineImage()}</div>}
        </div>
      </div>
      <div>{getHeadlineBanner()}</div>
      <div>{getCardsContainer()}</div>
      <div>{getHowtogetMore()}</div>
      <div>{getWhatAboutCompany()}</div>
      <div>{getOurPrograms()}</div>
      <div>{getWhatOurStudentsSay()}</div>
      <div>
        <EducationFooter />
      </div>
      <div>
        <Modal
          open={open}
          onClose={(e, reason) => handleClose(reason)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="education_homePage_modal_main_container">
              <div className="education_homePage_modal_live_title_container">
                <div>
                  <img
                    style={{ width: "20px", height: "20px" }}
                    src={ImageConstants.getLiveIcon}
                    alt="live icon"
                  />
                  <h3>Upcoming Webinar</h3>
                </div>
                <div
                  onClick={closeEventPopup}
                  style={{
                    borderRadius: "50%",
                    width: "20px",
                    height: "20px",
                    backgroundColor: "#222",
                    display: "flex",
                    alignContent: "center",
                    justifyContent: "center",
                    cursor: "pointer",
                  }}
                >
                  <CloseIcon style={{ color: "#fff", fontSize: "15px" }} />
                </div>
              </div>
              {popularResult === undefined ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Skeleton width={250} height={50} />
                  <LoadingAnimation />

                  <div>
                    <Skeleton width={200} height={50} />
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "10px 0px",
                    }}
                  >
                    <Skeleton width={100} height={50} />
                  </div>
                </div>
              ) : (
                <div>
                  <div className="education_homePage_event_title">
                    <h1>{popularResult?.title}</h1>
                    <div className="education_homepage_youtube_container">
                      {
                        <YouTube
                          videoId={popularResult?.youtubeUrl}
                          opts={opts}
                        />
                      }
                    </div>
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <h3
                        style={{
                          fontFamily: "sans-serif",
                          textDecoration: "line-through",
                          margin: "10px 0px",
                        }}
                      >
                        {MoneySigns.indianRupees}
                        {popularResult?.originalPrice}
                      </h3>
                      <div
                        style={{
                          margin: "0px 10px",
                          backgroundColor: "#FF3131",
                          padding: "5px 15px",
                          borderRadius: "22px",
                        }}
                      >
                        <span
                          style={{
                            color: "#fff",
                            fontSize: "16px",
                            fontWeight: "700",
                          }}
                        >
                          {popularResult?.currPrice === "0"
                            ? "Free"
                            : popularResult?.currPrice}
                        </span>
                      </div>
                    </div>

                    <div>
                      <p
                        style={{
                          margin: "0px",
                          color: "#888",
                          fontWeight: "600",
                          fontSize: "14px",
                        }}
                      >
                        🎉Offer Valid till:{" "}
                        <span
                          style={{
                            fontFamily: "sans-serif",
                            fontWeight: "700",
                            fontSize: "14px",
                            color: "#222",
                            marginLeft: "5px",
                          }}
                        >
                          {timeRemaining}
                        </span>
                      </p>
                    </div>
                    <div className="event_popup_button">
                      <Button onClick={registerEventClicked}>
                        Register Now
                      </Button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default EducationHomePage;
