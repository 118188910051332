import React, { useEffect, useRef, useState } from "react";
import "./CoursesMobileView.css";
import { ImageConstants } from "../../utils/constants";
import SearchIcon from "@mui/icons-material/Search";
import { Box, IconButton, Modal } from "@mui/material";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BottomDrawer from "./reusables/BottomDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedCourseFromListAction,
  addSelectedFromExploreAction,
  getSelectedCourseDataAction,
  getSuggestedCoursesAction,
} from "../../actions/Education/courseActions";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import EducationFooter from "./footer/education_footer";
import MenuIcon from "@mui/icons-material/Menu";
import TemporaryDrawer from "./reusables/drawer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const explore_filters = [
  "FrontEnd",
  "BackEnd",
  "FullStack",
  "Quick Projects",
  "Job Ready",
];
function CoursesMobileView() {
  const dispatch = useDispatch();
  const history = useHistory();
  const { selectedFromExplore } = useSelector(
    (state) => state.selectedExploreCourse
  );
  const { loading: suggestedLoading, suggestedCourses } = useSelector(
    (state) => state.suggestedCoursesListStore
  );
  // const { selectedCourseFromList, myClickCategory } = useSelector(
  //   (state) => state.selectedCourseFromListStore
  // );
  const { loading, selectedCourseList } = useSelector(
    (state) => state.coursesFromServer
  );
  const topRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [selectedCourseDetails, setSelectedCourseDetails] = useState(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  const getUpperBar = () => {
    return (
      <div className="courses_mobile_upperBar_main_container">
        <div className="courses_mobile_logo_container">
          <img
            onClick={() => history.push("/")}
            src={ImageConstants.courses_page_logo}
            alt=""
          />
        </div>
        <div>{getSearchBar()}</div>

        <div style={{ marginRight: "10px" }}>
          <MenuIcon fontSize="large" onClick={toggleDrawer} />
        </div>
      </div>
    );
  };

  const getSearchBar = () => {
    return (
      <div className="course_mobile_search_container">
        <SearchIcon />
        <input type="text" placeholder="Search For Courses" />
      </div>
    );
  };

  const handleSelectedExplore = (value) => {
    topRef.current.scrollIntoView();
    dispatch(addSelectedFromExploreAction(value));
    switch (value) {
      case "frontend":
        dispatch(getSelectedCourseDataAction("FrontEnd"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FullStack", "QuickJob", "JobReady"],
            "FrontEnd"
          )
        );
        break;
      case "backend":
        dispatch(getSelectedCourseDataAction("BackEnd"));
        dispatch(
          getSuggestedCoursesAction(
            ["FrontEnd", "FullStack", "QuickJob", "JobReady"],
            "BackEnd"
          )
        );
        break;
      case "fullstack":
        dispatch(getSelectedCourseDataAction("FullStack"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FrontEnd", "QuickJob", "JobReady"],
            "FullStack"
          )
        );
        break;
      case "quick_projects":
        dispatch(getSelectedCourseDataAction("QuickJob"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FullStack", "FrontEnd", "JobReady"],
            "QuickJob"
          )
        );
        break;
      case "job_ready":
        dispatch(getSelectedCourseDataAction("JobReady"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FullStack", "QuickJob", "FrontEnd"],
            "JobReady"
          )
        );
        break;

      default:
        break;
    }

    // if (value !== selectedFromExplore) {
    //   handleAddSelectedCourseFromList(0);
    //   ref.current.scrollIntoView();
    // }
  };

  const getAllExplore = () => {
    return (
      <div className="courses_mobile_allExplore_container">
        <div className="courses_mobile_allExplore">
          {explore_filters.map((filter, index) => {
            var currClassName = "courses_mobile_filters";
            var currItem = getCorrectFilterNames(filter);

            if (currItem === selectedFromExplore) {
              currClassName = "courses_mobile_filters_selected";
            }
            return (
              <div
                onClick={() => handleSelectedExplore(currItem)}
                key={index}
                className={`${currClassName}`}
              >
                <h4>{filter}</h4>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const getCorrectFilterNames = (filter) => {
    switch (filter) {
      case "FrontEnd":
        return "frontend";
      case "BackEnd":
        return "backend";
      case "FullStack":
        return "fullstack";
      case "Quick Projects":
        return "quick_projects";
      case "Job Ready":
        return "job_ready";

      default:
        break;
    }
  };

  const [openBookNow, setOpenBookNow] = useState(false);

  const handleBookNowClose = () => setOpenBookNow(false);

  const bookNowStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 250,
    bgcolor: "#fffcf3",
    boxShadow: 24,
    p: 3,
  };

  const handleDrawerOpenClose = (course) => {
    setOpen(true);
    setSelectedCourseDetails(course);
  };

  const getMainCourses = (course, index) => {
    return (
      <div
        key={index}
        onClick={() => handleDrawerOpenClose(course)}
        className="courses_mobile_mainCourses"
      >
        <div className="courses_mobile_mainCourses_imageContainer">
          <img src={ImageConstants.courses_certificate_image} alt="" />
        </div>
        <div className="courses_mobile_mainCourses_details_container">
          <h3>{course?.title}</h3>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "18px",
              }}
            >
              <OndemandVideoIcon
                style={{ fontSize: "18px", color: "grey", marginRight: "5px" }}
              />
              <span style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
                {course?.numOfVideos} Lectures
              </span>
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginRight: "10px",
              }}
            >
              <AccessTimeIcon
                style={{ fontSize: "18px", color: "grey", marginRight: "5px" }}
              />
              <span style={{ fontFamily: "sans-serif", fontSize: "16px" }}>
                {course?.numOfHours} hrs
              </span>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", alignItems: "start" }}>
              <div>
                <h3
                  style={{
                    margin: "0px",
                    fontSize: "20px",
                    fontFamily: "sans-serif",
                  }}
                >
                  ₹{course?.currPrice}
                </h3>
                <span
                  style={{
                    fontSize: "18px",
                    margin: "0px",
                    textDecoration: "line-through",
                    color: "#888",
                    fontFamily: "sans-serif",
                  }}
                >
                  ₹{course?.originalPrice}
                </span>
              </div>
              <div
                style={{
                  backgroundColor: "#f73535dc",
                  padding: "2px 10px",
                  borderRadius: "15px",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "12px",
                    color: "#fff",
                  }}
                >
                  -73%
                </span>
              </div>
            </div>
            <div className="courses_mobile_main_courses_button_container">
              <IconButton onClick={() => setOpen(true)}>
                <ChevronRightIcon style={{ color: "#fff" }} />
              </IconButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    dispatch(getSelectedCourseDataAction("FrontEnd"));
    dispatch(addSelectedCourseFromListAction(0));
    dispatch(addSelectedFromExploreAction("frontend"));
    dispatch(
      getSuggestedCoursesAction(
        ["BackEnd", "FullStack", "QuickJob", "JobReady"],
        "FrontEnd"
      )
    );
  }, []);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100vh",
        alignItems: "center",
      }}
    >
      {getUpperBar()}
      {getAllExplore()}
      <div
        style={{
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          overflowY: "scroll",
        }}
      >
        <div ref={topRef} />
        {loading ? (
          <Skeleton
            count={3}
            style={{
              height: "80px",
              padding: "10px",
              borderRadius: "22px",
              margin: "15px 30px",
              width: "300px",
            }}
          />
        ) : (
          selectedCourseList?.data?.map((course, index) => {
            return getMainCourses(course, index);
          })
        )}
        <h2 style={{ marginLeft: "10px" }}>Suggested Courses</h2>
        {suggestedLoading ? (
          <Skeleton
            count={3}
            style={{
              height: "80px",
              padding: "10px",
              borderRadius: "22px",
              margin: "15px 30px",
              width: "300px",
            }}
          />
        ) : (
          suggestedCourses?.data?.map((course, index) => {
            return getMainCourses(course, index);
          })
        )}
        <div>{<EducationFooter margin={"50px"} />}</div>
      </div>

      <BottomDrawer
        open={open}
        setOpen={setOpen}
        selectedCourseDetails={
          selectedCourseDetails !== undefined && selectedCourseDetails
        }
        setOpenBookNow={setOpenBookNow}
      />
      <TemporaryDrawer isOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
      <Modal
        open={openBookNow}
        onClose={handleBookNowClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={bookNowStyle}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h1>Redirecting to Payment Gateway...</h1>
          </div>
        </Box>
      </Modal>
    </div>
  );
}

export default CoursesMobileView;
