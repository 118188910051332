import React, { useEffect, useState } from "react";
import {
  Button,
  CircularProgress,
  IconButton,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useWindowResize from "../../utils/WindowResize";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import "./Registration.css";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  googleLoginAction,
  userRegisterAction,
} from "../../actions/userActions";
import MyReactHelmet from "./reusables/react_helmet";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import { motion } from "framer-motion";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { countryData } from "../../utils/country_data";
import { USER_REGISTER_RESET } from "../../constants/User/userConstants";
import LoginScreenAnimation from "./reusables/LoginScreenAnimation";

function RegistrationScreen() {
  const isSmallScreen = useWindowResize();
  const [phone, setPhone] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const handleFirstNameChange = (e) => {
    setFirstName(e.target.value);
  };
  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
  };
  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    var currPassword = e.target.value;
    setPassword(currPassword);
    validatePassword(currPassword);
  };
  const handlePhoneChange = (e) => {
    var currPhone = e.target.value;
    if (currPhone.length < 11) {
      setPhone(e.target.value);
    }
  };

  const validatePassword = (password) => {};

  useEffect(() => {
    if (
      email.length > 0 &&
      password.length > 0 &&
      firstName.length > 0 &&
      lastName.length > 0
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
      setShowPassword(false);
    }
  }, [email, password, firstName, lastName]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const history = useHistory();
  const handleLoginClick = () => {
    history.push("/login");
  };

  const dispatch = useDispatch();
  const { success, error, loading } = useSelector(
    (state) => state.userRegister
  );

  const handleSubmit = () => {
    const phoneNumber = "+" + selectedCountryCode + " " + phone;
    dispatch(
      userRegisterAction(
        firstName,
        lastName,
        email,
        phoneNumber,
        password,
        countryDetails
      )
    );
  };

  const googleLoginButton = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              dispatch(googleLoginAction(credentialResponse.credential));
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
            width={isSmallScreen ? 300 : 600}
          />
        </GoogleOAuthProvider>
      </div>
    );
  };

  const getHorizontalDivider = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0px",
          padding: "0px 20px",
        }}
      >
        <hr
          style={{
            width: "50%",
            display: "block",
            height: "1px",
            border: 0,
            borderTop: " 1px solid #ccc",
            padding: "0",
          }}
        />
        <span
          style={{
            margin: "0px 10px",
            display: "flex",
            justifyContent: "center",
            fontSize: "12px",
          }}
        >
          or
        </span>
        <hr
          style={{
            width: "50%",
            display: "block",
            height: "1px",
            border: 0,
            borderTop: " 1px solid #ccc",
            padding: "0",
          }}
        />
      </div>
    );
  };

  const [showSignupFormDetails, setShowSignupFormDetails] = useState(false);

  const getContinueWithEmailButton = () => {
    return (
      <div>
        <Button
          onClick={() => setShowSignupFormDetails(true)}
          style={{
            border: "1px solid lightgray",
            textTransform: "inherit",
            color: "#222",
            padding: "10px",
            height: "50px",
            width: `${isSmallScreen ? "300px" : "400px"}`,
            borderRadius: "5px",
          }}
        >
          Continue with email
        </Button>
      </div>
    );
  };

  const [countryList, setCountryList] = useState([]);
  const [selectedCountryCode, setSelectedCountryCode] = useState("91");
  const [countryDetails, setCountryDetails] = useState("India");

  useEffect(() => {
    setCountryList(countryData);
  }, []);

  const countryChange = (event) => {
    setSelectedCountryCode(event.target.value);
    const country = countryList.find(
      (country) => country.phone_code === event.target.value
    );
    setCountryDetails(country?.country_name);
  };

  const getSignUpFormDetails = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className="registration_details">
          <div className="registration_details_name_container">
            <div className="registration_details_name">
              <label>First Name</label>
              <div className="registration_details_name_internal_container">
                <input
                  type="text"
                  value={firstName}
                  onChange={(e) => handleFirstNameChange(e)}
                />
              </div>
            </div>
            <div className="registration_details_name">
              <label>Last Name</label>
              <div className="registration_details_name_internal_container">
                <input
                  type="text"
                  value={lastName}
                  onChange={(e) => handleLastNameChange(e)}
                />
              </div>
            </div>
          </div>

          <div>
            <label>Email Address</label>
            <div className="registration_details_phone">
              <input
                type="text"
                value={email}
                onChange={(e) => handleEmailChange(e)}
                onClick={() => dispatch({ type: USER_REGISTER_RESET })}
              />
            </div>
          </div>
          <div className="phone_field">
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label
                style={{
                  fontSize: `${isSmallScreen ? "10px" : "14px"}`,
                  fontWeight: "500",
                  marginBottom: "10px",
                }}
              >
                Country Code
              </label>

              <Select
                sx={{
                  height: "40px",
                  borderRadius: "5px",
                  border: "1px solid #dadadae0",
                  "& .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: "none",
                  },
                }}
                value={selectedCountryCode}
                onChange={countryChange}
                displayEmpty
                inputProps={{ "aria-label": "Without label" }}
                renderValue={(selected) => selected || ""}
              >
                {countryList?.map((data, index) => {
                  return (
                    <MenuItem key={index} value={data?.phone_code}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <span style={{ marginRight: "10px" }}>
                          {data?.country_name}
                        </span>
                        <span>{data?.phone_code}</span>
                      </div>
                    </MenuItem>
                  );
                })}
              </Select>
            </div>
            <div className="phone_field_input_container">
              <label>Phone</label>
              <div>
                <input
                  type="number"
                  value={phone}
                  onChange={(e) => handlePhoneChange(e)}
                />
              </div>
            </div>
          </div>

          <div>
            <div className="registration_details_password">
              <label>Password</label>
              <div
                style={{ position: "relative" }}
                className="registration_details_phone"
              >
                <input
                  type={`${showPassword ? "text" : "password"}`}
                  value={password}
                  onChange={(e) => handlePasswordChange(e)}
                  placeholder="Enter 8+ characters"
                />
                {password.length > 0 && (
                  <div
                    style={{
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    onClick={handleShowPassword}
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div>
          {error?.status === 400 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {error?.data?.errors?.map((e, index) => {
                return (
                  <div key={index}>
                    <p
                      style={{
                        margin: "10px 0px",
                        fontSize: "18px",
                        color: "red",
                      }}
                    >
                      *{e?.msg}
                    </p>
                  </div>
                );
              })}
            </div>
          )}
          {error?.status === 403 && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <p
                style={{
                  margin: "10px 0px",
                  fontSize: "18px",
                  color: "red",
                }}
              >
                *{error?.data?.message}
              </p>
            </div>
          )}
        </div>
        <div className="form_submit_container">
          {loading ? (
            <Button className="login_button">
              <CircularProgress style={{ color: "white" }} />
            </Button>
          ) : (
            <Button
              disabled={isDisabled}
              className={`${isDisabled ? "disabled_color" : "login_button"}`}
              onClick={handleSubmit}
            >
              Create <ArrowRightAltIcon style={{ marginLeft: "5px" }} />
            </Button>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p>
            Already a user please{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
                fontWeight: "700",
                color: "#FF3131",
                marginTop: "20px",
              }}
              onClick={handleLoginClick}
            >
              Login!
            </span>
          </p>
        </div>
      </div>
    );
  };

  const mainForm = () => {
    return (
      <div className="main_form_container">
        <div className="main_form_container_title">
          {showSignupFormDetails && (
            <IconButton
              onClick={() => {
                setShowSignupFormDetails(false);
                dispatch({ type: USER_REGISTER_RESET });
              }}
              style={{
                border: "1px solid lightgray",
                marginBottom: "30px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <ArrowBackIosIcon style={{ fontSize: "14px" }} />
            </IconButton>
          )}
          <h1
            style={{
              fontSize: `${isSmallScreen ? "20px" : "28px"}`,
              marginBottom: "30px",
            }}
          >
            Join Us to acheive your dream
          </h1>
        </div>
        {showSignupFormDetails ? (
          <motion.div
            variants={{
              hidden: { opacity: 0, y: -10 },
              visible: { opacity: 1, y: 0 },
            }}
            initial="hidden"
            animate="visible"
            transition={{ duration: 0.5 }}
          >
            {getSignUpFormDetails()}
          </motion.div>
        ) : (
          <div>
            {googleLoginButton()}
            {getHorizontalDivider()}
            {getContinueWithEmailButton()}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <p>
                Already a user please{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    cursor: "pointer",
                    fontWeight: "700",
                    color: "#FF3131",
                    marginTop: "20px",
                  }}
                  onClick={handleLoginClick}
                >
                  Login!
                </span>
              </p>
            </div>
          </div>
        )}
      </div>
    );
  };

  const getImageSection = () => {
    return <LoginScreenAnimation />;
  };
  return (
    <>
      <MyReactHelmet value={"Sign Up!"} />

      <div className="registration_main_container">
        {!isSmallScreen && getImageSection()}

        {mainForm()}
      </div>
    </>
  );
}

export default RegistrationScreen;
