import React, { useEffect, useState } from "react";
import { Avatar, Popover } from "antd";
import "./MyStepper.css";
import HelpIcon from "@mui/icons-material/Help";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import {
  Button,
  Checkbox,
  CircularProgress,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import { ImageConstants, MoneySigns } from "../../../utils/constants";
import ProblemStatementScreen from "./ProblemStatementScreen";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import QuizIcon from "@mui/icons-material/Quiz";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useDispatch, useSelector } from "react-redux";
import { MyConversion } from "../../../utils/conversion";
import {
  certificateEnrollmentAction,
  certificateProblemStatementSelectedAction,
  enrollmentReset,
  getCertificateAction,
} from "../../../actions/Education/certificateActions";
import { useHistory } from "react-router-dom";

const reviews = [
  {
    username: "Aditya Shakya",
    usercomment:
      "“Heartfelt thanks for crafting an exceptional FrontEnd Internship Certificate experience! Truly transformative!”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Tejas Shelar",
    usercomment:
      "“Immense gratitude for an enriching BackEnd Internship Certificate journey. Exceptional quality and expertise showcased!”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Sumit Kolpekwar",
    usercomment:
      "“Kudos on orchestrating an outstanding FullStack Internship Certificate program! Exceeded expectations.....”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Snehal More",
    usercomment:
      "“Exceptional craftsmanship on the FrontEnd Internship Certificate! Thank you for the invaluable skills and memorable experience”",
    userimage: ImageConstants.getAvatarImage,
  },
];

const MyStepper = () => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const { certificates, loading, success } = useSelector(
    (state) => state.getCertificateStore
  );
  const { problemStatement } = useSelector(
    (state) => state.certificateProblemStatements
  );
  const { loading: enrollLoading, success: enrollSuccess } = useSelector(
    (state) => state.certificateEnrollmentStore
  );
  const url = window.location.href.split("/");

  const getReviews = () => {
    return (
      <div>
        {reviews?.map((review, index) => {
          return (
            <div key={index} className="myStepper_welcome_reviews">
              <div>
                <Avatar src={review?.userimage} alt="" />
                <div>
                  <h3>{review?.username}</h3>
                  <h4>Software Engineer</h4>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span>{review?.usercomment}</span>
              </div>
            </div>
          );
        })}
      </div>
    );
  };
  const getWelcomeScreen = () => {
    return (
      <div
        style={{
          display: "flex",
        }}
      >
        <div style={{ margin: "auto 0px" }}>{getReviews()}</div>
        <div className="myStepper_welcome_container">
          <div className="myStepper_welcome_title">
            <h1>
              Welcome, Aboard{" "}
              <span
                style={{
                  color: "#FF3131",
                  fontSize: "3rem",
                  fontWeight: "800",
                }}
              >
                {userInfo?.firstName}
              </span>
            </h1>
          </div>

          <div className="myStepper_welcome_nextSteps_container">
            <h3>Next Steps to follow:</h3>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <HelpIcon
                  style={{
                    color: "#fff",
                    marginRight: "5px",
                  }}
                />
                <h4
                  style={{
                    color: "#fff",
                    margin: "0px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Step 1:
                </h4>
              </div>

              <h3
                style={{
                  color: "#fff",
                  fontSize: "0.8vw",
                  lineHeight: "22px",
                  fontWeight: "500",
                }}
              >
                Select the problem from the list of problems shared. All the
                requirements are listed for each problem statement along with
                videos and photos of expected results.
              </h3>
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <GitHubIcon
                  style={{
                    color: "#fff",
                    marginRight: "5px",
                  }}
                />
                <h4
                  style={{
                    color: "#fff",
                    margin: "0px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Step 2:
                </h4>
              </div>

              <h3
                style={{
                  color: "#fff",
                  fontSize: "0.8vw",
                  lineHeight: "22px",
                  fontWeight: "500",
                }}
              >
                Once completed, add your solution to github(we have shared how
                to add your solution to github) and share the link with us.
              </h3>
            </div>
            <div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <EmailIcon
                  style={{
                    color: "#fff",
                    marginRight: "5px",
                  }}
                />
                <h4
                  style={{
                    color: "#fff",
                    margin: "0px",
                    fontFamily: "sans-serif",
                  }}
                >
                  Step 3:
                </h4>
              </div>

              <h3
                style={{
                  color: "#fff",
                  fontSize: "0.8vw",
                  lineHeight: "22px",
                  fontWeight: "500",
                }}
              >
                We will review and share your certificate on your email.
              </h3>
            </div>
          </div>

          <div className="myStepper_welcome_nextButton_container">
            <Button onClick={() => next()}>Next</Button>
          </div>
        </div>
      </div>
    );
  };
  const [summaryCertificateName, setSummaryCertificateName] = useState({
    certificate_type: certificates?.certificate_type,
    certificate_category: certificates?.certificate_category,
  });
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    if (enrollSuccess) {
      history.replace("/dashboard");
      dispatch(enrollmentReset());
    }
  }, [enrollSuccess, history]);
  useEffect(() => {
    var conversion = new MyConversion();
    var certificateType = conversion.toCamelCase(
      certificates?.certificate_type
    );
    var certificateCategory = conversion.toCamelCase(
      certificates?.certificate_category
    );

    setSummaryCertificateName({
      certificate_type: certificateType,
      certificate_category: certificateCategory,
    });
  }, []);

  useEffect(() => {
    dispatch(
      certificateProblemStatementSelectedAction(
        certificates?.problemStatement[0],
        1
      )
    );
  }, [dispatch, certificates]);

  useEffect(() => {
    dispatch(getCertificateAction(url[4], url[5]));
  }, []);

  const getPaymentScreenLeftSummaryDetails = () => {
    return (
      <div>
        {/* certification details section */}
        <div className="myStepper_summary_title_container">
          <div className="myStepper_summary_title_icon">
            <CardMembershipIcon style={{ color: "#fff" }} />
          </div>
          <div className="myStepper_summary_title">
            <div>
              <p>Certification</p>
            </div>
            <h2>
              <span style={{ color: "#FF3131" }}>
                {" "}
                {summaryCertificateName?.certificate_type}{" "}
                {summaryCertificateName?.certificate_category} Internship{" "}
              </span>{" "}
              Certification
            </h2>
          </div>
        </div>
        {/* svg bordered line */}
        <div style={{ marginLeft: "-30px", marginTop: "-10px" }}>
          <svg width="90" height="70">
            <line
              x1="50"
              y1="0"
              x2="50"
              y2="100"
              stroke="lightgrey"
              strokeDasharray="5, 5"
            />
          </svg>
        </div>
        {/* selected problem statement details */}
        <div className="myStepper_summary_problemStatement_main_container">
          <div className="myStepper_summary_title_icon">
            <QuizIcon style={{ color: "#fff" }} />
          </div>
          <div className="myStepper_summary_problemStatement_container">
            <div className="myStepper_summary_problemStatement_title">
              <p>Statement Selected:</p>
              <div>
                <QuizIcon style={{ fontSize: "18px" }} />
                <h2>Problem Statement {problemStatement?.index}</h2>
              </div>
            </div>
            <div className="myStepper_summary_problemStatement_main_statement">
              <p>Problem Details:</p>
              <h4>{problemStatement?.selectedProblemStatement?.question}</h4>
            </div>
            <div className="myStepper_summary_problemStatement_image_container">
              <p>Results:</p>
              {problemStatement?.selectedProblemStatement?.image?.map(
                (e, index) => {
                  if (index < 3) {
                    return <img width={100} src={e} alt="" />;
                  } else {
                    return;
                  }
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const [termsConditions, setTermsConditions] = useState(false);
  const handleEnrollClick = () => {
    if (termsConditions) {
      dispatch(
        certificateEnrollmentAction(
          certificates?._id,
          problemStatement?.selectedProblemStatement?._id
        )
      );
    }

    if (termsConditions === false) {
      handleOpenChange(true);
    }
  };

  const [open, setOpen] = useState(false);
  // const hide = () => {
  //   setOpen(false);
  // };
  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const getPaymentScreenRightSummary = () => {
    return (
      <div className="getPaymentScreenRight_main_container">
        <div>
          <h2 style={{ margin: "0px" }}>Summary</h2>
        </div>
        <div>
          {/* subtotal section */}
          <div>
            <div className="getPaymentScreenRight_subtotal">
              <p>Subtotal</p>
              <h4>{MoneySigns.indianRupees}599</h4>
            </div>
          </div>
          {/* offer applied section */}
          <div>
            <div className="getPaymentScreenRight_subtotal">
              <p>
                Discount{" "}
                <span style={{ fontFamily: "sans-serif", color: "lightgreen" }}>
                  100%
                </span>{" "}
                applied
              </p>
              <h4 style={{ fontSize: "20px", color: "red" }}>
                - {MoneySigns.indianRupees}599
              </h4>
            </div>
          </div>
          <hr style={{ margin: "20px 0px" }} />
          {/* total price to pay */}
          <div>
            <div className="getPaymentScreenRight_subtotal">
              <p style={{ fontSize: "18px", fontWeight: "600" }}>Total</p>
              <h4 style={{ fontSize: "26px" }}>{MoneySigns.indianRupees}0</h4>
            </div>
          </div>
          <div className="getPaymentsScreenRight_couponCode">
            <p>Coupon Code</p>
            <input type="text" placeholder="FIRST" />
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginTop: "30px" }}
          >
            <Checkbox
              size="14"
              style={{ color: "#FF3131" }}
              onChange={(e) => setTermsConditions(e.target.checked)}
            />
            <Popover
              placement="bottomLeft"
              content={content}
              trigger="click"
              open={open}
              onOpenChange={handleOpenChange}
              overlayStyle={{
                padding: "2px",
              }}
            ></Popover>
            <p style={{ fontSize: "14px" }}>
              I agree to{" "}
              <span style={{ textDecoration: "underline", cursor: "pointer" }}>
                Terms & Conditions
              </span>
            </p>
          </div>
          <div className="getPaymentsScreenRight_enrollNow_button">
            {enrollLoading ? (
              <div
                style={{
                  padding: "8px",
                  width: "100%",
                  backgroundColor: "#FF3131",
                  borderRadius: "12px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <CircularProgress
                  style={{ color: "#fff", width: "30px", height: "30px" }}
                />
              </div>
            ) : (
              <Button onClick={() => handleEnrollClick()}>Enroll Now</Button>
            )}
          </div>
        </div>
      </div>
    );
  };

  const content = (
    <div>
      <span style={{ color: "red" }}>*Please accept terms and conditons.</span>
    </div>
  );

  const getPaymentScreen = () => {
    return (
      <div className="getPaymentScreen_main_screen">
        {getPaymentScreenLeftSummaryDetails()}
        {getPaymentScreenRightSummary()}
      </div>
    );
  };

  const steps = [
    {
      title: "Welcome",
      content: getWelcomeScreen(),
    },
    {
      title: "Problem Statement",
      content: <ProblemStatementScreen details={certificates} />,
    },
    {
      title: "Summary",
      content: getPaymentScreen(),
    },
  ];

  // const dispatch = useDispatch()
  const [current, setCurrent] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const next = () => {
    setCurrent(current + 1);
    setActiveStep(activeStep + 1);
    // if(current === 1) {
    //   dispatch(getCertificateFilteredProblemStatementAction())
    // }
  };
  const prev = () => {
    setCurrent(current - 1);
    setActiveStep(activeStep - 1);
  };

  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#FF3131",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#FF3131",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }));

  return (
    <div>
      <Stepper activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((step, index) => {
          return (
            <Step
              key={index}
              sx={{
                "& .MuiStepLabel-root .Mui-completed": {
                  color: "#FF3131", // circle color (COMPLETED)
                },
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                  {
                    color: "grey.500", // Just text label (COMPLETED)
                  },
                "& .MuiStepLabel-root .Mui-active": {
                  color: "#FF3131", // circle color (ACTIVE)
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                  {
                    color: "common.white", // Just text label (ACTIVE)
                  },
                "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                  fill: "white", // circle's number (ACTIVE)
                },
              }}
            >
              <StepLabel>{step.title}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      <>{steps[current].content}</>
      <div
        style={{
          marginTop: 24,
        }}
      >
        {current === steps.length - 1 && (
          <div className="stepper_bottom_buttons_container">
            <Button className="stepper_previous" onClick={() => prev()}>
              <ArrowBackIcon
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              Back
            </Button>
          </div>
        )}
        {current > 0 && current !== steps.length - 1 && (
          <div className="stepper_bottom_buttons_container">
            <Button className="stepper_previous" onClick={() => prev()}>
              <ArrowBackIcon
                style={{ fontSize: "18px", marginRight: "10px" }}
              />{" "}
              Back
            </Button>
            <Button className="stepper_next" onClick={() => next()}>
              Next
              <ArrowForwardIcon
                style={{ fontSize: "18px", marginLeft: "10px" }}
              />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
export default MyStepper;
