import React, { useEffect, useState } from "react";
import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { userInfo } = useSelector((state) => state.userLogin);
  const [loggedinUser, setLoggedInUser] = useState(
    localStorage.getItem("acetechies_logged_in_user")
  );
  useEffect(() => {
    const storedUser = localStorage.getItem("acetechies_logged_in_user");
    if (storedUser) {
      setLoggedInUser(storedUser);
    }
  }, []);
  return (
    <Route
      {...rest}
      render={(props) =>
        userInfo || loggedinUser ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export default ProtectedRoute;
