import {
  CERTIFICATE_ENROLLMENT_FAIL,
  CERTIFICATE_ENROLLMENT_LOADING,
  CERTIFICATE_ENROLLMENT_RESET,
  CERTIFICATE_ENROLLMENT_SUCCESS,
  CERTIFICATE_PROBLEM_STATEMENT_SELECTED,
  GET_CERTIFICATE_FILTERED_PROBLEM_STATEMENT,
  GET_CERTIFICATE_REQUEST_FAILED,
  GET_CERTIFICATE_REQUEST_LOADING,
  GET_CERTIFICATE_REQUEST_SUCCESS,
} from "../../constants/Education/certificateConstants";
import { APIRequestURI } from "../../utils/api_request_uri";
import axios from "axios";

export const certificateProblemStatementSelectedAction =
  (val, index) => (dispatch) => {
    const problemStatement = {
      selectedProblemStatement: val,
      index: index,
    };
    dispatch({
      type: CERTIFICATE_PROBLEM_STATEMENT_SELECTED,
      payload: problemStatement,
    });
  };

export const getCertificateAction =
  (certificate_type, certificate_category) => async (dispatch) => {
    try {
      dispatch({ type: GET_CERTIFICATE_REQUEST_LOADING });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      var getStoredData = JSON.parse(
        localStorage.getItem(`certificate-${certificate_type}`)
      );

      if (getStoredData === undefined || getStoredData === null) {
        const URI = new APIRequestURI().getBaseURI();
        const { data } = await axios.post(
          `${URI}/api/certification/get`,
          { certificate_type },
          config
        );
        localStorage.setItem(
          `certificate-${certificate_type}`,
          JSON.stringify(data)
        );
        getStoredData = data?.find(
          (data) => data.certificate_category === certificate_category
        );
        dispatch({
          type: GET_CERTIFICATE_REQUEST_SUCCESS,
          payload: getStoredData,
        });
      } else {
        getStoredData = getStoredData?.find(
          (data) => data.certificate_category === certificate_category
        );

        dispatch({
          type: GET_CERTIFICATE_REQUEST_SUCCESS,
          payload: getStoredData,
        });
      }
    } catch (error) {
      console.log(error);
      dispatch({ type: GET_CERTIFICATE_REQUEST_FAILED, payload: error });
    }
  };

export const getCertificateFilteredProblemStatementAction =
  (data) => (dispatch) => {
    dispatch({
      type: GET_CERTIFICATE_FILTERED_PROBLEM_STATEMENT,
      payload: data,
    });
  };

export const certificateEnrollmentAction =
  (certificateId, selected_problem_statement) => async (dispatch, getState) => {
    try {
      sessionStorage.removeItem("acetechies-certifications-info");
      dispatch({ type: CERTIFICATE_ENROLLMENT_LOADING });

      const {
        userLogin: { userInfo },
      } = getState();

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.post(
        `${URI}/api/submission/enroll`,
        { certificateId, selected_problem_statement },
        config
      );
      dispatch({
        type: CERTIFICATE_ENROLLMENT_SUCCESS,
        payload: data,
      });
    } catch (error) {
      dispatch({ type: CERTIFICATE_ENROLLMENT_FAIL, payload: error.message });
    }
  };

export const enrollmentReset = () => (dispatch) => {
  dispatch({ type: CERTIFICATE_ENROLLMENT_RESET });
};
