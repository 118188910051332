import React, { useEffect, useState } from "react";
import "./UploadSolution.css";
import LeftReusableScreen from "../reusables/LeftReusableScreen";
import MyFilterAndSlide from "../reusables/MyFilterAndSlide";
import { Card, Button } from "@mui/material";
import { ImageConstants } from "../../../utils/constants";
import QuizIcon from "@mui/icons-material/Quiz";
import YouTube from "react-youtube";
import { useDispatch, useSelector } from "react-redux";
import StarRateIcon from "@mui/icons-material/StarRate";
import ImageOpeningComponent from "../reusables/ImageOpeningComponent";
import GitHubIcon from "@mui/icons-material/GitHub";
import {
  DashboardFilterCertificationAction,
  DashboardUploadGithublinkAction,
  DashboardUploadResetSolutionAction,
  DashboardUploadSolutionAction,
  DashboardUploadSolutionGithubResetSolutionAction,
} from "../../../actions/Dashboard/dashboardActions";
import { getFullCertificationName } from "../../../utils/get_full_certification_name";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { validateGithublink } from "../../../utils/my_regex";
import { motion } from "framer-motion";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import Confetti from "react-confetti";
import { useWindowSize } from "react-use";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircularProgress from "@mui/material/CircularProgress";
import { getCertificateAction } from "../../../actions/Education/certificateActions";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import EqualizerIcon from "@mui/icons-material/Equalizer";

const importantPoints = [
  "Please Review your work before sending",
  "All the requirements should be completed",
  "Results should be matched atleast by 80% accuracy",
  "Please update the GitHub README with a screenshot of the results for a quicker review process.",
];

const applyForCertificate = [
  {
    title: "FrontEnd Basic Certificate",
    difficulty: "Easy",
    time: "2 weeks",
    image: ImageConstants.getBasicCertificateImage,
    technology: "frontend",
    tag: "basic",
  },
  {
    title: "BackEnd Basic Certificate",
    difficulty: "Easy",
    time: "2 weeks",
    image: ImageConstants.getBasicCertificateImage,
    technology: "backend",
    tag: "basic",
  },
  {
    title: "FullStack Basic Certificate",
    difficulty: "Easy",
    time: "2 weeks",
    image: ImageConstants.getBasicCertificateImage,
    technology: "fullstack",
    tag: "basic",
  },
  {
    title: "FrontEnd Intermediate Certificate",
    difficulty: "Medium",
    time: "4 weeks",
    image: ImageConstants.getIntermediateCertificateImage,
    technology: "frontend",
    tag: "intermediate",
  },
  {
    title: "BackEnd Intermediate Certificate",
    difficulty: "Medium",
    time: "4 weeks",
    image: ImageConstants.getIntermediateCertificateImage,
    technology: "backend",
    tag: "intermediate",
  },
  {
    title: "FullStack Intermediate Certificate",
    difficulty: "Medium",
    time: "4 weeks",
    image: ImageConstants.getIntermediateCertificateImage,
    technology: "fullstack",
    tag: "intermediate",
  },
  {
    title: "FrontEnd Advanced Certificate",
    difficulty: "Advanced",
    time: "6-8 weeks",
    image: ImageConstants.getAdvancedCertificateImage,
    technology: "frontend",
    tag: "advanced",
  },
  {
    title: "BackEnd Advanced Certificate",
    difficulty: "Advanced",
    time: "6-8 weeks",
    image: ImageConstants.getAdvancedCertificateImage,
    technology: "backend",
    tag: "advanced",
  },
  {
    title: "FullStack Advanced Certificate",
    difficulty: "Advanced",
    time: "6-8 weeks",
    image: ImageConstants.getAdvancedCertificateImage,
    technology: "fullstack",
    tag: "advanced",
  },
];

function UploadSolution() {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    p: 4,
    outline: 0,
  };
  const { width, height } = useWindowSize();
  const [currProblemStatement, setCurrProblemStatement] = useState({});

  const { userInfo } = useSelector((state) => state.userLogin);
  const { loading, success } = useSelector(
    (state) => state.dashboardUploadGithublinkStore
  );
  const { selectedProblemStatement } = useSelector(
    (state) => state.dashboardUploadSolutionStore
  );
  const { inprogressCertificationsData } = useSelector(
    (state) => state.dashboardFilterCertificationsStore
  );
  const [githublink, setGithublink] = useState(
    selectedProblemStatement?.github_link
  );
  const dispatch = useDispatch();
  useEffect(() => {
    if (userInfo != null || userInfo !== undefined) {
      dispatch(DashboardFilterCertificationAction("inprogress"));
    }
    if (selectedProblemStatement !== undefined) {
      setCurrProblemStatement(
        selectedProblemStatement?.selected_problem_statement
      );
    }
  }, [dispatch, userInfo, selectedProblemStatement]);

  const history = useHistory();
  const handleUploadSolution = (val) => {
    dispatch(DashboardUploadSolutionAction(val));
    history.push("/dashboard/uploadsolution");
  };

  const getCard = (e) => {
    var image = ImageConstants.getBasicCertificateImage;
    if (e?.certificate?.certificate_category === "basic") {
      image = ImageConstants.getBasicCertificateImage;
    } else if (e?.certificate?.certificate_category === "intermediate") {
      image = ImageConstants.getIntermediateCertificateImage;
    } else {
      image = ImageConstants.getAdvancedCertificateImage;
    }
    return (
      <Card className="inprogress_certification_card">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="inprogress_certification_card_image"
            src={image}
            alt=""
          />
        </div>
        <div className="inprogress_certification_card_title">
          <h4 style={{ textAlign: "center" }}>
            {getFullCertificationName(
              e?.certificate?.certificate_type,
              e?.certificate?.certificate_category
            )}{" "}
            Internship
          </h4>
        </div>
        <div className="inprogress_certification_card_selected">
          <h5>Selected:</h5>
          <div>
            <QuizIcon style={{ fontSize: "20px" }} />
            <h4>
              Problem Statement{" "}
              {e?.selectedProblemStatement?.problem_number ?? "1"}
            </h4>
          </div>
        </div>
        <div className="inprogress_certification_card_uploadButtonContainer">
          <Button onClick={() => handleUploadSolution(e)}>Select</Button>
        </div>
      </Card>
    );
  };

  const handleEnrollClick = (technology, tag) => {
    history.push(`/certificates/${technology}/${tag}`);
    dispatch(getCertificateAction(technology, tag));
  };
  const applyForCertificationCard = (e) => {
    return (
      <Card className="inprogress_certification_card">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            className="inprogress_certification_card_image"
            src={e.image}
            alt=""
          />
        </div>
        <div className="inprogress_certification_card_title">
          <h4 style={{ textAlign: "center" }}>{e.title}</h4>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="upload_solution_applyForCard_details">
            <h5>Difficulty:</h5>
            <div>
              <EqualizerIcon style={{ fontSize: "20px" }} />
              <h4>{e.difficulty}</h4>
            </div>
          </div>
          <div className="upload_solution_applyForCard_details">
            <h5>Duration:</h5>
            <div>
              <AccessTimeIcon style={{ fontSize: "20px" }} />
              <h4>{e.time}</h4>
            </div>
          </div>
        </div>
        <div className="inprogress_certification_card_uploadButtonContainer">
          <Button onClick={() => handleEnrollClick(e.technology, e.tag)}>
            Enroll Now
          </Button>
        </div>
      </Card>
    );
  };
  const getStaticData = () => {
    const opts = {
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        // autoplay: 1,
      },
    };
    return (
      <div style={{ padding: "10px" }}>
        <div>
          <div>
            <h2 style={{ margin: "20px 0px 10px 0px" }}>
              Select Problem Statement:
            </h2>
          </div>
          <div>
            {inprogressCertificationsData?.length > 0 ? (
              <MyFilterAndSlide
                data={inprogressCertificationsData?.map((e) => getCard(e))}
              />
            ) : (
              <MyFilterAndSlide
                data={applyForCertificate?.map((e) =>
                  applyForCertificationCard(e)
                )}
              />
            )}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <h3 style={{ margin: "0px" }}>Steps To Upload Solution:</h3>
              <div>
                <div
                  style={{
                    backgroundColor: "#222",
                    padding: "20px 10px",
                    borderRadius: "22px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                  }}
                >
                  <h4 style={{ margin: "0px", color: "#fff" }}>
                    Step 1: Select the problem statement.
                  </h4>
                </div>
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#222",
                    padding: "20px 10px",
                    borderRadius: "22px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                  }}
                >
                  <h4 style={{ margin: "0px", color: "#fff" }}>
                    Step 2: Read the preview before submitting for the review.
                  </h4>
                </div>
              </div>
              <div>
                <div
                  style={{
                    backgroundColor: "#222",
                    padding: "20px 10px",
                    borderRadius: "22px",
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px 0px",
                  }}
                >
                  <h4 style={{ margin: "0px", color: "#fff" }}>
                    Step 3: Add the Github repository link and submit for
                    review.
                  </h4>
                </div>
              </div>
            </div>
            <div className="youtube_player_container">
              <h3>How to Upload Solution?</h3>
              <YouTube videoId="dKSrVSXvm4Y" opts={opts} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleGithublinkChange = (e) => {
    setGithublink(e.target.value);
  };

  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [runConfetti, setRunConfetti] = useState(false);
  const handleSendForReview = () => {
    if (githublink.length > 0) {
      if (validateGithublink(githublink)) {
        dispatch(
          DashboardUploadGithublinkAction(
            githublink,
            selectedProblemStatement?._id
          )
        );
      } else {
        setIsClicked(true);
        setTimeout(() => {
          setIsClicked(false);
        }, 1000);
      }
    }
  };

  useEffect(() => {
    if (success) {
      setRunConfetti(true);
    }
  }, [success]);

  const animationVariants = {
    initial: {
      x: 0,
    },
    animate: {
      x: [-10, 10, -10, 10, 0],
      transition: {
        duration: 0.5,
      },
    },
  };

  const getSelectedCardData = () => {
    return (
      <div className="upload_solution_getSelectedCard_main_container">
        <div className="upload_solution_getSelectedCard_previewSection">
          <div>
            <h2>Preview:</h2>
          </div>
          <div className="upload_solution_getSelectedCard_previewSection_title">
            <h4 style={{ margin: "10px 0px 5px 0px" }}>Question:</h4>
            <p>{currProblemStatement?.question}</p>
          </div>
          <div>
            {currProblemStatement?.requirements?.map((e, index) => {
              return getStarPoints(e, index);
            })}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "start",
              margin: "20px 0px",
            }}
          >
            <ImageOpeningComponent
              images={currProblemStatement?.image}
              bigImageWidth={"10vw"}
            />
          </div>
        </div>
        {/* Github link adding section */}
        <div className="upload_solution_sendReviewSection">
          <div>
            <GitHubIcon style={{ fontSize: "5vw" }} />
          </div>
          <div className="upload_solution_sendReviewSection_info_container">
            {importantPoints?.map((point) => {
              return (
                <div className="upload_solution_sendReviewSection_important_points">
                  <ErrorOutlineIcon style={{ marginRight: "10px" }} />
                  <span>{point}</span>
                </div>
              );
            })}
          </div>
          <div className="upload_solution_sendReview_inputSection">
            <h3>Add Github link:</h3>
            <input
              value={githublink}
              type="url"
              placeholder="https://github.com/your_username/your_repository"
              onChange={(e) => handleGithublinkChange(e)}
            />
          </div>
          <motion.div
            style={{ margin: "10px 0px" }}
            variants={animationVariants}
            initial="initial"
            animate={isClicked ? "animate" : "initial"}
          >
            <span style={{ fontSize: "10px", color: "red" }}>
              *Please follow format:
              https://github.com/your_username/your_repository
            </span>
          </motion.div>
          <div className="upload_solution_sendReview_inputSection_button_container">
            {success ? (
              <CheckCircleIcon style={{ color: "green", fontSize: "60px" }} />
            ) : (
              <Button
                disabled={githublink?.length === 0 || loading}
                className={`${
                  githublink?.length > 0
                    ? "upload_solution_sendForReview"
                    : "upload_solution_sendForReview_disabled"
                }`}
                onClick={() => handleSendForReview()}
              >
                {loading ? (
                  <CircularProgress
                    style={{ color: "#fff", width: "24px", height: "24px" }}
                  />
                ) : (
                  "Send work for review"
                )}
              </Button>
            )}
          </div>
        </div>
      </div>
    );
  };
  const getStarPoints = (title, index) => {
    return (
      <div key={index} style={{ display: "flex", alignItems: "center" }}>
        <StarRateIcon style={{ color: "#FF416C", fontSize: "18px" }} />
        <span
          style={{
            margin: "10px 10px",
            fontFamily: "sans-serif",
            fontSize: "1vw",
          }}
        >
          {title}
        </span>
      </div>
    );
  };
  const handleGoToDashboard = () => {
    dispatch(DashboardUploadResetSolutionAction());
    dispatch(DashboardUploadSolutionGithubResetSolutionAction());
    history.replace("/dashboard");
  };
  return (
    <>
      <div style={{ display: "flex" }}>
        <LeftReusableScreen />
        <div className="upload_solution_main_container">
          {selectedProblemStatement ? getSelectedCardData() : getStaticData()}
        </div>
      </div>
      <div>
        <Confetti
          width={width - 50}
          height={height - 10}
          recycle={false}
          wind={0.02}
          numberOfPieces={800}
          run={runConfetti}
          tweenDuration={1000}
          onConfettiComplete={() => setOpenSuccessModal(true)}
        />
      </div>
      <Modal
        style={{ backgroundColor: "rgb(106 101 101 / 22%)" }}
        open={openSuccessModal}
      >
        <Box sx={style}>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CheckCircleIcon style={{ color: "green", fontSize: "60px" }} />
              <div>
                <h2>Submission Successful</h2>
              </div>
            </div>
            <div>
              <p
                style={{
                  fontFamily: "sans-serif",
                  lineHeight: "30px",
                  margin: "0px",
                  fontSize: "14px",
                  textAlign: "center",
                }}
              >
                Thank you,{" "}
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {userInfo?.firstName} {userInfo?.lastName}
                </span>
                , for submitting your solution for the{" "}
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {getFullCertificationName(
                    selectedProblemStatement?.certificate?.certificate_type,
                    selectedProblemStatement?.certificate?.certificate_category
                  )}{" "}
                  Internship
                </span>{" "}
                certification. Your work is currently under review. Your
                certificate will be sent to you via email within the next{" "}
                <span style={{ fontSize: "16px", fontWeight: "600" }}>
                  {" "}
                  24 - 48 hours
                </span>
                .
              </p>
            </div>
            <div className="upload_solution_goToDashboard">
              <Button onClick={() => handleGoToDashboard()}>
                Go to Dashboard
              </Button>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default UploadSolution;
