import React, { useEffect, useState } from "react";
import { ImageConstants } from "../../../utils/constants";
import "./LeftReusableScreen.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import LaptopChromebookIcon from "@mui/icons-material/LaptopChromebook";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PublishIcon from "@mui/icons-material/Publish";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { DashboardUploadResetSolutionAction } from "../../../actions/Dashboard/dashboardActions";

const titleData = [
  {
    unselected_icon: <DashboardIcon className="leftReusable_unselectedIcon" />,
    selected_icon: <DashboardIcon className="leftReusable_selectedIcon" />,
    title: "Dashboard",
  },
  {
    unselected_icon: (
      <CardMembershipIcon className="leftReusable_unselectedIcon" />
    ),
    selected_icon: <CardMembershipIcon className="leftReusable_selectedIcon" />,
    title: "Certificates",
  },
  {
    unselected_icon: (
      <LaptopChromebookIcon className="leftReusable_unselectedIcon" />
    ),
    selected_icon: (
      <LaptopChromebookIcon className="leftReusable_selectedIcon" />
    ),
    title: "Courses",
  },
  {
    unselected_icon: <PublishIcon className="leftReusable_unselectedIcon" />,
    selected_icon: <PublishIcon className="leftReusable_selectedIcon" />,
    title: "Upload Solution",
  },
  {
    unselected_icon: (
      <AccountCircleIcon className="leftReusable_unselectedIcon" />
    ),
    selected_icon: <AccountCircleIcon className="leftReusable_selectedIcon" />,
    title: "Support",
  },
];

function LeftReusableScreen() {
  const dispatch = useDispatch();
  const [selected, setSelected] = useState(0);
  const url = window.location.href.split("/");
  useEffect(() => {
    if (url[3] === "dashboard" && (url[4] === null || url[4] === undefined)) {
      setSelected(0);
    } else if (url[3] === "dashboard" && url[4] === "certificates") {
      setSelected(1);
    } else if (url[3] === "dashboard" && url[4] === "courses") {
      setSelected(2);
    } else if (url[3] === "dashboard" && url[4] === "uploadsolution") {
      setSelected(3);
    } else if (url[3] === "dashboard" && url[4] === "support") {
      setSelected(4);
    }
  }, [url]);

  const history = useHistory();
  const handleTitleClick = (index) => {
    if (index !== 3) dispatch(DashboardUploadResetSolutionAction());
    switch (index) {
      case 0:
        history.push("/dashboard");

        break;
      case 1:
        history.push("/dashboard/certificates");

        break;
      case 2:
        history.push("/dashboard/courses");
        break;
      case 3:
        history.push("/dashboard/uploadsolution");
        break;
      case 4:
        history.push("/dashboard/support");
        break;

      default:
        history.push("/");
        break;
    }
  };

  return (
    <div className="leftScreen_reusable_mainContainer">
      <div className="leftScreen_reusable_logo_container">
        <img
          onClick={() => history.push("/")}
          src={ImageConstants.getDashboardLogo}
          alt=""
        />
      </div>
      <div style={{ marginTop: "60px" }}>
        {titleData.map((data, index) => {
          return (
            <div
              key={index}
              onClick={() => handleTitleClick(index)}
              className="leftScreen_reusable_eachTitleMainContainer"
            >
              <div className="leftScreen_reusable_eachTitleContainer">
                {index === selected ? (
                  <div>{data.selected_icon}</div>
                ) : (
                  <div>{data.unselected_icon}</div>
                )}
                <div
                  className={`${
                    index === selected
                      ? "leftScreen_reusable_eachTitle_title_selected"
                      : "leftScreen_reusable_eachTitle_title_unselected"
                  }`}
                >
                  <h3>{data.title}</h3>
                </div>
              </div>
              {index === selected && (
                <div>
                  <div
                    style={{
                      width: "5px",
                      height: "20px",
                      backgroundColor: "#FF3131",
                    }}
                  />
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default LeftReusableScreen;
