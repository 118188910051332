export const privacy_policy_details = {
  title: "Privacy Policy",
  date: "Last updated on 1st September, 2024",
  subtext:
    "At Acetechies Solutions, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, and protect the personal information you provide to us when using our website.",
  details: [
    {
      title: "Information We Collect",
      points: [
        "Personal information (name, email address, phone number) when you fill out contact forms or make inquiries.",
        "Usage data, including your IP address, browser type, and how you use our site.",
      ],
    },
    {
      title: "How We Use Your Information",
      points: [
        "Responding to your inquiries and providing customer support.",
        "Improving our website and services.",
        "Sending you marketing communications if you have opted in.",
      ],
    },
    {
      title: "Data Sharing and Security",
      points: [
        "We do not share your personal information with third parties unless required by law. We use industry-standard security measures to protect your data.",
      ],
    },
  ],
};

export const terms_use_details = {
  title: "Terms of Use",
  date: "Last updated on 1st September, 2024",
  subtext:
    "Welcome to Acetechies Solutions. By using our website, you agree to the following terms and conditions.",
  details: [
    {
      title: "Acceptance of Terms",
      points: [
        "By accessing and using our website, you agree to be bound by these Terms of Use. If you do not agree with these terms, please do not use our website.",
      ],
    },
    {
      title: "Use of the Website",
      points: [
        "You agree to use the website in compliance with all applicable laws and regulations.",
        "You must not engage in any activity that may disrupt or interfere with the functioning of the website.",
      ],
    },
    {
      title: "Intellectual Property",
      points: [
        "All content on this website, including text, images, logos, and designs, is the intellectual property of Acetechies Solutions unless otherwise noted. You may not use or reproduce any content without our permission.",
      ],
    },
    {
      title: "Limitation of Liability",
      points: [
        "We are not liable for any damages arising from your use of the website or from any information provided on the site.",
      ],
    },
    {
      title: "Changes to Terms",
      points: [
        "We reserve the right to update or modify these Terms of Use at any time without prior notice. Your continued use of the website after changes are posted constitutes acceptance of the new terms.",
      ],
    },
  ],
};

export const cancellation_refund_details = {
  title: "Refund Policy",
  date: "",
  subtext:
    "At Acetechies Solutions, we strive to provide the best possible service. However, please note that all sales are final, and we do not issue any refunds once a service has been completed. Our policy is as follows:",
  details: [
    {
      title: "Refunds",
      points: [
        "No refunds will be issued for any services.",
        "By using our services, you acknowledge and agree to this no-refund policy.",
      ],
    },
    {
      title: "Contact Support",
      points: [
        "For any questions or concerns, please contact our support team at support@acetechies.co.in.",
      ],
    },
  ],
};
