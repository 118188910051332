export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_RESET = "USER_REGISTER_RESET";

export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_RESET = "USER_LOGIN_RESET";

export const ADD_IS_SMALL_SCREEN = "ADD_IS_SMALL_SCREEN";
export const REDIRECT_TO_HOME_SCREEN = "REDIRECT_TO_HOME_SCREEN";

export const USER_LOGOUT = "USER_LOGOUT";
