import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Drawer,
  Popover,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  stepConnectorClasses,
  styled,
} from "@mui/material";
import "./MyMobileStepper.css";
import { useDispatch, useSelector } from "react-redux";
import HelpIcon from "@mui/icons-material/Help";
import GitHubIcon from "@mui/icons-material/GitHub";
import EmailIcon from "@mui/icons-material/Email";
import { MoneySigns, StringConstants } from "../../../utils/constants";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Carousel } from "react-responsive-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  certificateProblemStatementSelectedAction,
  getCertificateAction,
} from "../../../actions/Education/certificateActions";
import CardMembershipIcon from "@mui/icons-material/CardMembership";
import QuizIcon from "@mui/icons-material/Quiz";
import { MyConversion } from "../../../utils/conversion";

function MyMobileStepper() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userLogin);
  const { certificates } = useSelector((state) => state.getCertificateStore);
  const { problemStatement } = useSelector(
    (state) => state.certificateProblemStatements
  );
  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: "calc(-50% + 16px)",
      right: "calc(50% + 16px)",
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#FF3131",
        color: "#FF3131",
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: "#FF3131",
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor:
        theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }));
  const url = window.location.href.split("/");
  useEffect(() => {
    dispatch(
      certificateProblemStatementSelectedAction(
        certificates?.problemStatement[0],
        1
      )
    );
  }, [dispatch, certificates]);
  useEffect(() => {
    dispatch(getCertificateAction(url[4], url[5]));
  }, []);
  const [activeStep, setActiveStep] = useState(0);
  const next = () => {
    setActiveStep(activeStep + 1);
  };
  const prev = () => {
    setActiveStep(activeStep - 1);
  };

  const getWelcomeScreenPoints = (icon, index, point) => {
    return (
      <div className="mobile_stepper_welcomeScreenPoints_container">
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon}
          <h4
            style={{
              color: "#fff",
              margin: "0px",
              fontFamily: "sans-serif",
            }}
          >
            Step {index}:
          </h4>
        </div>

        <h3
          style={{
            color: "#fff",
            fontSize: "3vw",
            lineHeight: "5vw",
            fontWeight: "500",
          }}
        >
          {point}
        </h3>
      </div>
    );
  };

  const getWelcomeScreen = () => {
    return (
      <div style={{ overflow: "auto", height: "80vh", marginTop: "140px" }}>
        <div>
          <h2>
            Welcome,{" "}
            <span
              style={{
                color: "#FF3131",
                fontSize: "2rem",
                fontWeight: "800",
              }}
            >
              {userInfo?.firstName}
            </span>
          </h2>
        </div>
        <div>
          <div>
            <h4>Next Steps to Follow:</h4>
          </div>
          {[1, 2, 3].map((e, index) => {
            if (index === 0) {
              return getWelcomeScreenPoints(
                <HelpIcon
                  style={{
                    color: "#fff",
                    marginRight: "5px",
                  }}
                />,
                e,
                StringConstants.stepperStep1String
              );
            } else if (index === 1) {
              return getWelcomeScreenPoints(
                <GitHubIcon
                  style={{
                    color: "#fff",
                    marginRight: "5px",
                  }}
                />,
                e,
                StringConstants.stepperStep2String
              );
            } else {
              return getWelcomeScreenPoints(
                <EmailIcon
                  style={{
                    color: "#fff",
                    marginRight: "5px",
                  }}
                />,
                e,
                StringConstants.stepperStep3String
              );
            }
          })}
          <div style={{ marginTop: "50px" }} />
        </div>
      </div>
    );
  };

  ///Problem Statement screen////////
  const [expanded, setExpanded] = useState("panel1");
  const [isChecked, setIsChecked] = useState("problemStatement1");
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  const handleCheckboxChange = (val, index) => {
    setIsChecked(`problemStatement${index}`);
    dispatch(certificateProblemStatementSelectedAction(val, index));
  };
  const problemStatementScreen = () => {
    return (
      <div className="mobile_stepper_main_accordian_container">
        {certificates?.problemStatement?.map((e, index) => {
          index = index + 1;
          return (
            <Accordion
              expanded={expanded === `panel${index}`}
              onChange={handleChange(`panel${index}`)}
              sx={{
                backgroundColor: "#fffcf3",
                margin: "20px 0px",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
              >
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div>
                    <Checkbox
                      checked={isChecked === `problemStatement${index}`}
                      onChange={() => handleCheckboxChange(e, index)}
                      sx={{
                        color: "red",
                        "&.Mui-checked": {
                          color: "red",
                        },
                      }}
                    />
                  </div>
                  <div>
                    <h2
                      style={{
                        margin: "0px",
                        fontSize: "18px",
                        fontFamily: "sans-serif",
                      }}
                    >
                      Problem Statement {index}
                    </h2>
                  </div>
                </div>
              </AccordionSummary>
              <AccordionDetails>
                <div>
                  <div>
                    <h3 style={{ margin: "0px" }}>Question:</h3>
                    <p style={{ fontWeight: "600" }}>{e?.question}</p>
                  </div>
                  {e?.requirements?.map((requirement) => {
                    return <div>{getStarPoints(requirement)}</div>;
                  })}

                  <div>
                    <Carousel
                      infiniteLoop={e?.image?.length > 1}
                      showArrows={true}
                      showIndicators={false}
                      showThumbs={false}
                      swipeScrollTolerance={30}
                      preventMovementUntilSwipeScrollTolerance={true}
                      renderArrowNext={(clickHandler, hasPrev) =>
                        rightArrow(clickHandler)
                      }
                      renderArrowPrev={(clickHandler, hasNext) =>
                        leftArrow(clickHandler)
                      }
                    >
                      {e?.image?.map((eachImage, index) => {
                        return (
                          <div key={index} style={{ minWidth: "110px" }}>
                            <img width={650} src={eachImage} alt="" />;
                          </div>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    );
  };

  const rightArrow = (clickHandler) => {
    return (
      <div
        onClick={clickHandler}
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: "#222",
          position: "absolute",
          zIndex: "100",
          top: "50%",
          right: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArrowForwardIosIcon
          style={{
            fontSize: "16px",
            color: "#fff",
          }}
        />
      </div>
    );
  };

  const leftArrow = (clickHandler) => {
    return (
      <div
        onClick={clickHandler}
        style={{
          width: "30px",
          height: "30px",
          borderRadius: "50%",
          backgroundColor: "#222",
          position: "absolute",
          zIndex: "100",
          top: "50%",
          left: "0px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ArrowBackIosNewIcon
          style={{
            fontSize: "16px",
            color: "#fff",
          }}
        />
      </div>
    );
  };

  const getStarPoints = (title, index) => {
    return (
      <div key={index} style={{ display: "flex", alignItems: "center" }}>
        <StarRateIcon style={{ color: "#FF416C", fontSize: "18px" }} />
        <span
          style={{
            margin: "10px 10px",
            fontFamily: "sans-serif",
            fontSize: "16px",
          }}
        >
          {title}
        </span>
      </div>
    );
  };
  ///paymentScreen
  const [summaryCertificateName, setSummaryCertificateName] = useState({
    certificate_type: certificates?.certificate_type,
    certificate_category: certificates?.certificate_category,
  });
  useEffect(() => {
    var conversion = new MyConversion();
    var certificateType = conversion.toCamelCase(
      certificates?.certificate_type
    );
    var certificateCategory = conversion.toCamelCase(
      certificates?.certificate_category
    );

    setSummaryCertificateName({
      certificate_type: certificateType,
      certificate_category: certificateCategory,
    });
  }, []);
  const getPaymentScreen = () => {
    return (
      <div style={{ marginTop: "160px" }}>
        <div className="myMobileStepper_summary_title_container">
          <div className="myMobileStepper_summary_title">
            <div className="myMobileStepper_summary_title_icon">
              <CardMembershipIcon style={{ color: "#fff" }} />
            </div>
            <div>
              <div>
                <p>Certification</p>
              </div>
              <h2>
                <span style={{ color: "#FF3131" }}>
                  {" "}
                  {summaryCertificateName?.certificate_type}{" "}
                  {summaryCertificateName?.certificate_category} Internship{" "}
                </span>{" "}
                Certification
              </h2>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <svg width="90" height="70">
            <line
              x1="50"
              y1="0"
              x2="50"
              y2="100"
              stroke="lightgrey"
              strokeDasharray="5, 5"
            />
          </svg>
        </div>
        <div className="myMobileStepper_summary_problemStatement_main_container">
          <div className="myMobileStepper_summary_problemStatement_container">
            <div className="myMobileStepper_summary_problemStatement_title">
              <div className="myMobileStepper_summary_title_icon">
                <QuizIcon style={{ color: "#fff" }} />
              </div>
              <div>
                <p>Statement Selected:</p>
                <div>
                  <QuizIcon style={{ fontSize: "18px" }} />
                  <h2>Problem Statement {problemStatement?.index}</h2>
                </div>
              </div>
            </div>
            <div className="myMobileStepper_summary_problemStatement_main_statement">
              <p>Problem Details:</p>
              <h4>{problemStatement?.selectedProblemStatement?.question}</h4>
            </div>
            <div className="myMobileStepper_summary_problemStatement_image_container">
              <p>Results:</p>
              {problemStatement?.selectedProblemStatement?.image?.map(
                (e, index) => {
                  if (index < 3) {
                    return <img width={100} src={e} alt="" />;
                  } else {
                    return;
                  }
                }
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const steps = [
    {
      title: "Welcome",
      content: getWelcomeScreen(),
    },
    {
      title: "Problem Statement",
      content: problemStatementScreen(),
    },
    {
      title: "Summary",
      content: getPaymentScreen(),
    },
  ];

  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = (newOpen, reason) => () => {
    setDrawerOpen(newOpen);
  };

  const handleGoClicked = () => {
    setDrawerOpen(true);
    setTermsConditions(false);
  };

  const [termsConditions, setTermsConditions] = useState(false);
  const handleEnrollClick = () => {
    if (termsConditions === false) {
      setOpen(true);
    }
  };
  const [open, setOpen] = useState(false);
  const paymentPageScreen = () => {
    return (
      <div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <div onClick={() => setDrawerOpen(false)}>
            <ArrowBackIcon />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h3>Payment Screen</h3>
          </div>
        </div>
        <div style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ fontWeight: "600" }}>Subtotal</h3>
            <h2
              style={{
                fontFamily: "sans-serif",
                fontWeight: "700",
                fontSize: "23px",
              }}
            >
              {MoneySigns.indianRupees}500
            </h2>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h3 style={{ fontWeight: "600", margin: "0px" }}>
              Discount{" "}
              <span
                style={{
                  fontFamily: "sans-serif",
                  fontSize: "14px",
                  color: "green",
                }}
              >
                (100% applied)
              </span>
            </h3>
            <h2
              style={{
                fontFamily: "sans-serif",
                fontWeight: "700",
                fontSize: "23px",
                color: "red",
                margin: "0px",
              }}
            >
              - {MoneySigns.indianRupees}500
            </h2>
          </div>
          <hr />
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h2 style={{ fontWeight: "700", margin: "0px" }}>Total</h2>
            <h1
              style={{
                fontFamily: "sans-serif",
                fontWeight: "700",
                fontSize: "28px",
                margin: "10px 0px",
              }}
            >
              {MoneySigns.indianRupees}0
            </h1>
          </div>
          <div className="mobile_stepper_coupon">
            <p style={{ fontWeight: "600" }}>Coupon Code</p>
            <input type="text" placeholder="FIRST" />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <Checkbox
                style={{ color: "#FF3131" }}
                onChange={(e) => {
                  setTermsConditions(e.target.checked);
                  setOpen(false);
                }}
              />
              <p style={{ fontSize: "16px" }}>
                I agree to{" "}
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  Terms & Conditions
                </span>
              </p>
            </div>
            {open && <div>{content}</div>}
          </div>

          <div className="mobile_stepper_enrollNowButton">
            <Button onClick={() => handleEnrollClick()}>
              Enroll Now for{" "}
              <h2 style={{ margin: "0px 10px" }}>
                {" "}
                {MoneySigns.indianRupees}0
              </h2>
            </Button>
          </div>
        </div>
      </div>
    );
  };
  const content = (
    <div>
      <span style={{ color: "red" }}>*Please accept terms and conditons.</span>
    </div>
  );
  return (
    <div>
      <div>
        <Stepper
          activeStep={activeStep}
          connector={<QontoConnector />}
          alternativeLabel
          style={{
            position: "fixed",
            top: "50px",
            zIndex: "140",
            backgroundColor: "#fffcf3",
            width: "100%",
            paddingTop: "20px",
            paddingBottom: "10px",
          }}
        >
          {steps.map((step, index) => {
            return (
              <Step
                key={index}
                sx={{
                  "& .MuiStepLabel-root .Mui-completed": {
                    color: "#FF3131", // circle color (COMPLETED)
                  },
                  "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                    {
                      color: "#FF3131", // Just text label (COMPLETED)
                    },
                  "& .MuiStepLabel-root .Mui-active": {
                    color: "#FF3131", // circle color (ACTIVE)
                  },
                  "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                    {
                      color: "#FF3131", // Just text label (ACTIVE)
                    },
                  "& .MuiStepLabel-root .Mui-active .MuiStepIcon-text": {
                    fill: "white", // circle's number (ACTIVE)
                  },
                }}
              >
                <StepLabel>{step.title}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
        <div style={{ padding: "10px" }}>{steps[activeStep].content}</div>
      </div>
      <div className="mobile_stepper_bottom_container">
        {activeStep > 0 && activeStep < 3 ? (
          <Button className="mobile_stepper_prev_button" onClick={prev}>
            Back
          </Button>
        ) : (
          <div />
        )}
        {activeStep <= 1 ? (
          <Button className="mobile_stepper_next_button" onClick={next}>
            Next
          </Button>
        ) : (
          <Button
            className="mobile_stepper_next_button"
            onClick={handleGoClicked}
          >
            Finish
          </Button>
        )}
      </div>
      <div>
        <Drawer
          PaperProps={{
            sx: {
              height: "75%",
              backgroundColor: "#fffcf3",
              borderTopLeftRadius: "22px",
              borderTopRightRadius: "22px",
            },
          }}
          anchor={"bottom"}
          open={drawerOpen}
          onClose={(ev, reason) => toggleDrawer(false, reason)}
        >
          <div style={{ padding: "10px" }}>{paymentPageScreen()}</div>
        </Drawer>
      </div>
    </div>
  );
}

export default MyMobileStepper;
