import { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import EducationHomePage from "./screens/education/EducationHomePage";
import { useDispatch, useSelector } from "react-redux";
import { userLoginAction } from "./actions/userActions";
import AuthenticationScreen from "./screens/education/AuthenticationScreen";
import RegistrationScreen from "./screens/education/RegistrationScreen";
import Courses from "./screens/education/Courses";
import CoursesMobileView from "./screens/education/CoursesMobileView";
import useWindowResize from "./utils/WindowResize";
import { isMobile } from "react-device-detect";
import CertificatesScreen from "./screens/education/CertificatesScreen";
import CertificateMobileScreen from "./screens/education/CertificateMobileScreen";
import CertificationErollment from "./screens/education/CertificationErollment";
import ScrollToTop from "./ScrollToTop";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import ProtectedRoute from "./screens/education/reusables/ProtectedRoute";
import DashboardScreen from "./screens/education/Dashboard/DashboardScreen";
import UploadSolution from "./screens/education/Dashboard/UploadSolution";
import DashboardCertificates from "./screens/education/Dashboard/DashboardCertificates";
import DashboardCourses from "./screens/education/Dashboard/DashboardCourses";
import Support from "./screens/education/Dashboard/Support";
import SuperAdminPanel from "./screens/education/Admin-Panels/SuperAdminPanel";
import AdminProtectedRoute from "./screens/education/reusables/AdminProtectedRoute";
import WebinarEventsMobile from "./screens/education/WebinarEvents/WebinarEventsMobile";
import WebinarEvents from "./screens/education/WebinarEvents/WebinarEvents";
import MobileAppPage from "./screens/education/mobile-app-page/mobile-app-page";
import PrivacyPolicy from "./screens/policies/PrivacyPolicy";
import {
  cancellation_refund_details,
  privacy_policy_details,
  terms_use_details,
} from "./screens/policies/policy_details";
import ContactUs from "./screens/policies/ContactUs";

function App() {
  const [isUserLoggedIn, setisUserLoggedIn] = useState(
    localStorage.getItem("acetechies_is_user_logged_in")
  );

  const { redirectUrl } = useSelector((state) => state.redirectUrlStore);
  const isSmallScreen = useWindowResize();

  const { userInfo } = useSelector((state) => state.userLogin);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(userLoginAction());
  }, [dispatch]);

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/contact">
            <ContactUs />
          </Route>
          <Route path="/cancellation-refund">
            <PrivacyPolicy policyData={cancellation_refund_details} />
          </Route>
          <Route path="/terms">
            <PrivacyPolicy policyData={terms_use_details} />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy policyData={privacy_policy_details} />
          </Route>
          <Route path="/dashboard/superadminpanel">
            <AdminProtectedRoute component={SuperAdminPanel} />
          </Route>
          {isSmallScreen || isMobile ? (
            <Route path="/events">
              <WebinarEventsMobile />
            </Route>
          ) : (
            <Route path="/events">
              <WebinarEvents />
            </Route>
          )}
          <Route path="/dashboard/support">
            <ProtectedRoute component={Support} />
          </Route>
          <Route path="/dashboard/courses">
            <ProtectedRoute component={DashboardCourses} />
          </Route>
          <Route path="/dashboard/certificates">
            <ProtectedRoute component={DashboardCertificates} />
          </Route>
          <Route path="/dashboard/uploadsolution">
            <ProtectedRoute component={UploadSolution} />
          </Route>
          <Route path="/dashboard">
            <ProtectedRoute component={DashboardScreen} />
          </Route>
          <Route path="/certificates/:tech/:tag">
            <ProtectedRoute component={CertificationErollment} />
          </Route>
          <Route path="/certificates">
            {isSmallScreen || isMobile ? (
              <CertificateMobileScreen />
            ) : (
              <CertificatesScreen />
            )}
          </Route>

          <Route path="/courses">
            {isSmallScreen || isMobile ? <CoursesMobileView /> : <Courses />}
          </Route>
          <Route path="/signup">
            {userInfo || isUserLoggedIn ? (
              <Redirect to={`${redirectUrl.url}`} />
            ) : (
              <RegistrationScreen />
            )}
          </Route>
          <Route path="/login">
            {userInfo || isUserLoggedIn ? (
              <Redirect to={`${redirectUrl.url}`} />
            ) : (
              <AuthenticationScreen />
            )}
          </Route>
          <Route path="/mobile-app-page">
            <MobileAppPage />
          </Route>
          <Route path="/">
            <EducationHomePage />
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
