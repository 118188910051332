import React from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

function MobileCarouselEffect({
  children,
  swipeScrollTolerance,
  transitionTime,
  interval,
}) {
  return (
    <Carousel
      infiniteLoop
      autoPlay
      centerMode
      showArrows={false}
      showIndicators={false}
      showStatusBar={false}
      showStatus={false}
      showThumbs={false}
      swipeScrollTolerance={swipeScrollTolerance ?? 30}
      preventMovementUntilSwipeScrollTolerance={true}
      transitionTime={transitionTime ?? 1500}
      interval={interval ?? 10000}
    >
      {children}
    </Carousel>
  );
}

export default MobileCarouselEffect;
