export const SELECTED_EXPLORE_COURSE = "SELECTED_EXPLORE_COURSE";
export const SELECTED_COURSE_FROM_LIST = "SELECTED_COURSE_FROM_LIST";
export const GET_SELECTED_COURSE_DETAILS_PROGRESS =
  "GET_SELECTED_COURSE_DETAILS_PROGRESS";
export const GET_SELECTED_COURSE_DETAILS_SUCCESS =
  "GET_SELECTED_COURSE_DETAILS_SUCCESS";
export const GET_SELECTED_COURSE_DETAILS_ERROR =
  "GET_SELECTED_COURSE_DETAILS_ERROR";

export const GET_SUGGESTED_COURSES_PROGRESS = "GET_SUGGESTED_COURSES_PROGRESS";
export const GET_SUGGESTED_COURESES_SUCCESS = "GET_SUGGESTED_COURESES_SUCCESS";
export const GET_SUGGESTED_COURESES_FAIL = "GET_SUGGESTED_COURESES_FAIL";

export const COURSE_PAYMENT_INITIATED = "COURSE_PAYMENT_INITIATED";
export const COURSE_PAYMENT_SUCCESS = "COURSE_PAYMENT_SUCCESS";
export const COURSE_PAYMENT_FAILED = "COURSE_PAYMENT_FAILED";