import axios from "axios";
import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../constants/User/userConstants";
import { APIRequestURI } from "../utils/api_request_uri";
import { decryptText } from "../utils/decrypt_cookie";
import { jwtDecode } from "jwt-decode";

export const userRegisterAction =
  (firstName, lastName, email, phoneNumber, password, country) =>
  async (dispatch) => {
    try {
      dispatch({ type: USER_REGISTER_REQUEST });
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.post(
        `${URI}/api/register`,
        { firstName, lastName, email, phoneNumber, password, country },
        config
      );
      const decryptedData = decryptText(data);
      dispatch({ type: USER_REGISTER_SUCCESS, payload: decryptedData });

      dispatch({ type: USER_LOGIN_SUCCESS, payload: decryptedData });

      localStorage.setItem("acetechies_logged_in_user", data);
    } catch (error) {
      dispatch({
        type: USER_REGISTER_FAIL,
        payload: {
          status: error.response.status,
          message: error.response.statusText,
          data: error.response.data,
        },
      });
    }
  };

export const googleLoginAction = (encodedToken) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const URI = new APIRequestURI().getBaseURI();
    const { data } = await axios.post(
      `${URI}/api/google-login`,
      { encodedToken },
      config
    );
    const decryptedData = decryptText(data);
    dispatch({ type: USER_REGISTER_SUCCESS, payload: decryptedData });

    dispatch({ type: USER_LOGIN_SUCCESS, payload: decryptedData });

    localStorage.setItem("acetechies_logged_in_user", data);
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const userLoginAction = (email, password) => async (dispatch) => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const isUserAlreadyLoggedIn = localStorage.getItem(
      "acetechies_logged_in_user"
    );
    if (isUserAlreadyLoggedIn) {
      const decrypUserDetails = decryptText(isUserAlreadyLoggedIn);
      const decodedToken = jwtDecode(decrypUserDetails.token);
      const isTokenExpired = decodedToken.exp * 1000 < Date.now();
      if (!isTokenExpired) {
        dispatch({
          type: USER_LOGIN_SUCCESS,
          payload: decrypUserDetails,
        });
        return;
      } else {
        localStorage.removeItem("acetechies_logged_in_user");
        return;
      }
    } else {
      dispatch({ type: USER_LOGIN_REQUEST });
    }
    const URI = new APIRequestURI().getBaseURI();
    const { data } = await axios.post(
      `${URI}/api/login`,
      { email, password },
      config
    );
    const decryptedData = decryptText(data);
    dispatch({ type: USER_LOGIN_SUCCESS, payload: decryptedData });

    localStorage.setItem("acetechies_logged_in_user", data);
    dispatch({ type: USER_LOGIN_RESET });
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload: {
        status: error.response.status,
        message: error.response.statusText,
        data: error.response.data,
      },
    });
  }
};

export const userLogoutAction = () => (dispatch) => {
  dispatch({ type: USER_LOGOUT });
  localStorage.removeItem("acetechies_logged_in_user");
};

export const setCsrf = () => async () => {
  try {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const URI = new APIRequestURI().getBaseURI();
    await axios.get(`${URI}/csrf-token`, config, { withCredentials: true });
  } catch (error) {
    // console.log(error);
  }
};

export const redirectToPageAction = (type, val) => (dispatch) => {
  dispatch({ type: type, payload: val });
};
