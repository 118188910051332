import React from "react";
import "./LoadingAnimation.css";

function LoadingAnimation() {
  return (
    <div className="loading_animation_details">
      <video
        autoPlay={true}
        muted={true}
        loop={true}
        src="https://res.cloudinary.com/cqn/video/upload/v1717425358/modal_download_icon_gcpxjv.mp4"
      />
      <h5>Gathering the event details. Please Wait.....</h5>
    </div>
  );
}

export default LoadingAnimation;
