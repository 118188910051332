import axios from "axios";
import {
  GET_DETAILS_FOR_EVENT,
  GET_MY_REGISTERED_EVENTS_FAIL,
  GET_MY_REGISTERED_EVENTS_LOADING,
  GET_MY_REGISTERED_EVENTS_SUCCESS,
  REGISTER_USER_FOR_WEBINAR_FAIL,
  REGISTER_USER_FOR_WEBINAR_LOADING,
  REGISTER_USER_FOR_WEBINAR_RESET,
  REGISTER_USER_FOR_WEBINAR_SUCCESS,
  WEBINAR_EVENT_ALL_FAIL,
  WEBINAR_EVENT_ALL_LOADING,
  WEBINAR_EVENT_ALL_SUCCESS,
  WEBINAR_EVENT_POPULAR,
  WEBINAR_EVENT_TOP_RESULT,
  WEBINAR_EVENT_UPCOMING,
} from "../../constants/WebinarEvents/webinarEventConstants";
import { APIRequestURI } from "../../utils/api_request_uri";
import { decryptText } from "../../utils/decrypt_cookie";

export const getWebinarEventAction = (status) => async (dispatch) => {
  try {
    dispatch({ type: WEBINAR_EVENT_ALL_LOADING });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const URI = new APIRequestURI().getBaseURI();
    const { data } = await axios.post(`${URI}/api/events/`, { status }, config);
    dispatch({ type: WEBINAR_EVENT_ALL_SUCCESS, payload: data });

    const popularResult = showPopularWebinar(data);
    const topResults = data.filter((x) => x.popularityScore > 3);
    const upcomingResults = data.filter((x) => x.status === "upcoming");

    dispatch({ type: WEBINAR_EVENT_TOP_RESULT, payload: topResults });
    dispatch({ type: WEBINAR_EVENT_UPCOMING, payload: upcomingResults });

    dispatch({ type: WEBINAR_EVENT_POPULAR, payload: popularResult });
  } catch (error) {
    dispatch({ type: WEBINAR_EVENT_ALL_FAIL, payload: error.message });
  }
};

const showPopularWebinar = (data) => {
  const isUserAlreadyLoggedIn = localStorage.getItem(
    "acetechies_logged_in_user"
  );
  if (isUserAlreadyLoggedIn) {
    const decrypUserDetails = decryptText(isUserAlreadyLoggedIn);
    const upcomingResults = data.find((x) => {
      return !decrypUserDetails?.registered_webinar_events.includes(x._id);
    });

    return upcomingResults;
  }
  return data.find((x) => x.popularityScore > 7);
};

export const registerUserForWebinarAction =
  (eventId) => async (dispatch, getState) => {
    try {
      dispatch({ type: REGISTER_USER_FOR_WEBINAR_LOADING });
      const {
        userLogin: { userInfo },
      } = getState();
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${userInfo?.token}`,
        },
      };
      const URI = new APIRequestURI().getBaseURI();
      const { data } = await axios.post(
        `${URI}/api/events/userregistration`,
        { eventId },
        config
      );
      dispatch({ type: REGISTER_USER_FOR_WEBINAR_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: REGISTER_USER_FOR_WEBINAR_FAIL,
        payload: error.message,
      });
    }
  };

export const resetRegisterUserStateAction = () => (dispatch) => {
  dispatch({ type: REGISTER_USER_FOR_WEBINAR_RESET });
};

export const webinarEventComingFromAction = (item) => (dispatch) => {
  dispatch({
    type: GET_DETAILS_FOR_EVENT,
    payload: { url: "/events", item: item },
  });
};

export const getMyRegisteredEventsAction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: GET_MY_REGISTERED_EVENTS_LOADING });
    const {
      userLogin: { userInfo },
    } = getState();

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userInfo?.token}`,
      },
    };
    const URI = new APIRequestURI().getBaseURI();
    const { data } = await axios.get(
      `${URI}/api/events/getmyregistration`,
      config
    );

    dispatch({ type: GET_MY_REGISTERED_EVENTS_SUCCESS, payload: data });
  } catch (error) {
    console.log(error);
    dispatch({ type: GET_MY_REGISTERED_EVENTS_FAIL, payload: error.message });
  }
};
