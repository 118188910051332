import {
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_RESET,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_RESET,
  USER_REGISTER_SUCCESS,
} from "../constants/User/userConstants";

export const userRegisterReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_REGISTER_REQUEST:
      return {
        loading: true,
        success: false,
      };
    case USER_REGISTER_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload,
      };
    case USER_REGISTER_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    case USER_REGISTER_RESET:
      return {};

    default:
      return state;
  }
};

export const userLoginReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_LOGIN_REQUEST:
      return {
        loading: true,
        success: false,
      };
    case USER_LOGIN_SUCCESS:
      return {
        loading: false,
        success: true,
        userInfo: action.payload,
      };

    case USER_LOGIN_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case USER_LOGIN_RESET:
      return {
        ...state,
        success: false,
      };
    case USER_LOGOUT:
      return {
        ...state,
        userInfo: null,
      };

    default:
      return state;
  }
};

// export const userLogoutReducer = (state = {}, action) => {
//   if (action.type === USER_LOGOUT) {
//     return {
//       ...state,
//       userInfo: null,
//     };
//   } else {
//     return state;
//   }
// };
