import React from "react";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { useDispatch } from "react-redux";
import { userLogoutAction } from "../../../actions/userActions";
import { Avatar, Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function ProfileDropdown({ userImage, userName }) {
  const dispatch = useDispatch();
  const handleLogoutClick = () => {
    dispatch(userLogoutAction());
  };
  const history = useHistory();

  const items = [
    {
      key: "1",
      label: <span>Profile</span>,
    },
    {
      key: "2",
      label: <span onClick={() => history.push("/dashboard")}>Dashboard</span>,
    },
    {
      key: "3",
      label: <span>Purchases</span>,
    },
    {
      key: "4",
      label: (
        <Button
          style={{
            width: "140px",
            textTransform: "inherit",
            backgroundColor: "#FF3131",
            color: "#fff",
          }}
          onClick={handleLogoutClick}
        >
          Logout
        </Button>
      ),
    },
  ];
  return (
    <Dropdown
      menu={{
        items,
        style: {
          backgroundColor: "#fffcf3",
          padding: "10px 5px",
        },
      }}
      trigger={["click"]}
    >
      <div style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
        <div
          style={{ display: "flex", alignItems: "center", marginRight: "20px" }}
        >
          <Avatar src={userImage} />
          <span
            style={{
              marginLeft: "10px",
              fontSize: "14px",
              fontWeight: "700",
              color: "black",
            }}
          >
            {userName}
          </span>
        </div>
        <DownOutlined style={{ fontSize: "10px" }} />
      </div>
    </Dropdown>
  );
}

export default ProfileDropdown;
