import React, { useEffect, useState } from "react";
import "./WebinarEventsMobile.css";
import EducationTopBar from "../topbar/EducationTopBar";
import MobileCarouselEffect from "../reusables/MobileCarouselEffect";
import YouTube from "react-youtube";
import { Button } from "@mui/material";
import WebinarDrawer from "./WebinarDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyRegisteredEventsAction,
  getWebinarEventAction,
} from "../../../actions/WebinarEvent/webinarEventAction";
import { redirectToPageAction } from "../../../actions/userActions";
import { REDIRECT_TO_HOME_SCREEN } from "../../../constants/User/userConstants";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import MyReactHelmet from "../reusables/react_helmet";

function WebinarEventsMobile() {
  const [open, setOpen] = useState({
    isOpen: false,
    isAlreadyRegistered: false,
  });
  const [selectedData, setSelectedData] = useState(null);

  const { redirectUrl } = useSelector((state) => state.redirectUrlStore);
  const { userInfo } = useSelector((state) => state.userLogin);
  const { webinar_events, loading, success, error } = useSelector(
    (state) => state.allWebinarEventStore
  );
  const { topResults, upcomingEvent } = useSelector(
    (state) => state.upcomingEventStore
  );
  const { webinarDetails } = useSelector(
    (state) => state.webinarEventComingFromStore
  );
  const { myEventRegistrations, loading: myEventLoading } = useSelector(
    (state) => state.getMyRegisteredEventsStore
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (!webinar_events) dispatch(getWebinarEventAction("upcoming"));
  }, []);
  useEffect(() => {
    if (webinarDetails !== undefined && webinarDetails !== null) {
      const alreadyRegistered = validateIfRegistered([webinarDetails], 0);
      setOpen({ isOpen: true, isAlreadyRegistered: alreadyRegistered });
      setSelectedData(webinarDetails.item);
    }
  }, []);
  useEffect(() => {
    if (
      redirectUrl !== undefined &&
      redirectUrl !== null &&
      redirectUrl?.url === "/events"
    ) {
      const alreadyRegistered = validateIfRegistered([redirectUrl.item], 0);
      setOpen({ isOpen: true, isAlreadyRegistered: alreadyRegistered });
      setSelectedData(redirectUrl.item);
      dispatch(redirectToPageAction(REDIRECT_TO_HOME_SCREEN, { url: "/" }));
    }
  }, []);
  useEffect(() => {
    if (userInfo) {
      dispatch(getMyRegisteredEventsAction());
    }
  }, [userInfo, dispatch]);
  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };
  const validateIfRegistered = (data, index) => {
    return myEventRegistrations?.registered_webinar_events?.some((item) => {
      if (item?._id === data[index]._id) {
        return true;
      }
      return false;
    });
  };
  const handleLearnMore = (data, index, isRegistered) => {
    const alreadyRegistered = validateIfRegistered(data, index);

    setOpen({ isOpen: true, isAlreadyRegistered: alreadyRegistered });
    setSelectedData(data[index]);
  };
  const showCard = () => {
    return topResults?.map((data, index) => {
      return (
        <div
          key={index}
          style={{
            margin: "20px 5px",
            padding: "10px",

            borderRadius: "22px",
            backgroundColor: "#222",
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          }}
        >
          <div>
            <h3 style={{ fontSize: "16px", fontWeight: "800", color: "#fff" }}>
              {data?.title}
            </h3>
          </div>
          <div>
            <div className="webinar_events_youtube">
              {<YouTube videoId={data?.youtubeUrl} opts={opts} />}
            </div>
          </div>
          <div className="webinar_learn_more">
            <Button onClick={() => handleLearnMore(topResults, index)}>
              Learn More
            </Button>
          </div>
        </div>
      );
    });
  };
  const upcomingCard = (data, item, index, isRegistered) => {
    return (
      <div
        style={{
          border: "1px solid lightgrey",
          margin: "10px 10px 0px 0px",
          borderRadius: "12px",
          padding: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          minWidth: "280px",
        }}
      >
        <div>
          <div>
            <img
              style={{ objectFit: "contain" }}
              width={200}
              height={150}
              src={item?.image}
              alt=""
            />
          </div>
          <div style={{ width: "200px" }}>
            <h5
              style={{
                display: "-webkit-box",
                fontSize: "4vw",
                fontWeight: "700",
                margin: "5px 0px 10px 0px",
                textOverflow: "ellipsis",
                overflow: "hidden",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: "4",
                width: "200px",
              }}
            >
              {item?.title}
            </h5>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CalendarMonthIcon style={{ color: "#888", fontSize: "20px" }} />
              <span style={{ fontFamily: "sans-serif" }}>
                {item?.eventDate}
              </span>
            </div>
          </div>
        </div>
        <div
          style={{ marginTop: "30px" }}
          className="upcoming_webinar_event_button"
        >
          {isRegistered ? (
            <Button onClick={() => handleLearnMore(data, index, true)}>
              View Details
            </Button>
          ) : (
            <Button onClick={() => handleLearnMore(data, index, false)}>
              Learn More
            </Button>
          )}
        </div>
      </div>
    );
  };

  const topUpcomingEvents = () => {
    return (
      <div>
        <MobileCarouselEffect
          children={showCard()}
          swipeScrollTolerance={10}
          interval={6000}
          transitionTime={1200}
        />
      </div>
    );
  };

  const upcomingEvents = () => {
    return (
      <div style={{ padding: "10px" }}>
        <div>
          <h1 style={{ fontSize: "6.5vw", fontWeight: "800" }}>
            Upcoming Events
          </h1>
        </div>
        <div className="webinar_events_card_container">
          {upcomingEvent?.map((item, index) => {
            return upcomingCard(webinar_events, item, index);
          })}
        </div>
      </div>
    );
  };

  const myEvents = () => {
    return (
      <div style={{ padding: "10px" }}>
        <div>
          <h1 style={{ fontSize: "6.5vw", fontWeight: "800" }}>
            Registered Events
          </h1>
        </div>
        {myEventLoading ? (
          <Skeleton width={250} height={300} />
        ) : (
          <div className="webinar_events_card_container">
            {myEventRegistrations?.registered_webinar_events?.map(
              (item, index) => {
                return upcomingCard(
                  myEventRegistrations?.registered_webinar_events,
                  item,
                  index,
                  true
                );
              }
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div>
      <MyReactHelmet value={"Events"} />
      <EducationTopBar />
      <div style={{ marginTop: "70px" }}>{topUpcomingEvents()}</div>
      {myEventRegistrations?.registered_webinar_events?.length > 0 && (
        <div>{myEvents()}</div>
      )}

      <div>{upcomingEvents()}</div>
      <div>
        <WebinarDrawer open={open} setOpen={setOpen} data={selectedData} />
      </div>
    </div>
  );
}

export default WebinarEventsMobile;
