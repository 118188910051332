import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { convertToHoursAndMinutes } from "../../../utils/change_time_format";

export default function MyAccordian({
  courseDetails,
  openedAccordian,
  setOpenedAccordian,
}) {
  // const [openedAccordian, setOpenedAccordian] = React.useState([]);
  const _getCourseContentEachContainer = (title, time) => {
    time = convertToHoursAndMinutes(time);
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <PlayCircleIcon />
          <div style={{ marginLeft: "10px", width: "100%" }}>
            <h4>{title} </h4>
          </div>
        </div>
        <div>
          <h4 style={{ fontFamily: "sans-serif" }}>{time}</h4>
        </div>
      </div>
    );
  };
  const handleChange = (index) => {
    if (openedAccordian.includes(index)) {
      setOpenedAccordian(openedAccordian.filter((number) => number !== index));
    } else {
      setOpenedAccordian([...openedAccordian, index]);
    }
  };
  return (
    <div>
      {courseDetails?.course_details.map((course, index) => {
        return (
          <Accordion
            key={index}
            style={{ marginBottom: "20px" }}
            onChange={() => handleChange(index)}
            expanded={openedAccordian.includes(index)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
              style={{
                fontSize: "18px",
                color: "black",
                fontWeight: "700",
                fontFamily: "sans-serif",
              }}
            >
              {course.title}
            </AccordionSummary>
            <AccordionDetails>
              {course?.videos.map((video) => {
                return _getCourseContentEachContainer(
                  video?.title,
                  video?.video_time
                );
              })}
            </AccordionDetails>
          </Accordion>
        );
      })}
    </div>
  );
}
