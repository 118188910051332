import React, { useEffect, useState } from "react";
import "./WebinarEvents.css";
import { motion } from "framer-motion";
import MyReactHelmet from "../reusables/react_helmet";
import { ImageConstants, MoneySigns } from "../../../utils/constants";
import { Button, CircularProgress, Drawer } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  getMyRegisteredEventsAction,
  getWebinarEventAction,
  registerUserForWebinarAction,
  resetRegisterUserStateAction,
} from "../../../actions/WebinarEvent/webinarEventAction";
import EducationTopBar from "../topbar/EducationTopBar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import YouTube from "react-youtube";
import useCountdown from "../../../utils/countdown";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ScheduleIcon from "@mui/icons-material/Schedule";

const filtersData = [
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1716234767/2721264_hvluo8.png",
    title: "Frontend",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1716234776/5432506_meqjhk.png",
    title: "Backend",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1716234853/328-3284437_full-stack-development-responsive-web-transparent-icon-hd_wixx8m.png",
    title: "FullStack",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1716234875/3476346_y0jwja.png",
    title: "Interview Ready",
  },
  {
    src: "https://res.cloudinary.com/cqn/image/upload/v1716234890/6171743_fgrn2g.png",
    title: "Internship Projects",
  },
];

function WebinarEvents() {
  const { webinar_events } = useSelector((state) => state.allWebinarEventStore);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getWebinarEventAction("upcoming"));
  }, []);

  const [activeButton, setActiveButton] = useState(0);
  const [filteredData, setFilteredData] = useState([]);
  const getFilters = () => {
    return (
      <div className="webinar_filter_container">
        <div className="webinar_filter_container_data">
          {filtersData.map((data, index) => {
            return getEachFilter(data, index);
          })}
        </div>
      </div>
    );
  };

  const selectFilter = (index) => {
    setActiveButton(index);
  };

  useEffect(() => {
    let data = [];
    switch (activeButton) {
      case 0:
        data = webinar_events?.filter((e) => e.event_domain === "frontend");
        setFilteredData(data);
        break;
      case 1:
        data = webinar_events?.filter((e) => e.event_domain === "backend");
        setFilteredData(data);
        break;
      case 2:
        data = webinar_events?.filter((e) => e.event_domain === "fullstack");
        setFilteredData(data);
        break;
      case 3:
        data = webinar_events?.filter((e) => e.event_domain === "interview");
        setFilteredData(data);
        break;
      case 4:
        data = webinar_events?.filter((e) => e.event_domain === "internship");
        setFilteredData(data);
        break;

      default:
        break;
    }
  }, [activeButton, webinar_events]);

  const getEachFilter = (data, index) => {
    return (
      <div
        key={index}
        onClick={() => selectFilter(index)}
        style={{
          cursor: "pointer",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <div>
            <img width={50} src={data.src} alt="" />
          </div>
          <div>
            <h3>{data.title}</h3>
          </div>
        </div>

        <div>
          {activeButton === index && (
            <motion.div
              layoutId="underline"
              className="underline"
              initial={{ x: -100 }}
              animate={{
                x: 0,
              }}
              transition={{
                type: "spring",
                stiffness: 400,
                damping: 20,
              }}
            >
              <div
                style={{
                  height: "2px",
                  padding: "2px",
                  backgroundColor: "#222",
                }}
              />
            </motion.div>
          )}
        </div>
      </div>
    );
  };

  const [open, setOpen] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);

  const handleCardClick = (data) => {
    setOpen(true);
    setSelectedCard(data);
  };

  const eventCard = (data) => {
    return (
      <div
        key={data?._id}
        onClick={() => handleCardClick(data)}
        className="eventCard_main_container"
      >
        <div className="eventCard_image_container">
          <img src={data?.image} alt="" />
        </div>
        <div
          style={{
            padding: "0px 10px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "sans-serif",
                fontSize: "12px",
                backgroundColor: "#fff",
                borderRadius: "22px",
                color: "#FF3131",
                fontWeight: "600",
              }}
            >
              <p>{data?.eventDate}</p>
              <p style={{ margin: "0px 5px", fontSize: "16px" }}>•</p>
              <p>{data?.eventTime}</p>
            </div>
            <div>
              <h4
                style={{
                  margin: "0px",
                  fontWeight: "800",
                  fontSize: "16px",
                  lineHeight: "22px",
                }}
              >
                {data?.title}
              </h4>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "10px",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                backgroundColor: "rgb(255 95 95 / 14%)",
                padding: "5px 5px",
                borderRadius: "22px",
              }}
            >
              {data?.event_tech_stack?.map((e, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      backgroundColor: "#fff",
                      padding: "5px 8px",
                      margin: "0px 5px",
                      borderRadius: "22px",
                      color: "#FF3131",
                      fontSize: "12px",
                      fontWeight: "700",
                    }}
                  >
                    <span>{e}</span>
                  </div>
                );
              })}
            </div>
            <div className="view_details_button_container">
              <Button onClick={() => setOpen(true)}>View Details</Button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const timeRemaining = useCountdown();
  const { loading, success } = useSelector(
    (state) => state.registerUserForWebinarStore
  );

  const handleRegisterNow = () => {
    dispatch(registerUserForWebinarAction(selectedCard?._id));
  };

  useEffect(() => {
    setTimeout(() => {
      if (success) {
        setOpen(false);
        dispatch(resetRegisterUserStateAction());
        dispatch(getMyRegisteredEventsAction());
      }
    }, 1500);
  }, [success]);

  const showPriceSection = () => {
    return (
      <div
        style={{
          position: "absolute",
          bottom: "0px",
          boxShadow: "0 -2px 4px rgba(0,0,0,.08), 0 -4px 12px rgba(0,0,0,.08)",
          width: "100%",
          backgroundColor: "#fffcf3",
          zIndex: "10",
        }}
      >
        {open.isAlreadyRegistered ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div>
              <h3
                style={{
                  color: "#FF3131",
                  fontFamily: "sans-serif",
                  fontWeight: "800",
                  fontSize: "21px",
                }}
              >
                Already Registered
              </h3>
            </div>
          </div>
        ) : (
          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "5px 5px",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <h3
                  style={{
                    fontFamily: "sans-serif",
                    textDecoration: "line-through",
                    margin: "10px 0px",
                  }}
                >
                  {MoneySigns.indianRupees}
                  {selectedCard?.originalPrice}
                </h3>
                <div
                  style={{
                    margin: "0px 10px",
                    backgroundColor: "#FF3131",
                    padding: "5px 15px",
                    borderRadius: "22px",
                  }}
                >
                  <span
                    style={{
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                    }}
                  >
                    free
                  </span>
                </div>
              </div>

              <div>
                <p
                  style={{
                    margin: "0px",
                    color: "#888",
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  🎉Valid until:{" "}
                  <span
                    style={{
                      fontFamily: "sans-serif",
                      fontWeight: "700",
                      fontSize: "12px",
                      color: "#222",
                      marginLeft: "5px",
                    }}
                  >
                    {timeRemaining}
                  </span>
                </p>
              </div>
            </div>
            <div style={{ margin: "0px 10px" }}>
              {success ? (
                <div>
                  <h4 style={{ color: "green" }}>Registered Successfully</h4>
                </div>
              ) : (
                <Button
                  disabled={loading}
                  onClick={() => handleRegisterNow()}
                  style={{
                    width: "140px",
                    padding: "10px",
                    backgroundColor: "#FF3131",
                    color: "#fff",
                    fontSize: "14px",
                    fontWeight: "600",
                    textTransform: "inherit",
                  }}
                >
                  {loading ? (
                    <CircularProgress
                      style={{ color: "#fff", width: "30px", height: "30px" }}
                    />
                  ) : (
                    "Register Now"
                  )}
                </Button>
              )}
            </div>
          </div>
        )}
      </div>
    );
  };

  const opts = {
    playerVars: {
      autoplay: 0,
    },
  };

  const eachCardDetails = () => {
    return (
      <div>
        <div className="webinarevents_drawer_main_container">
          <div style={{ marginTop: "10px" }} onClick={() => setOpen(false)}>
            <ArrowBackIcon style={{ fontSize: "34px", cursor: "pointer" }} />
          </div>
          <div>
            {/* youtube video section */}
            <div
              style={{ marginTop: "10px" }}
              className="webinarEventsDrawer_events_youtube"
            >
              {<YouTube videoId={selectedCard?.youtubeUrl} opts={opts} />}
            </div>
          </div>
          <div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                fontFamily: "sans-serif",
                fontSize: "16px",
                backgroundColor: "#fff",
                borderRadius: "22px",
                color: "#FF3131",
                fontWeight: "600",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <CalendarMonthIcon
                  style={{ fontSize: "18px", marginRight: "2px" }}
                />
                <p style={{ margin: "0px" }}>{selectedCard?.eventDate}</p>
              </div>
              <p style={{ margin: "10px 5px 0px 5px", fontSize: "16px" }}>•</p>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "10px",
                }}
              >
                <ScheduleIcon
                  style={{ fontSize: "18px", marginRight: "2px" }}
                />
                <p style={{ margin: "0px" }}>{selectedCard?.eventTime}</p>
              </div>
            </div>
          </div>
          <div>
            <h2 style={{ margin: "10px 0px 10px 0px" }}>
              {selectedCard?.title}
            </h2>
            <span
              style={{ color: "#888", lineHeight: "22px", fontSize: "12px" }}
            >
              {selectedCard?.subtitle}
            </span>
          </div>
          <div style={{ marginBottom: "70px" }}>
            <h2>Technologies Used:</h2>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {selectedCard?.event_tech_stack?.map((e, index) => {
                return (
                  <div
                    key={index}
                    style={{
                      padding: "5px 8px",
                      margin: "0px 5px",
                      borderRadius: "22px",
                      color: "#fff",
                      fontSize: "16px",
                      fontWeight: "700",
                      backgroundColor: "#FF3131",
                    }}
                  >
                    <span>{e}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div>{showPriceSection()}</div>
      </div>
    );
  };

  return (
    <div>
      <MyReactHelmet value={"Events"} />
      <EducationTopBar />
      <div
        style={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "60px",
        }}
      >
        {getFilters()}
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          padding: "0px 20px",
          justifyContent: "flex-start",
          gap: "40px",
        }}
      >
        {filteredData?.map((e) => eventCard(e))}
      </div>
      <Drawer
        PaperProps={{
          sx: {
            width: "40%",
            height: "100%",
            backgroundColor: "#fffcf3",
            boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
          },
        }}
        anchor="right"
        open={open}
      >
        {eachCardDetails()}
      </Drawer>
    </div>
  );
}

export default WebinarEvents;
