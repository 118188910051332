import React from "react";
import "./ContinuousCarousel.css";
import { Avatar } from "@mui/material";
import { ImageConstants } from "../../../utils/constants";

const data = [
  {
    username: "Aditya Shakya",
    usercomment:
      "“Heartfelt thanks for crafting an exceptional FrontEnd Internship Certificate experience! Truly transformative!”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Tejas Shelar",
    usercomment:
      "“Immense gratitude for an enriching BackEnd Internship Certificate journey. Exceptional quality and expertise showcased!”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Sumit Kolpekwar",
    usercomment:
      "“Kudos on orchestrating an outstanding FullStack Internship Certificate program! Exceeded expectations.....”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Snehal More",
    usercomment:
      "“Exceptional craftsmanship on the FrontEnd Internship Certificate! Thank you for the invaluable skills and memorable experience”",
    userimage: ImageConstants.getAvatarImage,
  },
];

function ContinuousCarousel() {
  const getCardDetails = (val, index) => {
    return (
      <div key={index}>
        <div className="continuous_comments">
          <h1>{val?.usercomment}</h1>
        </div>
        <div className="continuous_curved_line_image_container">
          <img
            src="https://res.cloudinary.com/cqn/image/upload/v1711048280/lightgrey_curve_line_jdg8tj.png"
            alt=""
          />
        </div>
        <div className="getSingleMovingCard_image_container">
          <div>
            <Avatar
              style={{ width: "50px", height: "50px" }}
              src={val.userimage}
            />
          </div>
          <div style={{ marginLeft: "10px" }}>
            <h1
              style={{ fontWeight: "700", fontSize: "16px", color: "#FF3131" }}
            >
              {val.username}
            </h1>
          </div>
        </div>
      </div>
    );
  };
  const getSingleMovingCard = () => {
    return (
      <div className="wrapper">
        {data?.map((val, index) => {
          return getCardDetails(val, index);
        })}
      </div>
    );
  };
  return <div className="brands-list">{getSingleMovingCard()}</div>;
}

export default ContinuousCarousel;
