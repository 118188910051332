import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import EducationTopBar from "./topbar/EducationTopBar";
import { AnimatePresence, motion } from "framer-motion";
import "./CertificateMobileScreen.css";
import { ImageConstants } from "../../utils/constants";
import { Avatar, Button } from "@mui/material";
import MobileCarouselEffect from "./reusables/MobileCarouselEffect";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import StarRateIcon from "@mui/icons-material/StarRate";
import EducationFooter from "./footer/education_footer";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useDispatch } from "react-redux";
import { getCertificateAction } from "../../actions/Education/certificateActions";
import { redirectToPageAction } from "../../actions/userActions";
import { REDIRECT_CERTIFICATE_PAGE } from "../../constants/Education/certificateConstants";

const staticCardData = [
  {
    image: ImageConstants.getBadgeImage,
    title: "Certify now, boost interview chances by 78%!",
  },
  {
    image: ImageConstants.getIncreasingRevenue,
    title: "Certify now, Get 8+ LPA job offers!",
  },
  {
    image: ImageConstants.getRecruiterImage,
    title: "Certify now, Get Interview calls from big MNCs.",
  },
];

const internshipCardsData = [
  {
    technology: "frontend",
    id: 1,
    title: "🌟 Grab FrontEnd Internship Certificate 🌟",
    certificateImage: ImageConstants.getBasicCertificateImage,
    tag: "basic",
    listOfPoints: [
      "Looking for Quick internship certificate? Work for us.",
      "Create Basic UI using HTML, CSS for our clients.",
      "More than 8+ LPA Salary for FrontEnd Engineers.",
      "Quick and Effective Certification for you resume.",
    ],
  },
  {
    technology: "frontend",
    id: 2,
    title: "🌟 Join our Internship Programme 🌟",
    certificateImage: ImageConstants.getIntermediateCertificateImage,
    tag: "intermediate",
    listOfPoints: [
      "75% software industry is looking for FrontEnd Developers with Internship Experience.",
      "Increase your chances 45% of getting into MNCs.",
      "Solve Challenges Faced by our Clients and gain your Internship Certificate.",
      "Real World UI challenges to make you smart Frontend Developer.",
    ],
  },
  {
    technology: "frontend",
    id: 3,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "advanced",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "backend",
    id: 4,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "basic",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "backend",
    id: 5,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "intermediate",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "backend",
    id: 6,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "advanced",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "fullstack",
    id: 7,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "basic",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "fullstack",
    id: 8,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "intermediate",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
  {
    technology: "fullstack",
    id: 9,
    title: "🌟Elite FrontEnd Development Internship Programme 🌟",
    certificateImage: ImageConstants.getAdvancedCertificateImage,
    tag: "advanced",
    listOfPoints: [
      "Create UI for Clients and solve real world software issues.",
      "End to End Development using Latest Technologies like React.js, Vue.js, Redux and much more.",
      "Join the Elite list of #Acetechycoders the best in industry.",
      "Make your Resume Standout by Creating Advanced UIs.",
    ],
  },
];

const acknowledgements = [
  {
    username: "Aditya Shakya",
    usercomment:
      "“Heartfelt thanks for crafting an exceptional FrontEnd Internship Certificate experience! Truly transformative!”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Tejas Shelar",
    usercomment:
      "“Immense gratitude for an enriching BackEnd Internship Certificate journey. Exceptional quality and expertise showcased!”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Sumit Kolpekwar",
    usercomment:
      "“Kudos on orchestrating an outstanding FullStack Internship Certificate program! Exceeded expectations.....”",
    userimage: ImageConstants.getAvatarImage,
  },
  {
    username: "Snehal More",
    usercomment:
      "“Exceptional craftsmanship on the FrontEnd Internship Certificate! Thank you for the invaluable skills and memorable experience”",
    userimage: ImageConstants.getAvatarImage,
  },
];

function CertificateMobileScreen() {
  const history = useHistory();
  const dispatch = useDispatch();
  const handleEnrollClick = (technology, tag) => {
    history.push(`/certificates/${technology}/${tag}`);
    dispatch(getCertificateAction(technology, tag));
    dispatch(
      redirectToPageAction(REDIRECT_CERTIFICATE_PAGE, {
        url: `/certificates/${technology}/${tag}`,
      })
    );
  };
  const [colorIndex, setColorIndex] = useState(0);
  const colors = [
    "rgb(255 108 108 / 21%)",
    "rgb(255 108 108 / 21%)",
    "rgb(255 108 108 / 21%)",
  ];
  const texts = ["FrontEnd", "BackEnd", "Fullstack"];

  useEffect(() => {
    const interval = setInterval(() => {
      setColorIndex((prevIndex) => (prevIndex + 1) % colors.length);
    }, 3000);

    return () => clearInterval(interval);
  });
  const getMainUpperContainer = () => {
    return (
      <div style={{ marginTop: "100px" }}>
        <div style={{ padding: "5px 10px" }}>
          <h1
            style={{
              textAlign: "center",
              fontSize: "2.5rem",
              fontWeight: "800",
            }}
          >
            Grab Your <span style={{ color: "#FF3131" }}>Certificates</span> for
          </h1>
        </div>
        {getMovingTextCircle()}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <h1 style={{ fontSize: "3rem", fontWeight: "800", color: "#FF3131" }}>
            Internship
          </h1>
          <div className="certificate_mobile_explorenow_button">
            <Button>Explore now</Button>
          </div>
        </div>
      </div>
    );
  };

  const getMovingTextCircle = () => {
    return (
      <div
        style={{
          backgroundColor: colors[colorIndex],
          width: "300px",
          height: "300px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          borderRadius: "55%",
          margin: "auto",
          padding: "20px",
        }}
      >
        <AnimatePresence exitBeforeEnter>
          {texts.map((text, index) => {
            return (
              index === colorIndex && (
                <motion.div
                  key={text}
                  initial={{ y: -100, opacity: 0 }}
                  animate={{
                    y: 0,
                    opacity: 1,
                  }}
                  exit={{
                    y: 100,
                    opacity: 0,
                    transition: { ease: "easeInOut", duration: 0.1 },
                  }}
                  transition={{
                    duration: 0.1,
                    type: "spring",
                    stiffness: 100,
                    delay: 0.002,
                  }}
                  className={`certificate_mobile_moving_text`}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <div style={{ marginBottom: "20px" }}>{text}</div>
                    <div>
                      <img
                        src={
                          index === 0
                            ? ImageConstants.getBasicCertificateImage
                            : index === 1
                            ? ImageConstants.getIntermediateCertificateImage
                            : ImageConstants.getAdvancedCertificateImage
                        }
                        alt=""
                        width={100}
                      />
                    </div>
                  </div>
                </motion.div>
              )
            );
          })}
        </AnimatePresence>
      </div>
    );
  };
  const showCard = () => {
    return staticCardData?.map((data, index) => {
      return (
        <div
          key={index}
          style={{
            margin: "20px 5px",
            padding: "20px 20px",
            height: "250px",
            borderRadius: "22px",
            backgroundColor: "#fff",
            boxShadow: "0px 10px 15px -3px rgba(0,0,0,0.1)",
          }}
        >
          <div>
            <img
              style={{ width: "100px", marginBottom: "10px" }}
              src={data.image}
              alt=""
            />
          </div>
          <div>
            <h3>{data.title}</h3>
          </div>
        </div>
      );
    });
  };
  const getInternshipCard = (data, index) => {
    return (
      <div key={index} className="certificateMobile_internship_card_main">
        <div className="certificateMobile_internship_card_tag">
          <h3>
            {data?.tag === "basic"
              ? "Basic"
              : data?.tag === "intermediate"
              ? "Intermediate"
              : "Advance"}
          </h3>
        </div>
        <div className="certificateMobile_internship_card_image">
          <img style={{ width: "100px" }} src={data?.certificateImage} alt="" />
        </div>
        <div>
          <h3 style={{ fontWeight: "800" }}>{data?.title}</h3>
          <div style={{ padding: "10px 10px" }}>
            {data?.listOfPoints?.map((point, index) => {
              return getStarPoints(point, index);
            })}
          </div>
          <div className="certificateMobile_internship_card_enroll">
            <Button
              onClick={() => handleEnrollClick(data?.technology, data?.tag)}
            >
              Enroll now
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getStarPoints = (title, index) => {
    return (
      <div key={index} style={{ display: "flex", alignItems: "center" }}>
        <StarRateIcon style={{ color: "#FF416C", fontSize: "18px" }} />
        <span
          style={{
            margin: "10px 10px",
            fontFamily: "sans-serif",
            fontSize: "14px",
            textAlign: "start",
          }}
        >
          {title}
        </span>
      </div>
    );
  };

  const getInternshipCarousal = (technology) => {
    const internshipCardFiltered = internshipCardsData.filter(
      (data) => data.technology === technology
    );
    return (
      <Carousel
        infiniteLoop
        autoPlay
        transitionTime={1000}
        interval={10000}
        centerMode
        showArrows={false}
        showIndicators={false}
        showStatusBar={false}
        showStatus={false}
        showThumbs={false}
        swipeScrollTolerance={50}
        preventMovementUntilSwipeScrollTolerance={true}
      >
        {internshipCardFiltered?.map((data, index) => {
          return getInternshipCard(data, index);
        })}
      </Carousel>
    );
  };

  const getAcknowledgements = (data, index) => {
    return (
      <div key={index} className="certificateMobile_acknowledgement_container">
        <div className="certificateMobile_acknowledgement_main_body">
          <h3>{data?.usercomment}</h3>
        </div>
        <div className="certificateMobile_acknowledgement_traingle">
          <img
            src="https://res.cloudinary.com/cqn/image/upload/v1711141502/flipped_triangle_dunllf.png"
            alt=""
          />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "30px",
            marginLeft: "20px",
          }}
        >
          <Avatar src={data?.userimage} />
          <h3 style={{ margin: "0px 10px" }}>{data?.username}</h3>
        </div>
      </div>
    );
  };

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Certificates</title>
        <link
          rel="canonical"
          href={
            "https://res.cloudinary.com/cqn/image/upload/v1705319231/icons_clobgy.png"
          }
        />
      </Helmet>
      <EducationTopBar />
      {getMainUpperContainer()}
      <div style={{ marginTop: "50px" }}>
        <MobileCarouselEffect children={showCard()} />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "50px 0px 20px 0px",
        }}
      >
        <h1 style={{ fontSize: "3rem", fontWeight: "800", color: "#FF3131" }}>
          FrontEnd
        </h1>
      </div>

      <div>{getInternshipCarousal("frontend")}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "50px 0px 20px 0px",
        }}
      >
        <h1 style={{ fontSize: "3rem", fontWeight: "800", color: "#FF3131" }}>
          BackEnd
        </h1>
      </div>

      <div>{getInternshipCarousal("backend")}</div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          margin: "50px 0px 20px 0px",
        }}
      >
        <h1 style={{ fontSize: "3rem", fontWeight: "800", color: "#FF3131" }}>
          FullStack
        </h1>
      </div>

      <div>{getInternshipCarousal("fullstack")}</div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginTop: "60px",
          marginBottom: "30px",
        }}
      >
        <h1
          style={{
            margin: "0px",
            color: "#FF3131",
            fontWeight: "800",
            fontSize: "2.5rem",
          }}
        >
          Reviews
        </h1>
      </div>
      <div className="certificateMobile_list_wrapper">
        <div className="certificateMobile_wrapper_slide">
          {acknowledgements?.map((data, index) => {
            return getAcknowledgements(data, index);
          })}
        </div>
        <div className="certificateMobile_wrapper_slide">
          {acknowledgements?.map((data, index) => {
            return getAcknowledgements(data, index);
          })}
        </div>
      </div>
      <div>
        <EducationFooter margin={"40px"} />
      </div>
    </div>
  );
}

export default CertificateMobileScreen;
