import React from "react";
import "./DashboardCourses.css";
import LeftReusableScreen from "../reusables/LeftReusableScreen";
import { ImageConstants } from "../../../utils/constants";
import MyFilterAndSlide from "../reusables/MyFilterAndSlide";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Card, Button } from "@mui/material";
import ProfileDropdown from "../reusables/profile_dropdown";
import { useSelector } from "react-redux";

const data = [
  {
    title: "Frontend Basic Internship",
    problemStatement: "1",
  },
  {
    title: "Backend Basic Internship",
    problemStatement: "4",
  },
  {
    title: "FullStack Basic Internship",
    problemStatement: "3",
  },
  {
    title: "Frontend Intermediate Internship",
    problemStatement: "10",
  },
];

function DashboardCourses() {
  const { userInfo } = useSelector((state) => state.userLogin);
  const upperContainer = () => {
    return (
      <div className="dashboardScreen_top_container">
        <div className="dashboardScreen_top_container_title">
          <h1>Welcome back Shreyash!</h1>
          <h3>
            You have completed{" "}
            <span
              style={{
                fontWeight: "800",
                fontFamily: "sans-serif",
                fontSize: "18px",
              }}
            >
              0
            </span>{" "}
            Courses. Unlock your potential! Expand your skillset by enrolling in
            our diverse range of courses today.
          </h3>
        </div>
        <img
          className="dashboardCourses_upper_image"
          src={ImageConstants.getDashboardCoursesTopImage}
          alt=""
        />
      </div>
    );
  };

  const notFoundComponent = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
      >
        <img width={400} src={ImageConstants.getNotFoundImage} alt="" />
        <div>
          <h2 style={{ margin: "0px" }}>
            You don't have any registered courses
          </h2>
        </div>
      </div>
    );
  };

  const inProgressCertificateSection = (e) => {
    return (
      <Card className="dashboardCourses_card_main">
        <div className="dashboardCourses_image_container">
          <img src={ImageConstants.shopping_project_image} alt="" />
        </div>
        <div className="dashboard_details_container">
          <p>Title of the Card</p>
          <span>Subtitle of the Card</span>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="dashboard_courses_below">
            <div>
              <OndemandVideoIcon style={{ fontSize: "16px", color: "grey" }} />
              <span>10 videos</span>
            </div>
            <div>
              <AccessTimeIcon style={{ fontSize: "16px", color: "grey" }} />
              <span>8 hrs</span>
            </div>
          </div>
          <div className="dashboard_price">
            <h4>₹499</h4>
          </div>
        </div>
        <div className="dashboard_courses_button_container">
          <Button>Start</Button>
        </div>
      </Card>
    );
  };

  return (
    <div style={{ display: "flex" }}>
      <LeftReusableScreen />
      <div className="dashboard_courses_upperContainer_main">
        <div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              margin: "10px 10px",
            }}
          >
            <ProfileDropdown
              userImage={ImageConstants.getTempImage}
              userName={userInfo?.firstName}
            />
          </div>
        </div>
        <div className="dashboardCourses_right_main_container">
          {upperContainer()}
          <div style={{ margin: "40px 0px" }}>
            <div>
              <h2 style={{ margin: "0px 0px -50px 0px" }}>
                Inprogress Courses:
              </h2>
            </div>
            <div style={{ marginTop: "50px" }} />
            {/* <MyFilterAndSlide
              data={data?.map((e) => inProgressCertificateSection(e))}
            /> */}
            {notFoundComponent()}
          </div>
          <div style={{ margin: "40px 0px" }}>
            <div>
              <h2 style={{ margin: "0px 0px -50px 0px" }}>
                Completed Courses:
              </h2>
            </div>
            <div style={{ marginTop: "50px" }} />
            {/* <MyFilterAndSlide
              data={data?.map((e) => inProgressCertificateSection(e))}
            /> */}
            {notFoundComponent()}
          </div>
          {/* <div style={{ margin: "40px 0px" }}>
            <div>
              <h2 style={{ margin: "0px 0px -50px 0px" }}>
                Suggested Courses:
              </h2>
            </div>
            <div style={{ marginTop: "50px" }} />
            <MyFilterAndSlide
              data={data?.map((e) => inProgressCertificateSection(e))}
            />
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default DashboardCourses;
