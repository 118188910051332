import React from "react";
import EducationTopBar from "../education/topbar/EducationTopBar";
import EducationFooter from "../education/footer/education_footer";

function ContactUs() {
  return (
    <div>
      <EducationTopBar />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "68vh",
        }}
      >
        <h1
          style={{
            display: "flex",
            justifyContent: "center",
            fontSize: "45px",
          }}
        >
          Contact Us
        </h1>
        <div>
          <h2 style={{ margin: "50px 0px" }}>
            We are here to assist you with any questions, concerns, or feedback.
            Please feel free to contact us using the details below:
          </h2>
          <h1>Email: support@acetechies.co.in</h1>
          <h1>Address: Ashirwad Nagar, Nagpur, Maharashtra</h1>
        </div>
      </div>
      <EducationFooter margin={"20px"} />
    </div>
  );
}

export default ContactUs;
