import React, { useEffect, useState } from "react";
import "./LoginScreenAnimation.css";
import { ImageConstants } from "../../../utils/constants";
import { motion } from "framer-motion";
import { useHistory } from "react-router-dom";

function LoginScreenAnimation() {
  const history = useHistory();
  const [currImage, setCurrImage] = useState(0);
  const data = [
    {
      src: ImageConstants.login_page_image1,
      title: "Master Problem Solving",
      subtitle:
        "Learn to Solve Real-World Problems with Code: Frontend, Backend, Fullstack",
    },
    {
      src: ImageConstants.login_page_image2,
      title: "Get Certifications",
      subtitle:
        "Boost Your Resume with Coding Certifications: Frontend, Backend, Fullstack",
    },
    {
      src: ImageConstants.login_page_image3,
      title: "Master Problem Solving",
      subtitle:
        "Learn to Solve Real-World Problems with Code: Frontend, Backend, Fullstack",
    },
  ];
  const variants = {
    hidden: { x: 20, opacity: 0 },
    visible: { x: 0, opacity: 1 },
    exiting: { x: -100, opacity: 0 },
  };
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrImage((value) => {
        if (data.length - 1 === value) {
          return 0;
        }
        return value + 1;
      });
    }, 10000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="image_main_container">
      <div className="authentication_container">
        <img
          onClick={() => history.push("/")}
          src={ImageConstants.main_logo}
          alt=""
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: "85vh",
        }}
      >
        {data?.map((val, index) => {
          return (
            index === currImage && (
              <div className="login_screen_animation_container">
                <motion.div
                  key={index}
                  initial="hidden"
                  animate="visible"
                  exit="exiting"
                  variants={variants}
                  transition={{ duration: 1.08, ease: "easeInOut" }}
                >
                  <div className="image_section_container">
                    <img src={val?.src} alt="" />
                  </div>
                  <div className="authentication_image_text">
                    <h1>{val?.title}</h1>
                    <h4>{val?.subtitle}</h4>
                  </div>
                </motion.div>
              </div>
            )
          );
        })}
      </div>
    </div>
  );
}

export default LoginScreenAnimation;
