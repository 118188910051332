import React, { useEffect, useState } from "react";
import "./AuthenticationScreen.css";
import { Button, CircularProgress } from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import useWindowResize from "../../utils/WindowResize";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { googleLoginAction, userLoginAction } from "../../actions/userActions";
import MyReactHelmet from "./reusables/react_helmet";
import EmailIcon from "@mui/icons-material/Email";
import KeyIcon from "@mui/icons-material/Key";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { GoogleLogin } from "@react-oauth/google";
import LoginScreenAnimation from "./reusables/LoginScreenAnimation";

function AuthenticationScreen() {
  const isSmallScreen = useWindowResize();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isDisabled, setDisabled] = useState(true);
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const handlePasswordChange = (e) => {
    var currPassword = e.target.value;
    setPassword(currPassword);
  };

  useEffect(() => {
    if (email.length > 0 && password.length > 0) {
      setDisabled(false);
    } else {
      setDisabled(true);
      setShowPassword(false);
    }
  }, [email, password]);

  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const history = useHistory();

  const handleSignUpClick = () => {
    history.push("/signup");
  };

  const handleSubmit = () => {
    dispatch(userLoginAction(email, password));
  };

  const { loading, error } = useSelector((state) => state.userLogin);

  const googleLoginButton = () => {
    return (
      <div>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_KEY}>
          <GoogleLogin
            onSuccess={(credentialResponse) => {
              dispatch(googleLoginAction(credentialResponse.credential));
              console.log(credentialResponse);
            }}
            onError={() => {
              console.log("Login Failed");
            }}
            width={isSmallScreen ? 300 : 600}
          />
        </GoogleOAuthProvider>
      </div>
    );
  };

  const getDetailsForm = () => {
    return (
      <div className="authentication_upper_container">
        <form className="authentication_form">
          <div
            className="authentication_form_title"
            style={{ margin: "40px 0px" }}
          >
            <h1 className="welcome_title">Welcome Back!</h1>
            <p className="welcome_subtitle">Please login to your account</p>
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            {googleLoginButton()}
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              margin: "20px 0px",
            }}
          >
            <hr
              style={{
                width: "50%",
                display: "block",
                height: "1px",
                border: 0,
                borderTop: " 1px solid #ccc",
                padding: "0",
              }}
            />
            <span
              style={{
                width: "50%",
                margin: "0px 10px",
                display: "flex",
                justifyContent: "center",
                fontSize: "12px",
              }}
            >
              or sign in with
            </span>
            <hr
              style={{
                width: "50%",
                display: "block",
                height: "1px",
                border: 0,
                borderTop: " 1px solid #ccc",

                padding: "0",
              }}
            />
          </div>
          <div className="authentication_details">
            <div>
              <label>Email Address</label>
              <div className="authentication_details_internal_container">
                <div style={{ marginLeft: "10px", marginTop: "4px" }}>
                  <EmailIcon />
                </div>
                <input
                  type="text"
                  value={email}
                  onChange={(e) => handleEmailChange(e)}
                />
              </div>
            </div>
            <div style={{ position: "relative" }}>
              <div className="authentication_details_password">
                <label>Password</label>
                <div className="authentication_details_internal_container">
                  <div style={{ marginLeft: "10px", marginTop: "4px" }}>
                    <KeyIcon />
                  </div>
                  <input
                    type={`${showPassword ? "text" : "password"}`}
                    value={password}
                    onChange={(e) => handlePasswordChange(e)}
                  />
                </div>
              </div>
              {password.length > 0 && (
                <div
                  style={{
                    position: "absolute",
                    top: "45px",
                    right: "20px",
                    cursor: "pointer",
                  }}
                  onClick={handleShowPassword}
                >
                  {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                </div>
              )}
            </div>
            {/* <div className="forgot_password">
              <span>Forgot Password?</span>
            </div> */}
            {error?.status === 403 && (
              <div style={{ display: "flex", alignItems: "center" }}>
                <p
                  style={{
                    margin: "10px 0px",
                    fontSize: "18px",
                    color: "red",
                  }}
                >
                  *{error?.data?.message}
                </p>
              </div>
            )}
          </div>
          <div className="authentication_form_submit_container">
            {loading === true ? (
              <Button className="authentication_login_button">
                <CircularProgress style={{ color: "white" }} />
              </Button>
            ) : (
              <Button
                disabled={isDisabled}
                className={`${
                  isDisabled
                    ? "authentication_disabled_color"
                    : "authentication_login_button"
                }`}
                onClick={handleSubmit}
              >
                Login <ArrowRightAltIcon style={{ marginLeft: "5px" }} />
              </Button>
            )}
          </div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <p>
              If you are new user please{" "}
              <span
                style={{
                  textDecoration: "underline",
                  cursor: "pointer",
                  fontWeight: "700",
                  color: "#FF3131",
                  marginTop: "20px",
                }}
                onClick={handleSignUpClick}
              >
                Sign UP!
              </span>
            </p>
          </div>
        </form>
      </div>
    );
  };

  const getImageSection = () => {
    return <LoginScreenAnimation />;
  };

  return (
    <>
      <MyReactHelmet value={"Login"} />

      <div className="authentication_main_container">
        {!isSmallScreen && getImageSection()}
        {getDetailsForm()}
      </div>
    </>
  );
}

export default AuthenticationScreen;
