import {
  DASHBOARD_FILTER_COMPLETED_CERTIFICATIONS,
  DASHBOARD_FILTER_INPROGRESS_CERTIFICATIONS,
  DASHBOARD_FILTER_INREVIEW_CERTIFICATIONS,
  DASHBOARD_GET_ALL_SUBMISSIONS_FAILED,
  DASHBOARD_GET_ALL_SUBMISSIONS_LOADING,
  DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_FAIL,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_REQUEST,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_RESET,
  DASHBOARD_UPLOAD_SOLUTION_GITHUB_SUCCESS,
  DASHBOARD_UPLOAD_SOLUTION_RESET,
  DASHBOARD_UPLOAD_SOLUTION_SELCTION,
} from "../../constants/Dashboard/dashboardConstants";

export const dashboardFilterCertificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_FILTER_INPROGRESS_CERTIFICATIONS:
      return {
        ...state,
        inprogressCertificationsData: action.payload,
      };
    case DASHBOARD_FILTER_COMPLETED_CERTIFICATIONS:
      return {
        ...state,
        completedCertificationsData: action.payload,
      };
    case DASHBOARD_FILTER_INREVIEW_CERTIFICATIONS:
      return {
        ...state,
        inReviewCertificationsData: action.payload,
      };

    default:
      return state;
  }
};

export const dashboardUploadSolutionReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_UPLOAD_SOLUTION_SELCTION:
      return {
        selectedProblemStatement: action.payload,
      };
    case DASHBOARD_UPLOAD_SOLUTION_RESET:
      return {};

    default:
      return state;
  }
};

export const dashboardUploadGithublinkReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_UPLOAD_SOLUTION_GITHUB_REQUEST:
      return {
        loading: true,
      };
    case DASHBOARD_UPLOAD_SOLUTION_GITHUB_SUCCESS:
      return {
        loading: false,
        success: true,
        uploaded_githublink_res: action.payload,
      };
    case DASHBOARD_UPLOAD_SOLUTION_GITHUB_FAIL:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };
    case DASHBOARD_UPLOAD_SOLUTION_GITHUB_RESET:
      return {};
    default:
      return state;
  }
};

export const dashboardGetAllSubmissionReducer = (state = {}, action) => {
  switch (action.type) {
    case DASHBOARD_GET_ALL_SUBMISSIONS_LOADING:
      return {
        loading: true,
      };
    case DASHBOARD_GET_ALL_SUBMISSIONS_SUCCESS:
      return {
        loading: false,
        success: true,
        allSubmissions: action.payload,
      };
    case DASHBOARD_GET_ALL_SUBMISSIONS_FAILED:
      return {
        loading: false,
        success: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
