import React, { useEffect, useRef, useState } from "react";
import "./Courses.css";
import MyReactHelmet from "./reusables/react_helmet";
import { ImageConstants } from "../../utils/constants";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useDispatch, useSelector } from "react-redux";
import {
  addSelectedCourseFromListAction,
  addSelectedFromExploreAction,
  coursePaymentAction,
  getSelectedCourseDataAction,
  getSuggestedCoursesAction,
} from "../../actions/Education/courseActions";
import SearchIcon from "@mui/icons-material/Search";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { Box, Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ProfileDropdown from "./reusables/profile_dropdown";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import PauseCircleIcon from "@mui/icons-material/PauseCircle";
import SpatialAudioOffIcon from "@mui/icons-material/SpatialAudioOff";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import SuggestedCoursesCard from "./reusables/SuggestedCoursesCard";
import AssignmentIcon from "@mui/icons-material/Assignment";
import Modal from "@mui/material/Modal";
import MyAccordian from "./reusables/My_Accordian";
import CloseIcon from "@mui/icons-material/Close";
import {
  generateMerchantUserId,
  generateTransactionId,
} from "../../utils/payment_utils";

function Courses() {
  const discount = ((2999 - 799) / 2999) * 100;
  const dispatch = useDispatch();
  const ref = useRef(null);
  const videoRef = useRef(null);

  const { selectedFromExplore } = useSelector(
    (state) => state.selectedExploreCourse
  );
  const { selectedCourseFromList, myClickCategory } = useSelector(
    (state) => state.selectedCourseFromListStore
  );

  const { loading, selectedCourseList } = useSelector(
    (state) => state.coursesFromServer
  );

  const { loading: suggestedLoading, suggestedCourses } = useSelector(
    (state) => state.suggestedCoursesListStore
  );

  const handleSelectedExplore = (value) => {
    dispatch(addSelectedFromExploreAction(value));
    switch (value) {
      case "frontend":
        dispatch(getSelectedCourseDataAction("FrontEnd"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FullStack", "QuickJob", "JobReady"],
            "FrontEnd"
          )
        );
        break;
      case "backend":
        dispatch(getSelectedCourseDataAction("BackEnd"));
        dispatch(
          getSuggestedCoursesAction(
            ["FrontEnd", "FullStack", "QuickJob", "JobReady"],
            "BackEnd"
          )
        );
        break;
      case "fullstack":
        dispatch(getSelectedCourseDataAction("FullStack"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FrontEnd", "QuickJob", "JobReady"],
            "FullStack"
          )
        );
        break;
      case "quick_projects":
        dispatch(getSelectedCourseDataAction("QuickJob"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FullStack", "FrontEnd", "JobReady"],
            "QuickJob"
          )
        );
        break;
      case "job_ready":
        dispatch(getSelectedCourseDataAction("JobReady"));
        dispatch(
          getSuggestedCoursesAction(
            ["BackEnd", "FullStack", "QuickJob", "FrontEnd"],
            "JobReady"
          )
        );
        break;

      default:
        break;
    }
    if (value !== selectedFromExplore) {
      handleAddSelectedCourseFromList(0);
      ref.current.scrollIntoView();
    }
  };
  const { userInfo } = useSelector((state) => state.userLogin);
  const history = useHistory();

  useEffect(() => {
    dispatch(getSelectedCourseDataAction("FrontEnd"));
    dispatch(addSelectedCourseFromListAction(0));
    dispatch(addSelectedFromExploreAction("frontend"));
    dispatch(
      getSuggestedCoursesAction(
        ["BackEnd", "FullStack", "QuickJob", "JobReady"],
        "FrontEnd"
      )
    );
  }, []);

  const leftDetails = () => {
    return (
      <div>
        <div
          onClick={() => history.push("/")}
          className="courses_logo_container"
        >
          <div className="courses_logo_image_container">
            <img width={40} src={ImageConstants.courses_page_logo} alt="" />
          </div>
          <div>
            <h2
              style={{
                fontSize: "28px",
                marginLeft: "-10px",
                fontWeight: "800",
              }}
            >
              Courses
            </h2>
          </div>
        </div>
        <div>{getExploreSection()}</div>
      </div>
    );
  };

  const getExploreSection = () => {
    return (
      <div className="getExploreContainer">
        <div className="getExploreContainer_title">
          <h1 style={{ marginBottom: "20px" }}>Explore</h1>
        </div>
        <div className="getExploreContainer_list">
          <div
            className={`${
              selectedFromExplore === "frontend"
                ? "getExploreContainer_list_each_container_selected"
                : "getExploreContainer_list_each_container"
            }`}
            onClick={() => handleSelectedExplore("frontend")}
          >
            <p>FrontEnd</p>
            {selectedFromExplore === "frontend" && <NavigateNextIcon />}
          </div>
          <div
            className={`${
              selectedFromExplore === "backend"
                ? "getExploreContainer_list_each_container_selected"
                : "getExploreContainer_list_each_container"
            }`}
            onClick={() => handleSelectedExplore("backend")}
          >
            <p>BackEnd</p>
            {selectedFromExplore === "backend" && <NavigateNextIcon />}
          </div>
          <div
            className={`${
              selectedFromExplore === "fullstack"
                ? "getExploreContainer_list_each_container_selected"
                : "getExploreContainer_list_each_container"
            }`}
            onClick={() => handleSelectedExplore("fullstack")}
          >
            <p>FullStack</p>
            {selectedFromExplore === "fullstack" && <NavigateNextIcon />}
          </div>
          <div
            className={`${
              selectedFromExplore === "quick_projects"
                ? "getExploreContainer_list_each_container_selected"
                : "getExploreContainer_list_each_container"
            }`}
            onClick={() => handleSelectedExplore("quick_projects")}
          >
            <p>Quick Projects</p>
            {selectedFromExplore === "quick_projects" && <NavigateNextIcon />}
          </div>
          <div
            className={`${
              selectedFromExplore === "job_ready"
                ? "getExploreContainer_list_each_container_selected"
                : "getExploreContainer_list_each_container"
            }`}
            onClick={() => handleSelectedExplore("job_ready")}
          >
            <p>Job Ready Projects</p>
            {selectedFromExplore === "job_ready" && <NavigateNextIcon />}
          </div>
        </div>
        <div>
          <div className="course_image_container">
            <img src={ImageConstants.courses_certificate_image} alt="" />
            <h1>Grab your Certificates Now!!</h1>
          </div>
        </div>
      </div>
    );
  };

  const getMiddleSection = () => {
    return (
      <div className="courses_middleSection_container">
        {getSearchSection()}
        {loading ? (
          <Skeleton
            count={5}
            style={{
              height: "80px",
              padding: "10px",
              borderRadius: "22px",
              marginTop: "15px",
            }}
          />
        ) : (
          getSelectedResult()
        )}
      </div>
    );
  };

  const getSearchSection = () => {
    return (
      <div className="courses_searchSection_container">
        <SearchIcon style={{ fontSize: "18px" }} />
        <div className="courses_search_input_container">
          <input type="text" placeholder="What do you want to learn?" />
        </div>
      </div>
    );
  };

  const getSelectedResult = () => {
    return (
      <div className="getSelectedResult_main_container">
        <div ref={ref} />
        {selectedCourseList?.data?.map((course, index) => {
          return middleSectionCard(
            index,
            course?.title,
            course?.thumbnail_url ??
              "https://images.unsplash.com/photo-1581276879432-15e50529f34b?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
            course?.subtitle,
            course?.tags,
            course?.numOfVideos,
            course?.numOfHours
          );
        })}
        {suggestedLoading ? (
          <Skeleton
            style={{
              height: "50px",
              padding: "10px",
              borderRadius: "22px",
              margin: "20px 0px",
            }}
            count={3}
          />
        ) : (
          <div>
            <div>
              <h2>Suggested Courses</h2>
            </div>
            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {suggestedCourses?.data?.map((course, index) => {
                return <SuggestedCoursesCard course={course} index={index} />;
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const handleAddSelectedCourseFromList = (index) => {
    dispatch(addSelectedCourseFromListAction(index, "courses"));
  };

  const middleSectionCard = (
    index,
    title,
    imgSrc,
    subtitle,
    tags,
    numberOfVideos,
    hours
  ) => {
    return (
      <div
        key={index}
        className={`${
          selectedCourseFromList === index &&
          myClickCategory !== "suggestedCourses"
            ? "middleSectionCardMainContainer_selected"
            : "middleSectionCardMainContainer"
        }`}
        onClick={() => handleAddSelectedCourseFromList(index)}
      >
        <div className="middleSectionCardContainer">
          <div className="middleSectionCardImageContainer">
            <img src={imgSrc} alt="" />
          </div>
          <div className="middleSectionCard_details_container">
            <div className="middleSectionCard_details_title">
              <h2>{title}</h2>
            </div>
            <div className="middleSectionCard_details_subtitle">
              <p>{subtitle}</p>
            </div>
            <div className="middleSectionCard_details_tags_container">
              <div className="middleSectionCard_details_tags">
                {tags.map((tag) => {
                  return (
                    <div key={tag}>
                      <span>{tag}</span>
                    </div>
                  );
                })}
              </div>
              <div className="middleSectionCard_details_video_details">
                <div>
                  <OndemandVideoIcon
                    style={{ fontSize: "16px", color: "grey" }}
                  />
                  <span>{numberOfVideos} videos</span>
                </div>
                <div>
                  <AccessTimeIcon style={{ fontSize: "16px", color: "grey" }} />
                  <span>{hours} hrs</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div>
          <NavigateNextIcon style={{ fontSize: "30px", color: "grey" }} />
        </div>
      </div>
    );
  };

  const rightSectionDetails = () => {
    return (
      <div>
        {getRightUpperSection()}
        {getRightMainSection()}
      </div>
    );
  };

  const getRightUpperSection = () => {
    return (
      <div className="rightUpperSection_main_container">
        <div className="rightUpperSection_container">
          <div>
            <p>My Certifications</p>
          </div>
          <div>
            <p>About us</p>
          </div>

          <div>
            <p>Help Center</p>
          </div>
        </div>
        <div>
          {userInfo ? (
            <div
              style={{
                width: "160px",
                border: "1px solid lightgray",
                borderRadius: "22px",
                padding: "5px",
              }}
            >
              <ProfileDropdown
                // userImage={ImageConstants.getUserProfile_image}
                userName={userInfo?.firstName}
              />
            </div>
          ) : (
            <div className="rightSectionLoginButtonContainer">
              <div>
                <Button
                  onClick={() => history.push("/login")}
                  className="rightSection_sign_in"
                >
                  Sign In
                </Button>
              </div>
              <div>
                <Button
                  onClick={() => history.push("/signup")}
                  className="rightSection_sign_up"
                >
                  Sign UP
                </Button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getRightMainSection = () => {
    var title = "";
    var subtitle = "";
    var currPrice = 0;
    var originalPrice = 0;
    var audio_available = "";
    var preview_video_url = "";
    if (
      selectedCourseList?.data?.length > 0 ||
      suggestedCourses?.data?.length > 0
    ) {
      if (myClickCategory === "suggestedCourses") {
        title = suggestedCourses?.data[selectedCourseFromList].title;
        subtitle = suggestedCourses?.data[selectedCourseFromList].subtitle;
        currPrice = suggestedCourses?.data[selectedCourseFromList].currPrice;
        originalPrice =
          suggestedCourses?.data[selectedCourseFromList].originalPrice;
        audio_available =
          suggestedCourses?.data[selectedCourseFromList].audio_available;
        preview_video_url =
          suggestedCourses?.data[selectedCourseFromList].preview_video_url;
      } else {
        title = selectedCourseList?.data[selectedCourseFromList].title;
        subtitle = selectedCourseList?.data[selectedCourseFromList].subtitle;
        currPrice = selectedCourseList?.data[selectedCourseFromList].currPrice;
        originalPrice =
          selectedCourseList?.data[selectedCourseFromList].originalPrice;
        audio_available =
          selectedCourseList?.data[selectedCourseFromList].audio_available;
        preview_video_url =
          selectedCourseList?.data[selectedCourseFromList].preview_video_url;
      }
    }
    return (
      <div>
        {loading ? (
          <Skeleton
            style={{ height: "250px", padding: "10px", borderRadius: "22px" }}
          />
        ) : (
          getPreviewVideoPlayer(preview_video_url)
        )}
        {loading ? (
          <Skeleton count={5} style={{ height: "30px", margin: "15px" }} />
        ) : (
          (selectedCourseList !== null || selectedCourseList !== undefined) &&
          getVideoDetails(
            title,
            subtitle,
            currPrice,
            originalPrice,
            audio_available
          )
        )}
      </div>
    );
  };

  const [openBookNow, setOpenBookNow] = useState(false);

  const handleBookNow = () => {
    if (userInfo !== null && userInfo !== undefined) {
      setOpenBookNow(true);
      let amount =
        myClickCategory === "suggestedCourses"
          ? suggestedCourses?.data[selectedCourseFromList].currPrice
          : selectedCourseList?.data[selectedCourseFromList].currPrice;
      let mobileNumber = userInfo.phoneNumber;
      dispatch(
        coursePaymentAction(
          generateTransactionId(),
          generateMerchantUserId(userInfo?._id),
          amount,
          mobileNumber
        )
      );
    } else {
      history.push("/login");
    }
  };

  const handleBookNowClose = () => setOpenBookNow(false);

  const getVideoDetails = (
    title,
    subtitle,
    currPrice,
    originalPrice,
    audio_available
  ) => {
    return (
      <div>
        {/* Show Rating under video */}
        <div></div>
        <div>
          <h1 style={{ fontSize: "24px", margin: "10px 0px 10px 0px" }}>
            {title}
          </h1>
        </div>
        <div>
          <p
            style={{
              margin: "0px 0px 20px 0px",
              fontSize: "14px",
              color: "#888",
            }}
          >
            {subtitle}
          </p>
        </div>
        <div className="getVideoDetails_booking_container">
          <div className="getVideoDetails_money_container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <h1 style={{ margin: "0px", fontSize: "34px" }}>₹{currPrice}</h1>
              <div
                style={{
                  backgroundColor: "#FF3131",
                  padding: "4px",
                  borderRadius: "10px",
                  marginLeft: "10px",
                }}
              >
                <span
                  style={{
                    fontFamily: "sans-serif",
                    fontSize: "14px",
                    color: "#fff",
                  }}
                >
                  -{discount.toFixed()}%
                </span>
              </div>
            </div>
            <div>
              <h1
                style={{
                  fontSize: "18px",
                  margin: "0px",
                  textDecoration: "line-through",
                  color: "#888",
                }}
              >
                ₹{originalPrice}
              </h1>
            </div>
          </div>
          <div className="getVideoDetails_bookNow">
            <Button onClick={() => handleBookNow()}>Book Now</Button>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          {getEachVideoDetails(
            <SpatialAudioOffIcon style={{ color: "#fff", fontSize: "18px" }} />,
            "Audio",
            audio_available
          )}

          {getEachVideoDetails(
            <AssignmentIcon style={{ color: "#fff", fontSize: "18px" }} />,
            "Certificate",
            "Certificate of Completion"
          )}
        </div>
        <div className="getVideoDetails_course_details_button_container">
          <Button onClick={handleOpen}>Course Details</Button>
        </div>
      </div>
    );
  };

  const getEachVideoDetails = (icon, title, text) => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          margin: "20px 20px 20px 0px",
        }}
      >
        <div
          style={{
            backgroundColor: "#FF3131",
            padding: "8px 10px",
            borderRadius: "50%",
            marginRight: "10px",
          }}
        >
          {icon}
        </div>
        <div>
          <span style={{ fontWeight: "500" }}>{title}</span>
          <p style={{ margin: "0px", fontWeight: "700" }}>{text}</p>
        </div>
      </div>
    );
  };

  const [showPlay, setShowPlay] = useState(false);

  const handleVideoClick = () => {
    const videoElement = videoRef.current;
    if (videoElement.paused === true) {
      videoElement.play();
      setShowPlay(true);
    } else {
      videoElement.pause();
      setShowPlay(false);
    }
  };

  const getPreviewVideoPlayer = (preview_video_url) => {
    return (
      <div className="getPreviewVideoPlayer">
        {showPlay === false ? (
          <div className="getPreviewVideoPlayer_play_button">
            <PlayCircleIcon style={{ color: "#FF3131", fontSize: "65px" }} />
          </div>
        ) : (
          <div className="getPreviewVideoPlayer_pause_button">
            <PauseCircleIcon style={{ color: "#FF3131", fontSize: "65px" }} />
          </div>
        )}
        {
          <div
            style={{
              position: "absolute",
              bottom: "10px",
              right: "10px",
              cursor: "pointer",
              zIndex: "200",
            }}
            onClick={() => {
              if (videoRef.current.webkitRequestFullscreen) {
                videoRef.current.webkitRequestFullscreen();
              }
            }}
          >
            <FullscreenIcon style={{ fontSize: "30px" }} />
          </div>
        }
        <video
          src={
            preview_video_url ??
            "https://res.cloudinary.com/cqn/video/upload/v1709572848/AceTech-Business-Carousal_pllyr1.mp4"
          }
          ref={videoRef}
          onClick={handleVideoClick}
          playsInline
        />
      </div>
    );
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 500,
    height: 500,
    bgcolor: "#fffcf3",
    boxShadow: 24,
    p: 3,
  };
  const bookNowStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 350,
    height: 250,
    bgcolor: "#fffcf3",
    boxShadow: 24,
    p: 3,
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpenedAccordian([]);
    setOpen(false);
  };
  const [openedAccordian, setOpenedAccordian] = useState([]);

  return (
    <div className="courses_main_container">
      <MyReactHelmet value={"Courses"} />
      <div>{leftDetails()}</div>
      <div>{getMiddleSection()}</div>
      <div style={{ width: "100%" }}>{rightSectionDetails()}</div>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style} className="course_details_modal">
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                margin: "-18px",
              }}
            >
              <div style={{ cursor: "pointer" }} onClick={handleClose}>
                <CloseIcon />
              </div>
            </div>
            <div>
              <div>
                <h2 style={{ fontWeight: "800" }}>Course Content</h2>
              </div>
              <div>
                <MyAccordian
                  setOpenedAccordian={setOpenedAccordian}
                  openedAccordian={openedAccordian}
                  courseDetails={
                    myClickCategory === "suggestedCourses"
                      ? suggestedCourses?.data[selectedCourseFromList]
                      : selectedCourseList?.data[selectedCourseFromList]
                  }
                />
              </div>
            </div>
            <div style={{ marginTop: "40px" }}>
              <h2 style={{ fontWeight: "800" }}>Requirements</h2>
              <ul className="bottomDrawer_requirements_list">
                <li>A computer with access to the internet.</li>
                <li>No paid software required.</li>
              </ul>
            </div>
          </Box>
        </Modal>
        <Modal
          open={openBookNow}
          onClose={handleBookNowClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={bookNowStyle}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1>Redirecting to Payment Gateway...</h1>
            </div>
          </Box>
        </Modal>
      </div>
    </div>
  );
}

export default Courses;
